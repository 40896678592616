/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import dybApi from "lib/api/dybApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { filterProject, searchKeyword } from "../../modules/projectFilter";

// ====================== MP&MUI Components  ======================
import MPButton from "components/MPButton/index";
import MPBox from "components/MPBox/index";
import MPTypography from "components/MPTypography/index";
import MPAvatar from "components/MPAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import { CardActions, CardContent, Grid } from "../../../node_modules/@mui/material/index";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// ====================== Service Components ======================
import HomeLayout from "services/LayoutContainers/HomeLayout";
import HomeNavbar from "services/Navbars/HomeNavbar";
import SvcFooter from "services/Footer/SvcFooter";

// ====================== Images ==================================
import onePng from "assets/images/number/one.png";
import twoPng from "assets/images/number/two.png";
import threePng from "assets/images/number/three.png";
import fourPng from "assets/images/number/four.png";
import questionMark from "assets/images/home/question-mark.svg";
import designerImg from "assets/images/home/designer.jpg";
import developerImg from "assets/images/home/developer.jpg";
import plannerImg from "assets/images/home/planner.jpg";
import publisherImg from "assets/images/home/publisher.jpg";
import communicationImg from "assets/images/home/communication-main.jpg";
import trustImg from "assets/images/home/trust-image.jpg";
import dybSoftAws from "assets/images/home/dybsoft-aws.png";
import dybSoftTechnology from "assets/images/home/dybsoft-skills.png";
import serverLess from "assets/images/home/icons/server-less.png";
import serverCloud from "assets/images/home/icons/server-cloud.png";
import serverConfiguration from "assets/images/home/server-configuration.jpg";
import ApacheJmeter from "assets/images/home/Apache-Jmeter.jpg";
import apiGateway from "assets/images/home/APIGateway.jpg";
import lamda from "assets/images/home/lamda.jpg";
import awsFargate from "assets/images/home/AWSFargate.jpg";
import arrowDown from "assets/images/home/icons/arrow-down.png";

let page = 1;
function Home() {
  // 동적 글자 표기
  let count = 0;
  const [applyTitle, setApplyTitle] = useState("");
  const completionWord = "아래 개발 및 운영 프로젝트를 확인해 보세요";
  const dispatchRedux = useDispatch();

  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const goToDifferentReason = () => {
    const access = document.getElementById("different-reason");
    access.scrollIntoView({ behavior: "smooth" }, true);
  };

  const handleInfiniteScroll = () => {
    setTimeout(() => {
      page++;
      fetchProjectList();
    }, 1700);
  };

  const fetchProjectList = async () => {
    try {
      const response = await dybApi.get(`/api/svc/v2/projectList?page=${page}`);
      if (response.data.data.length > 0) {
        setDataList((dataList) => dataList.concat(response.data.data));
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetchProjectList : ", error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await dybApi.get("/api/svc/v2/categoryList?categoryLevel=1");
      setCategoryList(response.data.data);
    } catch (error) {
      console.error("Error fetching categoryList:", error);
    }
  };

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setApplyTitle((applyTitle) => {
        let result = applyTitle ? applyTitle + completionWord[count] : completionWord[0];
        count++;
        if (count > completionWord.length) {
          count = 0;
          setApplyTitle("");
        }

        return result;
      });
    }, 250);
    // Promise.all과 일반함수로 호출하는 것 차이 명확하게 알기
    // Promise.all([fetchProjectList(), fetchCategoryList()]); 로 호출해도 된다.
    fetchProjectList();
    fetchCategoryList();

    return () => {
      // unmount 시 초기화 해줘야 함.
      page = 1;
      clearInterval(typingInterval);
    };
  }, []);

  return (
    <HomeLayout>
      <HomeNavbar light={false} />
      <MPBox bgColor="#FCFCFE" height="100%" pb={2}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={3} pb={2} lineHeight={1} pl={{ xs: 2, lg: 1 }}>
            <MPTypography color="black" variant="h4">
              개발, 운영 프로젝트 아웃소싱
            </MPTypography>
            <MPBox pl={{ xs: 1, lg: 38 }} mb={3}>
              <MPTypography sx={{ color: "red" }} variant="caption" fontWeight="regular">
                DYB Soft는 다양한 프로젝트를 수주받습니다.
              </MPTypography>
              <MPBox pl={{ xs: 5, lg: 33 }} mt={{ xs: 1, lg: -3 }} mb={2}>
                <CardMedia
                  src={questionMark}
                  component="img"
                  title="modern"
                  sx={{
                    maxWidth: "100%",
                    margin: 0,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MPBox>
              <MPBox pl={{ xs: 15, lg: 42 }} mt={{ xs: -7, lg: -5 }} mb={2}>
                <MPButton
                  onClick={goToDifferentReason}
                  // component={Link}
                  // to={"/project/dev"}
                  variant="outlined"
                  size="small"
                  color="black"
                  sx={{ fontSize: "initial" }}
                >
                  무엇이 다른가요?
                </MPButton>
              </MPBox>
            </MPBox>
            <MPBox>
              <MPTypography color="black" variant="h6">
                직군별로 다양한 프로젝트 확인해보세요!
              </MPTypography>
            </MPBox>
          </MPBox>
          <MPBox px={3}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={6} xl={3} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={plannerImg}
                    component="img"
                    title="기획자"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
                <MPBox
                  position="absolute"
                  top="50%"
                  left={{ xs: "36%", sm: "44%", md: "45%", lg: "45%", xl: "41%" }}
                >
                  <MPButton
                    component={Link}
                    to={"/project"}
                    variant="contained"
                    size="small"
                    color="white"
                    sx={{ fontSize: "medium", fontWeight: "bold" }}
                    onClick={() => {
                      dispatchRedux(filterProject({ workType: "", prjType: "", category: "pla" }));
                    }}
                  >
                    기획자
                  </MPButton>
                </MPBox>
              </Grid>
              <Grid item xs={6} md={6} xl={3} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={designerImg}
                    component="img"
                    title="디자이너"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
                <MPBox
                  position="absolute"
                  top="50%"
                  left={{ xs: "32%", sm: "41%", md: "43%", lg: "44%", xl: "39%" }}
                >
                  <MPButton
                    component={Link}
                    to={"/project"}
                    variant="contained"
                    size="small"
                    color="white"
                    sx={{ fontSize: "medium", fontWeight: "bold" }}
                    onClick={() => {
                      dispatchRedux(filterProject({ workType: "", prjType: "", category: "des" }));
                    }}
                  >
                    디자이너
                  </MPButton>
                </MPBox>
              </Grid>
              <Grid item xs={6} md={6} xl={3} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={publisherImg}
                    component="img"
                    title="퍼블리셔"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
                <MPBox
                  position="absolute"
                  top="50%"
                  left={{ xs: "32%", sm: "45%", md: "43%", lg: "44%", xl: "39%" }}
                >
                  <MPButton
                    component={Link}
                    to={"/project"}
                    variant="contained"
                    size="small"
                    color="white"
                    sx={{ fontSize: "medium", fontWeight: "bold" }}
                    onClick={() => {
                      dispatchRedux(filterProject({ workType: "", prjType: "", category: "pub" }));
                    }}
                  >
                    퍼블리셔
                  </MPButton>
                </MPBox>
              </Grid>
              <Grid item xs={6} md={6} xl={3} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={developerImg}
                    component="img"
                    title="개발자"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
                <MPBox
                  position="absolute"
                  top="50%"
                  left={{ xs: "36%", sm: "44%", md: "45%", lg: "45%", xl: "41%" }}
                >
                  <MPButton
                    component={Link}
                    to={"/project"}
                    variant="contained"
                    size="small"
                    color="white"
                    sx={{ fontSize: "medium", fontWeight: "bold" }}
                    onClick={() => {
                      dispatchRedux(filterProject({ workType: "", prjType: "", category: "dev" }));
                    }}
                  >
                    개발자
                  </MPButton>
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox bgColor="#FEFEF5" height="100%" pb={3}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={3} pb={1} lineHeight={1} pl={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="h4">
              DYBSoft 턴키 웹 및 앱 개발 프로젝트 수주
            </MPTypography>
          </MPBox>
          <MPBox pt={1} lineHeight={1} px={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography sx={{ color: "red" }} variant="h6">
              우리는 이렇게 소프트웨어 개발을 합니다.
            </MPTypography>
          </MPBox>

          <MPBox px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={1}>
              <Grid mt={4} item xs={12} md={6} xl={4}>
                <MPBox display="flex" alignItems="center">
                  <MPBox mr={1}>
                    <MPAvatar src={onePng} alt="one-image" size="sm" shadow="sm" />
                  </MPBox>
                  <MPTypography color="black" variant="h5">
                    개발비용 전액 환불제도
                  </MPTypography>
                </MPBox>
                <MPBox p={1}>
                  <MPTypography color="black" variant="body2" fontWeight="regular">
                    결과물이 만족스럽지 않을 경우 개발비용 전액 환불해드립니다. 계약 형태에 따라
                    만족하실 때까지 수정 보완해드립니다.
                  </MPTypography>
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 0, lg: 4 }} item xs={12} md={6} xl={8}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={trustImg}
                    component="img"
                    title={"Trust"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
            <Grid mt={{ xs: 1, lg: 5 }} container spacing={1} flexDirection="row-reverse">
              <Grid mt={4} item xs={12} md={6} xl={4}>
                <MPBox>
                  <MPBox display="flex" alignItems="center">
                    <MPBox mr={1}>
                      <MPAvatar src={twoPng} alt="two-image" size="sm" shadow="sm" />
                    </MPBox>
                    <MPTypography color="black" variant="h5">
                      작업결과
                      <br /> 자사서버에서 실시간 확인가능
                    </MPTypography>
                  </MPBox>
                  <MPBox p={1}>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      자사에서 운영중인 클라우드서버이용, 작업결과물 실시간으로 인터넷 웹
                      브라우저에서 동작하는 모습 확인시켜드립니다.
                    </MPTypography>
                  </MPBox>
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 0, lg: 4 }} item xs={12} md={6} xl={8}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={dybSoftAws}
                    component="img"
                    title={"DYB Soft AWS"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
            <Grid mt={{ xs: 1, lg: 5 }} container spacing={1}>
              <Grid mt={4} item xs={12} md={6} xl={4}>
                <MPBox display="flex" alignItems="center">
                  <MPBox mr={1}>
                    <MPAvatar src={threePng} alt="three-image" size="sm" shadow="sm" />
                  </MPBox>
                  <MPTypography color="black" variant="h5">
                    고객과 끊임없는 의사소통
                  </MPTypography>
                </MPBox>
                <MPBox p={1}>
                  <MPTypography color="black" variant="body2" fontWeight="regular">
                    실제 인터넷 웹브라우저에서 작동하는 작업물을 바탕으로 고객과 주기적으로
                    의사소통합니다. 개선 보완할 부분 끊임없이 반영해드립니다.
                  </MPTypography>
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 0, lg: 4 }} item xs={12} md={6} xl={8}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={communicationImg}
                    component="img"
                    title={"Communication"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
            <Grid mt={{ xs: 1, lg: 5 }} container spacing={1} flexDirection="row-reverse">
              <Grid mt={4} item xs={12} md={6} xl={4}>
                <MPBox display="flex" alignItems="center">
                  <MPBox mr={1}>
                    <MPAvatar src={fourPng} alt="four-image" size="sm" shadow="sm" />
                  </MPBox>
                  <MPTypography color="black" variant="h5">
                    최신 기술스택만 사용
                  </MPTypography>
                </MPBox>
                <MPBox p={1}>
                  <MPTypography color="black" variant="body2" fontWeight="regular">
                    DYB Soft는 검증되고 안정화된 최신 기술만 사용합니다.
                  </MPTypography>
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 0, lg: 4 }} item xs={12} md={6} xl={8}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={dybSoftTechnology}
                    component="img"
                    title={"DYB Soft 기술스택"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
            <MPBox mt={6} mb={3} textAlign="center">
              <MPTypography sx={{ color: "red" }} variant="h5" fontWeight="bold">
                고객님! 결과물로 <br /> 만족시켜드리지 못할 자신이 없습니다.
              </MPTypography>
            </MPBox>
            <MPBox display="flex" justifyContent="center">
              <MPButton
                component={Link}
                to={"/inquiry"}
                variant="contained"
                size="small"
                color="black"
                sx={{ fontSize: "initial" }}
              >
                외주개발 문의
              </MPButton>
            </MPBox>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox bgColor="#FCFCFE" height="100%" pb={5}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={3} lineHeight={1} px={{ xs: 2, lg: 1 }}>
            <MPTypography color="black" variant="h4">
              클라우드기반 서버구축(AWS)
            </MPTypography>
          </MPBox>
          <MPBox pt={1} lineHeight={1} px={{ xs: 2, lg: 1 }}>
            <MPTypography color="black" variant="h6">
              AWS 전문 Solution Architect의 컨설팅 및 서버 구축
            </MPTypography>
          </MPBox>
          <MPBox mt={2} px={{ xs: 2, lg: 1 }}>
            <MPBox display="flex" alignItems="center">
              <MPBox mr={1}>
                <CardMedia
                  src={serverLess}
                  component="img"
                  title={"Server Less"}
                  sx={{
                    maxWidth: "100%",
                    margin: 1,
                    boxShadow: ({ boxShadows: { xl } }) => xl,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MPBox>
              <MPTypography color="black" variant="h5">
                Serverless Architecture <br />
                솔루션 구축
              </MPTypography>
            </MPBox>
            <Grid container spacing={2}>
              <Grid mt={{ xs: 2, lg: 1 }} item xs={12} md={6} xl={4}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={lamda}
                    component="img"
                    title={"Lamda"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 2, lg: 1 }} item xs={12} md={6} xl={4}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={apiGateway}
                    component="img"
                    title={"API Gateway"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 2, lg: 1 }} item xs={12} md={6} xl={4}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={awsFargate}
                    component="img"
                    title={"AWS Fargate"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
          <MPBox mt={4} px={{ xs: 2, lg: 1 }}>
            <MPBox display="flex" alignItems="center">
              <MPBox mr={1}>
                <CardMedia
                  src={serverCloud}
                  component="img"
                  title={"Server Cloud"}
                  sx={{
                    maxWidth: "100%",
                    margin: 1,
                    boxShadow: ({ boxShadows: { xl } }) => xl,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MPBox>
              <MPTypography color="black" variant="h5">
                클라우드기반 <br /> DEV, STG, PRD 서버 구축
              </MPTypography>
            </MPBox>
            <Grid container spacing={2} alignItems="center">
              <Grid mt={{ xs: 2, lg: 1 }} item xs={12} md={6} xl={6}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={serverConfiguration}
                    component="img"
                    title={"SERVER Configuration"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
              <Grid mt={{ xs: 2, lg: 1 }} item xs={12} md={6} xl={6}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={ApacheJmeter}
                    component="img"
                    title={"Apache Jmeter"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
          <MPBox mt={4} display="flex" justifyContent="center">
            <MPBox mr={1}>
              <MPButton
                component={Link}
                to={"/awspage"}
                variant="outlined"
                size="small"
                color="black"
                sx={{ fontSize: "initial" }}
              >
                AWS 활용사례
              </MPButton>
            </MPBox>
            <MPBox>
              <MPButton
                component={Link}
                to={"/inquiry"}
                variant="contained"
                size="small"
                color="black"
                sx={{ fontSize: "initial" }}
              >
                AWS 솔루션 구축문의
              </MPButton>
            </MPBox>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox id="different-reason" bgColor="#FDFDF2" height="100%" pb={2}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox width="100%" display="flex" justifyContent="center" p={3}>
            <CircularProgress color="inherit" />
          </MPBox>
          <MPBox lineHeight={1} textAlign="center">
            <MPTypography color="black" variant="h4">
              {applyTitle}
            </MPTypography>
          </MPBox>
          <MPBox width="100%" borderRadius="xl" display="flex" justifyContent="center" p={1}>
            <CardMedia
              src={arrowDown}
              component="img"
              title={"Arrow"}
              sx={{
                maxWidth: "100%",
                margin: 0,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox
        maxWidth="1080px"
        variant="contained"
        borderRadius="lg"
        bgColor="info"
        coloredShadow="info"
        p={2}
        m={"0 auto"}
        mb={1}
        mt={3}
        textAlign="center"
      >
        <MPTypography variant="h5" fontWeight="medium" color="white">
          DYB Soft 전체 프로젝트 목록
        </MPTypography>
      </MPBox>
      <InfiniteScroll
        dataLength={dataList ? dataList.length : 0} // Current length of data array
        next={handleInfiniteScroll} // Function to handle scroll
        hasMore={hasMore} // Boolean indicating if there is more data to load
        loader={
          <MPBox sx={{ textAlign: "center", backgroundColor: "#FFFFF0", marginTop: "10px" }}>
            <CircularProgress color="inherit" />
          </MPBox>
        } // Loading indicator
        endMessage={
          <p style={{ textAlign: "center", color: "black" }}>
            <MPBox bgColor="#FFFFF0">
              <MPTypography variant="h6" color="black">
                더 이상의 프로젝트가 존재하지 않습니다.
              </MPTypography>
            </MPBox>
          </p>
        }
      >
        <Grid mt={1} justifyContent="center" container>
          {dataList
            ? dataList.map((card, index) => (
                <Grid item key={index} xs={11} sm={11} md={11} lg={11} xl={7} mt={2}>
                  {/* 프로젝트명칭, 기술, 연차, 위치, 단가, 한 행당 두개 카드만 들어가게   */}
                  <Card sx={{ bgcolor: card.recruitmentYn === "Y" ? "#f0f0f0" : "#c0c0c0" }}>
                    <CardContent sx={{ paddingBottom: "0" }}>
                      <MPBox>
                        {card.recruitmentYn == "Y" ? (
                          <Chip
                            key={card.recruitmentYn}
                            sx={{ marginRight: "3px" }}
                            label="모집중"
                            size="small"
                            color="error"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            key={card.recruitmentYn}
                            sx={{ marginRight: "3px" }}
                            label="모집종료"
                            size="small"
                            color="secondary"
                            variant="outlined"
                          />
                        )}
                        {card.projectWorkType == "RE" ? (
                          <Chip
                            key={card.projectWorkType}
                            sx={{ marginRight: "3px" }}
                            label="재택"
                            size="small"
                            color="primary"
                            variant="contained"
                          />
                        ) : (
                          <Chip
                            key={card.projectWorkType}
                            sx={{ marginRight: "3px" }}
                            label="상주"
                            size="small"
                            color="info"
                            variant="contained"
                          />
                        )}
                        {/* <Chip
                            key="반상주"
                            sx={{ marginRight: "3px" }}
                            label="반상주"
                            size="small"
                            color="secondary"
                            variant="contained"
                          /> */}
                      </MPBox>
                      <NavLink to={`/project-detail/${card.projectId}`}>
                        <MPTypography
                          component="div"
                          color="dark"
                          fontSize="large"
                          fontWeight="bold"
                        >
                          {card.projectName}
                        </MPTypography>
                      </NavLink>
                      <MPBox minWidth="200px">
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          예상 금액 : {card.estimatedPriceStart}~{card.estimatedPriceEnd}만원 /{" "}
                          {card.estimatedPriceType == "P" ? "프로젝트 단위" : "월 단위"}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          프로젝트 기간 : {card.period}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          프로젝트 시작일 :{" "}
                          {card.projectStartDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          근무 위치 : {card.address}
                        </MPTypography>
                      </MPBox>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <MPBox>
                        {card.language.split(",").map((language) => (
                          <Chip
                            key={language}
                            sx={{ marginRight: "3px" }}
                            label={language}
                            size="small"
                            color="success"
                            variant="outlined"
                          />
                        ))}
                        {card.framework.split(",").map((framework) => (
                          <Chip
                            key={framework}
                            sx={{ marginRight: "3px" }}
                            label={framework}
                            size="small"
                            color="success"
                            variant="outlined"
                          />
                        ))}
                      </MPBox>
                      <MPBox>
                        {card.recruitmentYn == "Y" ? (
                          <NavLink to={`/project-detail/${card.projectId}`}>
                            <MPButton
                              sx={{
                                marginLeft: "auto",
                                fontWeight: "bold",
                                color: "orange",
                                minWidth: "135px",
                              }}
                              size="small"
                            >
                              프로젝트 상세보기
                            </MPButton>
                          </NavLink>
                        ) : (
                          <MPButton
                            disabled
                            sx={{
                              marginLeft: "auto",
                              fontWeight: "bold",
                              color: "orange",
                              minWidth: "135px",
                            }}
                            size="small"
                          >
                            모집종료
                          </MPButton>
                        )}

                        {/* 모집 종료 버튼
                        <MPButton
                            disabled
                            sx={{
                              marginLeft: "auto",
                              fontWeight: "bold",
                              color: "orange",
                              minWidth: "135px",
                            }}
                            size="small"
                          >
                            모집종료
                          </MPButton>  */}
                      </MPBox>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            : ""}
        </Grid>
      </InfiniteScroll>
      <SvcFooter />
    </HomeLayout>
  );
}

export default Home;
