// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { convertToDateWithDateTime } from "lib/api/utils";
import dybApi from "lib/api/dybApi";
import { Link } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox/index";
import MPTypography from "components/MPTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AWSpageHeader from "layouts/awspage/svcAws/components/AWSpageHeader";

// ====================== Service Components ======================
import SvcFooter from "services/Footer/SvcFooter";
import PageLayout from "services/LayoutContainers/PageLayout/index";
import HomeNavbar from "services/Navbars/HomeNavbar/index";

// ====================== Images ==================================

function AWSpage() {
  const [pagingInfo, setPagingInfo] = useState({});
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const handleChange = (event, page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchBoardList();
    return () => {
      // unmount 시 초기화
    };
  }, [page]);

  const fetchBoardList = async () => {
    try {
      const response = await dybApi.get(`/api/svc/v2/boardList?boardType=aws&page=${page}`);
      if (response.data.result === "SC001") {
        setDataList(response.data.data);
        setPagingInfo(response.data.pagingInfo);
      }
    } catch (error) {
      console.error("Error fetchBoardList : ", error);
    }
  };

  return (
    <PageLayout>
      <HomeNavbar />
      <AWSpageHeader>
        <MPBox pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MPBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={3}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  textAlign="center"
                >
                  <MPTypography variant="h5" color="white">
                    AWS를 이용한 인프라 구축 사례
                  </MPTypography>
                </MPBox>
                <MPBox p={2} minHeight="430px">
                  <TableContainer component={Paper} sx={{ borderRadius: "4px" }}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead
                        sx={{
                          display: "table-header-group",
                          backgroundColor: "#D3D3D3",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ minWidth: 400 }}
                            sx={({ typography: { size, fontWeightMedium } }) => ({
                              fontSize: size.sm,
                              fontWeight: fontWeightMedium,
                            })}
                          >
                            AWS Usage Patterns
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ minWidth: 80 }}
                            sx={({ typography: { size, fontWeightMedium } }) => ({
                              fontSize: size.sm,
                              fontWeight: fontWeightMedium,
                            })}
                          >
                            작성자
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ minWidth: 80 }}
                            sx={({ typography: { size, fontWeightMedium } }) => ({
                              fontSize: size.sm,
                              fontWeight: fontWeightMedium,
                            })}
                          >
                            작성일
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataList.map((data) => (
                          <TableRow key={data.boardId}>
                            <TableCell align="left" style={{ minWidth: 400 }}>
                              <MPTypography
                                display="block"
                                variant="button"
                                sx={({ typography: { size, fontWeightMedium } }) => ({
                                  fontSize: size.sm,
                                  fontWeight: fontWeightMedium,
                                })}
                              >
                                {/* <Link to={`/awspage`}>{data.boardTitle}</Link> */}
                                <Link to={`/awspage/${data.boardId}`}>{data.boardTitle}</Link>
                              </MPTypography>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: 80 }}
                              sx={({ typography: { size, fontWeightRegular } }) => ({
                                fontSize: size.sm,
                                fontWeight: fontWeightRegular,
                              })}
                            >
                              {data.regiUserId}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: 80 }}
                              sx={({ typography: { size, fontWeightRegular } }) => ({
                                fontSize: size.sm,
                                fontWeight: fontWeightRegular,
                              })}
                            >
                              {convertToDateWithDateTime(data.regiDttm)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MPBox>
                <MPBox display="flex">
                  <MPBox
                    sacing={2}
                    sx={{ margin: "0 auto", marginTop: "15px", marginBottom: "20px" }}
                  >
                    <Pagination
                      count={1}
                      defaultPage={1}
                      siblingCount={0}
                      boundaryCount={1}
                      renderItem={(dataList) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...dataList}
                        />
                      )}
                      onChange={handleChange}
                    />
                  </MPBox>
                </MPBox>
              </Card>
            </Grid>
          </Grid>
        </MPBox>
      </AWSpageHeader>
      <SvcFooter sx={{ positon: "fixed", bottom: "0" }} />
    </PageLayout>
  );
}

export default AWSpage;
