// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "../../context";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox/index";
import MPTypography from "components/MPTypography";
import MPInput from "components/MPInput/index";
import MPButton from "components/MPButton/index";
import { TextField } from "../../../node_modules/@mui/material/index";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// ====================== Service Components ======================
import PageLayout from "services/LayoutContainers/PageLayout/index";
import HomeNavbar from "services/Navbars/HomeNavbar/index";
import DataTable from "services/Tables/DataTable";
import SvcFooter from "services/Footer/SvcFooter";
import ProfilesList from "services/Lists/ProfilesList";
import MypageHeader from "layouts/mypage/components/MypageHeader";
import profilesListData from "layouts/profile/data/profilesListData";
import MyProjectData from "layouts/mypage/data/myProjectData";

// ====================== Images ==================================
import WomanDefault from "assets/images/icons/woman_default.jpg";
import ManDefault from "assets/images/icons/man_defalut.jpg";

function Mypage() {
  const [myInfo, setMyInfo] = useState({
    name: "",
    phoneNumber: "",
    userIntroduce: "",
    mainLanguage: "",
    mainFramework: "",
  });

  const [myAddress, setMyAddress] = useState({
    address: "",
    addressDetails: "",
    zipCode: "",
  });
  const [myProjectList, setMyProjectList] = useState([]);
  const [page, setPage] = useState(1);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  const handleChange = (event, page) => {
    setPage(page);
  };

  const handleMyInfo = (event) => {
    const { name, value } = event.target;
    setMyInfo({
      ...myInfo,
      [name]: value,
    });
  };

  const handleMyAddress = (event) => {
    const { name, value } = event.target;
    setMyAddress({
      ...myAddress,
      [name]: value,
    });
  };

  const { columns, rows, pagingInfo } = MyProjectData(page);
  const [controller, dispatch] = useMaterialUIController();
  const { openDialogpopup } = controller;

  const updateMyinfo = async (myInfo) => {
    const updateMyInfo = {
      address: {
        address: myInfo.address,
        addressDetails: myInfo.addressDetails,
        zipCode: myInfo.zipCode,
      },
      mainFramework: myInfo.mainFramework,
      mainLanguage: myInfo.mainLanguage,
      name: myInfo.name,
      phoneNumber: myInfo.phoneNumber,
      userIntroduce: myInfo.userIntroduce,
    };
    try {
      const response = await dybApi.put("/api/svc/v1/updateMyInfo", updateMyInfo);
      if (response.data.result === "SC001") {
        // 성공 popup
        setOpenDialogPopup(dispatch, { modalType: "success", showYn: true });
        console.log("수정완료");
      } else {
        // 실패 popup
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myInfoResponse = await dybApi.get("/api/svc/v1/myInfo");
        if (myInfoResponse.data.result === "SC001") {
          setMyInfo(myInfoResponse.data.data);
          setMyAddress(myInfoResponse.data.data?.address);

          setValue("name", myInfoResponse.data.data.name);
          setValue("phoneNumber", myInfoResponse.data.data.phoneNumber);
          setValue("address", myInfoResponse.data.data?.address?.address);
          setValue("addressDetails", myInfoResponse.data.data?.address?.addressDetails);
          setValue("zipCode", myInfoResponse.data.data?.address?.zipCode);
          setValue("mainLanguage", myInfoResponse.data.data.mainLanguage);
          setValue("mainFramework", myInfoResponse.data.data.mainFramework);
          setValue("userIntroduce", myInfoResponse.data.data.userIntroduce);
        }

        // AccessToken, RefreshToken 테스트
        // const logResponse = await dybApi.get("/api/log");
        // console.log(logResponse.data.data);

        // const infoCheckResponse = await dybApi.get("/api/infoCheck");
        // console.log(infoCheckResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <PageLayout background="#FAFAFD">
      <HomeNavbar />
      <MypageHeader myInfo={myInfo}>
        <MPBox
          component="form"
          role="form"
          onSubmit={handleSubmit((data) => {
            updateMyinfo(data);
          })}
          mt={1}
          mb={3}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <MPBox pt={2} px={2}>
                <MPTypography variant="h6" color="black" fontWeight="medium">
                  개인정보
                </MPTypography>
              </MPBox>
              <MPBox px={2}>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  아이디(이메일) *아이디는 변경 불가능합니다.
                </MPTypography>
                <MPBox>
                  <MPInput fullWidth disabled value={myInfo.email || ""} onChange={handleMyInfo} />
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  이름
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("name", { required: "이름을 입력해주세요." })}
                    // name="name"
                    // value={myInfo.name || ""}
                    onChange={handleMyInfo}
                    fullWidth
                  />
                  <MPBox>
                    {errors.name && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.name.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  연락처
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("phoneNumber", { required: "연락처를 입력해주세요." })}
                    // name="phoneNumber"
                    // value={myInfo.phoneNumber || ""}
                    onChange={handleMyInfo}
                    fullWidth
                  />
                  <MPBox>
                    {errors.phoneNumber && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.phoneNumber.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  주소
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("address", { required: "주소를 입력해주세요." })}
                    // name="address"
                    // value={myAddress.address || ""}
                    onChange={handleMyAddress}
                    fullWidth
                  />
                  <MPBox>
                    {errors.address && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.address.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  상세주소
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("addressDetails", { required: "상세주소를 입력해주세요." })}
                    // name="addressDetails"
                    // value={myAddress.addressDetails || ""}
                    onChange={handleMyAddress}
                    fullWidth
                  />
                  <MPBox>
                    {errors.addressDetails && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.addressDetails.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  우편번호
                </MPTypography>
                <MPBox mb={2}>
                  <MPInput
                    {...register("zipCode", { required: "우편번호를 입력해주세요." })}
                    // name="zipCode"
                    // value={myAddress.zipCode || ""}
                    onChange={handleMyAddress}
                    fullWidth
                  />
                  <MPBox>
                    {errors.zipCode && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.zipCode.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                {/* TODO 이쪽 퍼블 다시 */}
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  *연관프로젝트 실시간 추천해드립니다. <br />
                  언어, 프레임워크 최대 3개 입력 <br />
                </MPTypography>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  주력 언어
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("mainLanguage")}
                    // name="mainLanguage"
                    // value={myInfo.mainLanguage || ""}
                    onChange={handleMyInfo}
                    fullWidth
                  />
                </MPBox>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  주력 프레임워크
                </MPTypography>
                <MPBox>
                  <MPInput
                    {...register("mainFramework")}
                    // name="mainFramework"
                    // value={myInfo.mainFramework || ""}
                    onChange={handleMyInfo}
                    fullWidth
                  />
                </MPBox>
              </MPBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <MPBox p={2}>
                <MPTypography variant="h6" color="black" fontWeight="medium">
                  자기소개
                </MPTypography>
                <MPTypography variant="caption" fontWeight="bold" color="text">
                  * 보유기술로 간략하게 자기소개를 해주세요.
                </MPTypography>
                <TextField
                  {...register("userIntroduce")}
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  // name="userIntroduce"
                  // value={myInfo.userIntroduce || ""}
                  onChange={handleMyInfo}
                />
                <MPBox mt={2}>
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                    startIcon={<DoneAllIcon />}
                    disabled={isSubmitting}
                  >
                    내정보 수정하기
                  </MPButton>
                </MPBox>
              </MPBox>
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <ProfilesList title="나의 친구목록" profiles={profilesListData} shadow={false} />
            </Grid>
          </Grid>
        </MPBox>
        <MPBox pt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MPBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={3}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MPTypography variant="h6" color="white">
                    내가 지원한 프로젝트 현황
                  </MPTypography>
                </MPBox>
                <MPBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MPBox>
                <MPBox display="flex">
                  <MPBox
                    sacing={2}
                    sx={{ margin: "0 auto", marginTop: "15px", marginBottom: "20px" }}
                  >
                    <Pagination
                      count={pagingInfo.totPage}
                      defaultPage={1}
                      siblingCount={0}
                      boundaryCount={1}
                      renderItem={(dataList) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...dataList}
                        />
                      )}
                      onChange={handleChange}
                    />
                  </MPBox>
                </MPBox>
              </Card>
            </Grid>
          </Grid>
        </MPBox>
      </MypageHeader>
      <SvcFooter />
    </PageLayout>
  );
}

export default Mypage;
