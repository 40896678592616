/*
SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "./context";

// ====================== MP&MUI Components  ======================
import MPBox from "./components/MPBox";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { CssBaseline, ThemeProvider } from "@mui/material";

// ====================== Service Components ======================
import Sidenav from "./services/Sidenav";
import Configurator from "./services/Configurator";
import DialogPopup from "./services/DialogPopup";
import AdminRoute from "lib/routerCheck/AdminRoute";
import PrivateRoute from "lib/routerCheck/PrivateRoute";
import routes from "./routes";

// ====================== Images ==================================
import theme from "assets/theme/theme-rtl";
import brandWhite from "assets/images/logos/dybsoft/providence-logo-white.png";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // route에 auth가 true일 경우 접근제한
      if (route.admin && route.auth) {
        return (
          <Route
            exact
            path={route.route}
            element={<AdminRoute>{route.component}</AdminRoute>}
            key={route.key}
          />
        );
      } else if (route.auth) {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      } else {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
    });

  const configsButton = (
    <MPBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleMiniSidenav}
    >
      <FolderOpenIcon />
    </MPBox>
  );

  // if (isMobile) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Do Your Best"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* Context API 활용한 태마 변경 */}
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* Dialog popup 전역으로 등록해 두기 */}
      <DialogPopup />
    </ThemeProvider>
  );
  // } else {
  //   alert("모바일전용 사이트이므로, PC버전은 지원하지 않습니다. \n모바일 기기로 이용부탁드립니다.");
  // }
}
