import { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { setOpenDialogPopup, useMaterialUIController } from "../../context";

const AdminRoute = ({ children }) => {
  // 프론트에서 막아주고, api로 두 번 막기.
  // api로 안 막을 경우 막말로 localStorate, sessionStorage 임의로 변경하면 접근 가능해진다.
  const [userType, setUserType] = useState(
    JSON.parse(localStorage.getItem("DYBSoft"))
      ? JSON.parse(localStorage.getItem("DYBSoft")).userType
      : "" || JSON.parse(sessionStorage.getItem("DYBSoft"))
      ? JSON.parse(sessionStorage.getItem("DYBSoft")).userType
      : ""
  );
  // const [attemptedRoute, setAttemptedRoute] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { openDialogpopup } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    if (userType !== "Adm") {
      // 관리자만 접근할 수 있는 페이지입니다.
      history.back();
      setOpenDialogPopup(dispatch, { modalType: "admin", showYn: true });
    }
  }, [userType, dispatch]);

  return children;
};

export default AdminRoute;
