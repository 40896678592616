import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MPBox p={2}>
        <MPTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </MPTypography>
      </MPBox>
      <MPBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MPTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </MPTypography>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              Email me when someone follows me
            </MPTypography>
          </MPBox>
        </MPBox>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={answersPost} onChange={() => setAnswersPost(!answersPost)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              Email me when someone answers on my post
            </MPTypography>
          </MPBox>
        </MPBox>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={mentionsMe} onChange={() => setMentionsMe(!mentionsMe)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              Email me when someone mentions me
            </MPTypography>
          </MPBox>
        </MPBox>
        <MPBox mt={3}>
          <MPTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            application
          </MPTypography>
        </MPBox>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              New launches and projects
            </MPTypography>
          </MPBox>
        </MPBox>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={productUpdate} onChange={() => setProductUpdate(!productUpdate)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              Monthly product updates
            </MPTypography>
          </MPBox>
        </MPBox>
        <MPBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MPBox mt={0.5}>
            <Switch checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
          </MPBox>
          <MPBox width="80%" ml={0.5}>
            <MPTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </MPTypography>
          </MPBox>
        </MPBox>
      </MPBox>
    </Card>
  );
}

export default PlatformSettings;
