import axios from "axios";
import Cookies from "js-cookie";
import { logoutRemoveAuthInfo } from "lib/api/utils";

const API_TIMEOUT = 30000; // 30s

const dybApi = axios.create({
  baseURL: "https://www.dybsoft.com", // devl
  timeout: API_TIMEOUT,
  withCredentials: false,
  changeOrigin: true,
  headers: {
    Authorization: "Bearer " + Cookies.get("AccessToken") || "",
    "Access-Control-Max-Age": "600",

    // "Content-Type": "multipart/form-data; application/json;",
  },
});

// Function to refresh the access token
const refreshAccessToken = () => {
  // Make a request to refresh the access token
  const JWT = {
    refreshToken: Cookies.get("RefreshToken"),
  };
  return dybApi.post("/api/svc/v2/refreshJwt", JWT);
};

// interceptor request
dybApi.interceptors.request.use(
  (config) => {
    // Add the access token to the request headers
    config.headers["Authorization"] = `Bearer ${Cookies.get("AccessToken") || ""}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

dybApi.interceptors.response.use(
  (response) => {
    // If the response is successful, return it directly
    return response;
  },
  // axios 통신이 200 아닐 때 다 error로 감. 200이면 response로 가니까 모든 api 다 확인하게 된다. 성능문제 있을듯.
  async (error) => {
    const originalRequest = error.config;

    // Check if the error response status code indicates an expired access token
    if (error.response.data.result == "JT002" && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh the access token
        const refreshTokenResponse = await refreshAccessToken();

        // Update the access token with the new one
        const newAccessToken = refreshTokenResponse.data.data.accessToken;
        const newRefreshToken = refreshTokenResponse.data.data.refreshToken;

        const at = new Date();
        const rt = new Date();
        at.setTime(at.getTime() + 1000 * 60 * 60 * 24 * 30); // 30일
        rt.setTime(rt.getTime() + 1000 * 60 * 60 * 24 * 90); // 90일
        Cookies.set("AccessToken", newAccessToken, { expires: at });
        Cookies.set("RefreshToken", newRefreshToken, { expires: rt });

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return dybApi(originalRequest);
      } catch (refreshError) {
        // refreshToken 발급받는 과정에서 에러가 났을 경우 -> 재 로그인해야함.
        console.error("RefreshToken Expired :", refreshError);
        /*
         모달 띄우는 건 불가능함. (react hook을 못쓰기 때문에)
         redirectToLogin 페이지로 가서 로그인할 지 홈으로 이동할지 결정하게
         TODO:: 더 나은 방식은 없는가?
         */
        logoutRemoveAuthInfo("redirectToLogin");

        throw error; // Throw the original error to maintain the error flow
      } finally {
      }
    }
    return Promise.reject(error);
  }
);

export default dybApi;
