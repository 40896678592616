// ====================== React Library ===========================
import { useState, useEffect } from "react";
import dybApi from "lib/api/dybApi";
import { useParams, useNavigate, Link } from "react-router-dom";
import { convertToDate, convertToDateTime } from "lib/api/utils";

// ====================== MP&MUI Components  ======================
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MPBox from "components/MPBox/index";
import MPTypography from "components/MPTypography";
import Avatar from "@mui/material/Avatar";

// ====================== Service Components ======================
import DataTable from "services/Tables/DataTable";
import PageLayout from "services/LayoutContainers/PageLayout/index";
import HomeNavbar from "services/Navbars/HomeNavbar/index";
import SvcFooter from "services/Footer/SvcFooter";
import AWSpageHeader from "layouts/awspage/svcAws/components/AWSpageHeader";
import DOMPurify from "dompurify";

// ====================== Images ==================================
import awsbackground from "assets/images/background/aws-background3.jpg";
import breakpoints from "assets/theme/base/breakpoints";
import leftArrow from "assets/images/icons/arrow_back.svg";

function AWSDetail() {
  const { pageId } = useParams();
  const navigate = useNavigate();
  const [htmlCode, setHtmlCode] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {
    fetchBoardDetail();
    return () => {
      // unmount 시 초기화
    };
  }, [pageId]);

  const fetchBoardDetail = async () => {
    try {
      const response = await dybApi.get(`/api/svc/v1/boardDetail?boardId=${pageId}`);
      if (response.data.result === "SC001") {
        setData(response.data.data);
        const sanitizedHtmlCode = DOMPurify.sanitize(response.data.data.boardDescription);
        setHtmlCode(sanitizedHtmlCode);
      }
    } catch (error) {
      console.error("Error fetchBoardDetail: ", error);
    }
  };

  return (
    <PageLayout>
      <HomeNavbar />
      <MPBox position="relative" maxWidth={{ lg: "1080px" }} m={"0 auto"} p={1}>
        <MPBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="8.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `url(${awsbackground})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 1,
            py: 2,
            px: 1,
          }}
        >
          <Grid container p={1} justifyContent="space-between" flexWrap="no-wrap">
            <Grid item>
              <MPTypography variant="h5" fontWeight="medium">
                {data.boardTitle}
              </MPTypography>
            </Grid>
            <Grid item>
              <MPTypography variant="caption" fontWeight="regular">
                작성일 : {convertToDateTime(data.regiDttm)}
              </MPTypography>
            </Grid>
          </Grid>
          <MPTypography pl={2} mt={2} variant="h6" fontWeight="bold">
            AWS 인프라 구축 상세 내용
          </MPTypography>
          <Grid
            container
            justifyContent="center"
            p={1}
            spacing={1}
            sx={{ overflowX: "auto", overflowY: "hidden" }}
          >
            <Grid item xs={12}>
              <MPBox position="relative" width="100%" mt={1} mb={2}>
                <MPTypography
                  variant="body2"
                  fontWeight="regular"
                  dangerouslySetInnerHTML={{ __html: htmlCode ? htmlCode : "" }}
                ></MPTypography>
              </MPBox>
            </Grid>
          </Grid>
          <MPBox p={1} mt={1}>
            <Chip
              avatar={<Avatar alt="back" src={leftArrow} />}
              label="AWS 인프라 구축 사례 목록"
              onClick={() => navigate(`/awspage`)}
            />
          </MPBox>
        </Card>
      </MPBox>
      <SvcFooter sx={{ positon: "fixed", bottom: "0" }} />
    </PageLayout>
  );
}

export default AWSDetail;
