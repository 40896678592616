// ====================== React Library ===========================
import { useState, useEffect } from "react";
import dybApi from "lib/api/dybApi";
import { convertToDate } from "lib/api/utils";
import { Link } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPButton from "components/MPButton/index";
import MPTypography from "components/MPTypography";
import MPAvatar from "components/MPAvatar";
import MPBadge from "components/MPBadge";
import LoupeIcon from "@mui/icons-material/Loupe";
// ====================== Service Components ======================
// ====================== Images ==================================
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function AdminProjectData(page, searchValue, fetch, sortCode) {
  const [dataList, setDataList] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({});

  useEffect(() => {
    fetchProjectList();
    return () => {
      // unmount 시 초기화
    };
  }, [page, fetch, sortCode]);
  const fetchProjectList = async () => {
    try {
      const response = await dybApi.get(
        `/api/adm/projectList?page=${page}&search=${searchValue}&sortCode=${sortCode}`
      );
      if (response.data.result === "SC001") {
        setDataList(response.data.data);
        setPagingInfo(response.data.pagingInfo);
      }
    } catch (error) {
      console.error("Error fetchProjectList : ", error);
    }
  };

  const Person = ({ image, name, maneger, projectId }) => (
    <MPBox display="flex" alignItems="center" lineHeight={1}>
      <MPAvatar src={image} name={name} size="md" />
      <MPBox ml={2} lineHeight={1}>
        <MPTypography
          display="block"
          variant="button"
          sx={({ typography: { size, fontWeightMedium } }) => ({
            fontSize: size.sm,
            fontWeight: fontWeightMedium,
          })}
        >
          <Link to={`/adm/project-detail/${projectId}`}>{name}</Link>
        </MPTypography>

        <MPTypography
          variant="caption"
          sx={({ typography: { size, fontWeightRegular } }) => ({
            fontSize: size.xs,
            fontWeight: fontWeightRegular,
          })}
        >
          {maneger}
        </MPTypography>
      </MPBox>
    </MPBox>
  );

  const Job = ({ title, description }) => (
    <MPBox lineHeight={1} textAlign="left">
      <MPTypography
        display="block"
        variant="caption"
        color="black"
        sx={({ typography: { size, fontWeightMedium } }) => ({
          fontSize: size.sm,
          fontWeight: fontWeightMedium,
        })}
      >
        {title}
      </MPTypography>
      <MPTypography
        variant="caption"
        color="black"
        sx={({ typography: { size, fontWeightRegular } }) => ({
          fontSize: size.sm,
          fontWeight: fontWeightRegular,
        })}
      >
        {description}
      </MPTypography>
    </MPBox>
  );

  const tableColumn = [
    { Header: "프로젝트 명칭", accessor: "projectName", width: "40%", align: "left" },
    { Header: "프로젝트 견적", accessor: "function", align: "left" },
    { Header: "지원자 수", accessor: "prjApplyNum", align: "center" },
    { Header: "프로젝트 위치", accessor: "address", align: "center" },
    { Header: "프로젝트 시작일", accessor: "startDate", align: "center" },
    { Header: "지원자 확인", accessor: "action", align: "center" },
  ];

  const dybSoftManpower = dataList
    ? dataList.map((card, index) => ({
        projectName: (
          <Person
            image={team2}
            name={card.projectName}
            maneger="김성준 매니저"
            projectId={card.projectId}
          />
        ),
        function: (
          <Job
            title={card.estimatedPriceStart + "만원"}
            description={card.estimatedPriceStart + "~" + card.estimatedPriceEnd + "만원"}
          />
        ),
        prjApplyNum: (
          <MPBox>
            <MPTypography
              component="div"
              variant="caption"
              color="black"
              sx={({ typography: { size, fontWeightRegular } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightRegular,
              })}
            >
              {card.prjApplyNum}
            </MPTypography>
          </MPBox>
        ),
        address: (
          <MPTypography
            variant="caption"
            color="black"
            sx={({ typography: { size, fontWeightRegular } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightRegular,
            })}
          >
            {card.address}
          </MPTypography>
        ),
        startDate: (
          <MPTypography
            variant="caption"
            color="black"
            sx={({ typography: { size, fontWeightRegular } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightRegular,
            })}
          >
            {convertToDate(card.projectStartDate)}
          </MPTypography>
        ),
        action: (
          <MPTypography
            variant="caption"
            color="black"
            sx={({ typography: { size, fontWeightRegular } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightRegular,
            })}
          >
            <Link to={`/adm/contract/${card.projectId}`}>
              <MPButton
                fullWidth
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<LoupeIcon />}
              >
                지원내역
              </MPButton>
            </Link>
          </MPTypography>
        ),
      }))
    : "";

  return {
    columns: tableColumn,
    rows: dybSoftManpower,
    pagingInfo: pagingInfo,
  };
}
export default AdminProjectData;
