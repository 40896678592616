// ====================== React Library ===========================
import { Link, useNavigate } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import MPTypography from "components/MPTypography";
import MPButton from "components/MPButton";
import MPBox from "components/MPBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import HomeIcon from "@mui/icons-material/Home";

// ====================== Service Components ======================
import PageLayout from "services/LayoutContainers/PageLayout/index";

// ====================== Images ==================================
import typography from "assets/theme/base/typography";

function RedirectToLogin() {
  const navigate = useNavigate();
  const { size } = typography;

  return (
    <PageLayout background="#FEFEF5">
      <MPBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} sm={9} md={6} lg={4} xl={3}>
            <Card>
              <MPBox
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="info"
                textAlign="center"
                mx={1}
                mt={-3}
                p={2}
                mb={1}
              >
                <MPTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                  계정인증이 만료되었습니다.
                </MPTypography>
              </MPBox>
              <MPBox p={3}>
                <MPBox mb={3}>
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    fullWidth
                    variant="outlined"
                    color="warning"
                    startIcon={<HomeIcon />}
                    onClick={() => navigate("/")}
                  >
                    DYB Soft 홈으로 이동
                  </MPButton>
                </MPBox>
                <MPBox>
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    fullWidth
                    variant="outlined"
                    color="info"
                    startIcon={<HomeIcon />}
                    onClick={() => navigate("/authentication/sign-in")}
                  >
                    로그인 페이지로 이동
                  </MPButton>
                </MPBox>
              </MPBox>
            </Card>
            <MPBox fontSize={size.sm} pt={2} px={0.5} display="flex" justifyContent="center">
              <MPBox>
                &copy; {new Date().getFullYear()}, made by
                <Link to={"/"} target="_blank">
                  <MPTypography variant="button" fontWeight="medium">
                    &nbsp;{"Providence Inc."}&nbsp;
                  </MPTypography>
                </Link>
                All Rights Reserved.
              </MPBox>
            </MPBox>
          </Grid>
        </Grid>
      </MPBox>
    </PageLayout>
  );
}
export default RedirectToLogin;
