// ====================== React Library ===========================
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { convertToDateTime } from "lib/api/utils";
import dybApi from "lib/api/dybApi";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox/index";
import MPInput from "components/MPInput";
import MPTypography from "components/MPTypography/index";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";

import Select from "@mui/material/Select";
import { Grid } from "../../../../node_modules/@mui/material/index";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";

import MPButton from "components/MPButton/index";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
// ====================== Service Components ======================
import DashboardLayout from "services/LayoutContainers/DashboardLayout";
import AdmFooter from "services/Footer/AdmFooter/index";

// ====================== Images ==================================
import breakpoints from "assets/theme/base/breakpoints";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiTypography-root": {
    padding: theme.spacing(1),
  },
}));

function AdmInquiry() {
  const [order, setOrder] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [page, setPage] = useState(1);
  const [qnaType, setQnaType] = useState("");
  const [pagingInfo, setPagingInfo] = useState({});
  const [dataList, setDataList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [qnaContent, setQnaContent] = useState("");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleChangeQnaType = (event) => {
    console.log(event.target.value);
    setQnaType(event.target.value);
  };
  const handleChange = (event, page) => {
    setPage(page);
  };
  const fetchQnaList = async () => {
    try {
      const response = await dybApi.get(`api/adm/qnaList?page=${page}&qnaType=${qnaType}`);
      if (response.data.result === "SC001") {
        setDataList(response.data.data);
        setPagingInfo(response.data.pagingInfo);
      }
    } catch (error) {
      console.error("Error fetchBoardList : ", error);
    }
  };

  const showQnaContent = (qnaContent) => {
    setOpen(true);
    setQnaContent(qnaContent);
  };

  useEffect(() => {
    fetchQnaList();

    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, [page]);

  return (
    <DashboardLayout>
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item mb={{ xs: 1, lg: 0 }}>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<SaveIcon />}
          >
            저장하기
          </MPButton>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<VisibilityIcon />}
            onClick={fetchQnaList}
          >
            조회하기
          </MPButton>
        </Grid>
        {mobileView && (
          <MPBox mb={1}>
            <MPInput
              m="0 auto"
              sx={{ width: "300px" }}
              type="text"
              label="문의 제목으로 검색"
              value={searchValue}
              onChange={handleInputChange}
            />
          </MPBox>
        )}
        {!mobileView && (
          <Grid mt={1} item xs={12}>
            <Card>
              <Grid container justifycontent="space-around" p={3}>
                <Grid item display="flex">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* 모바일에서는 필터식으로 마크업하기 */}

                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        조회 시작일
                      </MPTypography>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        mask={"____-__-__"}
                        label="From"
                        background="white"
                        sx={{
                          width: "200px",
                          minWidth: "200px",
                          marginRight: "10px",
                        }}
                      />
                    </MPBox>
                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        조회 종료일
                      </MPTypography>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        mask={"____-__-__"}
                        label="To"
                        sx={{ width: "200px", minWidth: "200px", marginRight: "30px" }}
                      />
                    </MPBox>

                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <MPBox>
                    <MPTypography
                      component="div"
                      variant="caption"
                      mb={0.5}
                      color="black"
                      fontWeight="regular"
                    >
                      문의 유형
                    </MPTypography>
                  </MPBox>
                  <FormControl sx={{ width: "120px" }}>
                    <Select value={qnaType} onChange={handleChangeQnaType}>
                      <MenuItem value={"AWS"}>AWS</MenuItem>
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"SM"}>SM</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item ml="auto">
                  <MPBox>
                    <MPTypography
                      component="div"
                      variant="caption"
                      mb={0.5}
                      color="black"
                      fontWeight="regular"
                    >
                      검색어 입력
                    </MPTypography>
                  </MPBox>
                  <MPBox>
                    <MPInput
                      sx={{ width: { xs: "270px", lg: "400px" } }}
                      type="text"
                      label="문의 제목으로 검색"
                      value={searchValue}
                      onChange={handleInputChange}
                    />
                  </MPBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
        <Grid item mt={5} xs={12}>
          <Card>
            <MPBox
              mx={2}
              mt={-3}
              py={3}
              px={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MPTypography variant="h5" color="white">
                문의 내용 관리
              </MPTypography>
            </MPBox>
            <MPBox p={2} minHeight="430px">
              <TableContainer component={Paper} sx={{ borderRadius: "4px" }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead
                    sx={{
                      display: "table-header-group",
                      backgroundColor: "#D3D3D3",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의 유형
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의 제목
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의자 이름
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의자 연락처
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의자 Email
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        문의자 IP 주소
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 80 }}
                        sx={({ typography: { size, fontWeightMedium } }) => ({
                          fontSize: size.sm,
                          fontWeight: fontWeightMedium,
                        })}
                      >
                        작성일
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataList.map((data) => (
                      <TableRow key={data.qnaId}>
                        <TableCell align="center" style={{ minWidth: 80 }}>
                          <MPTypography
                            display="block"
                            variant="button"
                            sx={({ typography: { size, fontWeightRegular } }) => ({
                              fontSize: size.sm,
                              fontWeight: fontWeightRegular,
                            })}
                          >
                            {data.qnaType}
                          </MPTypography>
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 80 }}>
                          <MPTypography
                            display="block"
                            variant="button"
                            onClick={() => showQnaContent(data.qnaContent)}
                            sx={({ typography: { size, fontWeightMedium } }) => ({
                              fontSize: size.sm,
                              fontWeight: fontWeightMedium,
                            })}
                          >
                            {data.qnaTitle}
                          </MPTypography>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 80 }}
                          sx={({ typography: { size, fontWeightRegular } }) => ({
                            fontSize: size.sm,
                            fontWeight: fontWeightRegular,
                          })}
                        >
                          {data.qnaName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 80 }}
                          sx={({ typography: { size, fontWeightRegular } }) => ({
                            fontSize: size.sm,
                            fontWeight: fontWeightRegular,
                          })}
                        >
                          {data.qnaPhone}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 80 }}
                          sx={({ typography: { size, fontWeightRegular } }) => ({
                            fontSize: size.sm,
                            fontWeight: fontWeightRegular,
                          })}
                        >
                          {data.qnaEmail}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 80 }}
                          sx={({ typography: { size, fontWeightRegular } }) => ({
                            fontSize: size.sm,
                            fontWeight: fontWeightRegular,
                          })}
                        >
                          {data.ipAddress}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 80 }}
                          sx={({ typography: { size, fontWeightRegular } }) => ({
                            fontSize: size.sm,
                            fontWeight: fontWeightRegular,
                          })}
                        >
                          {convertToDateTime(data.regiDttm)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <BootstrapDialog open={open} onClose={handleClose}>
                      <DialogContent>
                        <MPTypography>{qnaContent}</MPTypography>
                      </DialogContent>
                    </BootstrapDialog>
                  </TableBody>
                </Table>
              </TableContainer>
            </MPBox>

            <MPBox display="flex">
              <MPBox sacing={2} sx={{ margin: "0 auto", marginTop: "15px", marginBottom: "20px" }}>
                <Pagination
                  count={pagingInfo.totPage}
                  defaultPage={1}
                  siblingCount={0}
                  boundaryCount={1}
                  renderItem={(dataList) => (
                    <PaginationItem
                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                      {...dataList}
                    />
                  )}
                  onChange={handleChange}
                />
              </MPBox>
            </MPBox>
          </Card>
        </Grid>
      </Grid>
      <AdmFooter />
    </DashboardLayout>
  );
}

export default AdmInquiry;
