/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

import { createAction, handleActions } from "redux-actions";

/* 액션 타입 만들기 */
// Ducks 패턴을 따를땐 액션의 이름에 접두사를 넣기.
// 이렇게 하면 다른 모듈과 액션 이름이 중복되는 것을 방지 할 수 있다.
const SEARCH_KEYWORD = "projectFilter/SEARCH_KEYWORD";
const PROJECT_FILTER = "projectFilter/PROJECT_FILTER";

/* 액션 생성함수 만들기 */
// 액션 생성함수를 만들고 export 키워드를 사용해서 내보내기
export const searchKeyword = createAction(SEARCH_KEYWORD, (keyword) => keyword);
export const filterProject = createAction(PROJECT_FILTER, (projectInfo) => projectInfo);

const initialState = {
  keyword: "",
  workType: "",
  prjType: "",
  category: "",
};

//handleActions 는  리듀서 대체
const filterReducer = handleActions(
  {
    [SEARCH_KEYWORD]: (state, action) => ({
      ...state,
      keyword: action.payload,
    }),
    [PROJECT_FILTER]: (state, action) => ({
      ...state,
      workType: action.payload.workType,
      prjType: action.payload.prjType,
      category: action.payload.category,
    }),
  },
  initialState
);

export default filterReducer;
