// ====================== React Library ===========================
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "context/";
import "blob-polyfill";

// ====================== MP&MUI Components  ======================
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DownloadIcon from "@mui/icons-material/Download";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PublishIcon from "@mui/icons-material/Publish";
import MPTypography from "components/MPTypography/index";
import MPButton from "components/MPButton";
import MPBox from "components/MPBox/index";
import MPInput from "components/MPInput";

// ====================== Service Components ======================
import Calendar from "../../../node_modules/react-calendar/dist/cjs/Calendar";
import "react-calendar/dist/Calendar.css";
// ====================== Images ==================================

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(MPBox)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? grey[200] : grey[800],
}));

const Puller = styled(MPBox)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[400] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const navigate = useNavigate();
  const { window } = props;
  const [controller, dispatch] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    // accept: "image/*", // Only accept image files
    maxFiles: 1, // Limit the number of files to 1
    noDrag: true,
  });
  const files = acceptedFiles.map((file, index) => (
    <MPBox key={index}>
      <MPTypography
        sx={{ whiteSpace: "no-wrap", overflow: "hidden", textOverflow: "ellipsis" }}
        fontSize="medium"
        fontWeight="bold"
        color="inherit"
      >
        {index + 1}. {file.name}
      </MPTypography>
    </MPBox>
  ));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  const fileInputRef = useRef(null);
  const handleClickSelectFiles = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const applyForProject = async (applyProject) => {
    try {
      const response = await dybApi.post("/api/svc/v1/applyForProject", applyProject);
      if (response.data.result === "SC001") {
        setOpenDialogPopup(dispatch, {
          modalType: "sucCustom",
          showYn: true,
          title: "지원완료!",
          content: "프로젝트 지원완료 되었습니다",
          reload: true,
        });
      } else {
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log("Error apply project:", error);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };
  const downloadResume = async () => {
    try {
      // S3 붙여봐(사용자 단 파일 다운로드 경우)
      // 일단 adm 열면 안된다.(경로 바꿔서 열기)
      const response = await dybApi.get(
        "/api/svc/v2/downloadfile/dybsoft_resume_form.docx?fileType=DYBSOFT",
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dybsoft_개인이력카드.docx"); // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the blob URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error.response);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
            margin: "0 auto",
            width: "100%",
            maxWidth: "800px",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            width: "100%",
            // right: 0,
            // left: 0,
          }}
        >
          <Puller />
          <MPTypography fontWeight="regular" sx={{ p: 2, color: "inherit", textAlign: "center" }}>
            <SwapVertIcon /> 프로젝트 지원
          </MPTypography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <MPBox pt={4} pb={3} px={3} sx={{ background: "white" }}>
            <MPTypography variant="h6" color="inherit">
              희망 견적(단가) /월 단위
            </MPTypography>
            <MPBox
              component="form"
              role="form"
              onSubmit={handleSubmit((data) => {
                if (!acceptedFiles[0]) {
                  setOpenDialogPopup(dispatch, {
                    modalType: "failCustom",
                    showYn: true,
                    content: "이력서를 첨부해주세요",
                  });
                  return;
                }
                const dY = data.prjStPosDate.getFullYear();
                let dM = data.prjStPosDate.getMonth() + 1;
                const dD = data.prjStPosDate.getDate();
                dM.toString().length == 1 ? (dM = "0" + dM.toString()) : dM;

                const prjStPosDate = dY.toString() + dM + dD.toString();
                const dataDto = {
                  devHopeStartPrice: data.devHopeStartPrice,
                  devHopeEndPrice: data.devHopeEndPrice,
                  // devHopeTotalPrice: 1200, //api에서 제거하기
                  prjStPosDate: prjStPosDate,
                  projectId: props.projectId,
                };
                const formData = new FormData();
                const applyProjectDto = JSON.stringify(dataDto);
                const blob = new Blob([applyProjectDto], { type: "application/json" });
                formData.append("resume", acceptedFiles[0]);
                formData.append("applyProjectDto", blob);
                console.log(dataDto, "데이타");
                console.log(acceptedFiles[0], "파일");
                applyForProject(formData);
              })}
            >
              <MPBox display="flex" alignItems="center" justifyContent="center">
                <MPInput
                  fullWidth
                  type="number"
                  label="만원"
                  {...register("devHopeStartPrice", {
                    required: "희망 견적을 입력해주세요.",
                  })}
                />
                ~
                <MPInput
                  fullWidth
                  type="number"
                  label="만원"
                  {...register("devHopeEndPrice", {
                    required: "희망 견적을 입력해주세요.",
                  })}
                />
              </MPBox>
              <MPBox>
                {(errors.devHopeStartPrice || errors.devHopeEndPrice) && (
                  <MPTypography variant="caption" color="error" fontWeight="bold">
                    {errors.devHopeStartPrice
                      ? errors.devHopeStartPrice.message
                      : "" || errors.devHopeEndPrice
                      ? errors.devHopeEndPrice.message
                      : ""}
                  </MPTypography>
                )}
              </MPBox>
              <MPTypography mt={3} variant="h6" color="inherit">
                프로젝트 시작 가능일
              </MPTypography>
              <MPBox mb={3} display="flex" justifyContent="center">
                <Calendar
                  value={setValue("prjStPosDate", selectedDate)}
                  {...register("prjStPosDate", { required: "시작 가능일을 선택해주세요." })}
                  onChange={handleDateChange}
                />
                <MPBox>
                  {errors.prjStPosDate && (
                    <MPTypography variant="caption" color="error" fontWeight="bold">
                      {errors.prjStPosDate.message}
                    </MPTypography>
                  )}
                </MPBox>
              </MPBox>
              <MPBox display="flex">
                <MPTypography mb={1} variant="h6" color="inherit">
                  이력서 업로드{" "}
                </MPTypography>
                <MPTypography mt={1} variant="caption" fontWeight="regular" color="inherit">
                  * 파일 최대 1개 등록
                </MPTypography>
              </MPBox>
              <MPBox>
                <input {...getInputProps()} ref={fileInputRef} sx={{ display: "none" }} />

                {props.isLogIn && props.isApplied == "N" ? (
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={handleClickSelectFiles}
                  >
                    파일 추가
                  </MPButton>
                ) : (
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    disabled
                  >
                    파일 추가
                  </MPButton>
                )}
                <MPBox mt={1}>{files}</MPBox>
              </MPBox>
              <MPBox mt={3} mb={1}>
                {!props.isLogIn ? (
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    type="submit"
                    variant="gradient"
                    fontWeight="bold"
                    color="secondary"
                    fullWidth
                    onClick={() => navigate("/authentication/sign-in")}
                    startIcon={<LockOpenIcon />}
                  >
                    로그인 후 지원가능
                  </MPButton>
                ) : props.isApplied == "Y" ? (
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    type="submit"
                    variant="gradient"
                    fontWeight="bold"
                    color="info"
                    fullWidth
                    disabled
                    startIcon={<PublishIcon />}
                  >
                    이미 지원완료 하셨습니다.
                  </MPButton>
                ) : (
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    type="submit"
                    variant="gradient"
                    fontWeight="bold"
                    color="info"
                    fullWidth
                    startIcon={<PublishIcon />}
                  >
                    지원완료 하기
                  </MPButton>
                )}
              </MPBox>
            </MPBox>
            <MPTypography mt={3} variant="h6" color="inherit">
              자사 이력서 양식 다운로드
            </MPTypography>
            <MPButton
              sx={{ fontSize: "initial" }}
              type="submit"
              variant="gradient"
              fontWeight="bold"
              color="info"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={downloadResume}
            >
              이력서 다운로드
            </MPButton>
          </MPBox>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
