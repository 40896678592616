// ====================== React Library ===========================
import { useState, useEffect, forwardRef, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import dybApi from "lib/api/dybApi";
import { convertToDate } from "lib/api/utils";

import { setOpenDialogPopup, useMaterialUIController } from "context/";
// draft 사용방법 https://haranglog.tistory.com/12
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPInput from "components/MPInput/index";
import MPButton from "components/MPButton/index";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

// ====================== Service Components ======================
import DashboardLayout from "services/LayoutContainers/DashboardLayout";
import AdmProjectNavbar from "services/Navbars/AdmProjectNavbar";
import AdmFooter from "services/Footer/AdmFooter";
import AdmContractHeader from "layouts/contract/components/AdmContractHeader";

// ====================== Images ==================================

function AdmContractList() {
  let page = 1;
  const { projectId } = useParams();

  const [contractList, setContractList] = useState([]);

  const [controller, dispatch] = useMaterialUIController();

  const changeContractStep = async (contractId, event) => {
    try {
      const response = await dybApi.put(
        `/api/adm/updateContractStep?contractId=${contractId}&contractStep=${event.target.dataset.value}`
      );
      if (response.data.result === "SC001") {
        // 성공 popup
        setOpenDialogPopup(dispatch, { modalType: "success", showYn: true });
        fetchContractList();
      } else {
        // 실패 popup
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log(error.response);
      // 실패 popup httpStatus code 200 아닐 때 TODO (api 통일해야 한다.)
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };

  const fetchContractList = async () => {
    try {
      const contractListRes = await dybApi.get(
        `/api/adm/contractListByProjectId?page=${page}&projectId=${projectId}`
      );
      if (contractListRes.data.result === "SC001") {
        setContractList(contractListRes.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchContractList();
  }, [page, projectId]);

  return (
    <DashboardLayout>
      <AdmProjectNavbar />
      <MPBox mb={2} />
      <AdmContractHeader contractInfo={contractList} projectId={projectId}>
        <MPBox mt={1} mb={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group", backgroundColor: "#D3D3D3" }}>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 170 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      지원자 계정 (mail 주소)
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      지원자 이름
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 160 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      프로젝트 시작 가능일
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 130 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      (최소)&nbsp;희망 단가 /월단위
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 130 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      (최대)&nbsp;희망 단가 /월단위
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 130 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      계약 단계
                    </MPTypography>
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 130 }}>
                    <MPTypography
                      variant="caption"
                      fontWeight="medium"
                      color="black"
                      sx={({ typography: { size, fontWeightMedium } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightMedium,
                      })}
                    >
                      계약 단계 수정
                    </MPTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contractList?.map((contract) => (
                  <TableRow key={contract.userId}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 170 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {contract.email}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 150 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {contract.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 160 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {convertToDate(contract.prjStPosDate)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 130 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {contract.devHopeStartPrice + " 만원"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 130 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {contract.devHopeEndPrice + " 만원"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 130 }}
                      sx={({ typography: { size, fontWeightRegular } }) => ({
                        fontSize: size.sm,
                        fontWeight: fontWeightRegular,
                      })}
                    >
                      {contract.contractStep == "APP" ? (
                        <Chip
                          key={contract.contractStep}
                          label="지원 완료"
                          size="small"
                          color="warning"
                          variant="contained"
                        />
                      ) : contract.contractStep == "DOC" ? (
                        <Chip
                          key={contract.contractStep}
                          label="서류 검토"
                          size="small"
                          color="info"
                          variant="contained"
                        />
                      ) : contract.contractStep == "INT" ? (
                        <Chip
                          key={contract.contractStep}
                          label="인터뷰 요청"
                          size="small"
                          color="primary"
                          variant="contained"
                        />
                      ) : contract.contractStep == "SUC" ? (
                        <Chip
                          key={contract.contractStep}
                          label="계약 체결"
                          size="small"
                          color="success"
                          variant="contained"
                        />
                      ) : (
                        <Chip
                          key={contract.contractStep}
                          label="모집 종료"
                          size="small"
                          color="secondary"
                          variant="contained"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 130 }}>
                      <FormControl sx={{ mb: 1, width: "120px" }}>
                        <Select
                          value={contract.contractStep}
                          onChange={() => changeContractStep(contract.contractId, event)}
                        >
                          <MenuItem value={"APP"}>지원 완료</MenuItem>
                          <MenuItem value={"DOC"}>서류 검토</MenuItem>
                          <MenuItem value={"INT"}>인터뷰 요청</MenuItem>
                          <MenuItem value={"SUC"}>계약 체결</MenuItem>
                          <MenuItem value={"END"}>모집 종료</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MPBox>
      </AdmContractHeader>
      <AdmFooter />
    </DashboardLayout>
  );
}

export default AdmContractList;
