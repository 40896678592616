// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";

// Material Dashboard 2 React example components
import PageLayout from "services/LayoutContainers/PageLayout";
import HomeNavbar from "services/Navbars/HomeNavbar";

function BasicLayout({ children }) {
  return (
    <PageLayout>
      <HomeNavbar />
      <MPBox bgColor="#FAFAFD" width="100%" height="100vh" mx="auto">
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MPBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
