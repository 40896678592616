import { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { setOpenDialogPopup, useMaterialUIController } from "../../context";

const PrivateRoute = ({ children }) => {
  // 프론트에서 막아주고, api로 두 번 막기.
  // api로 안 막을 경우 막말로 localStorate, sessionStorage 임의로 변경하면 접근 가능해진다.
  // TODO:: isLogin으로 체크해야한다.

  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("DYBSoft") || sessionStorage.getItem("DYBSoft") ? true : false
  );
  // const [attemptedRoute, setAttemptedRoute] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { openDialogpopup } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLogin) {
      // 로그인 후 이용가능합니다로 변경하기
      history.back();
      setOpenDialogPopup(dispatch, { modalType: "login", showYn: true });
    }
  }, [isLogin, dispatch]);

  return children;
};

export default PrivateRoute;
