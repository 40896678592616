// ====================== React Library ===========================
import { useEffect, useState, useRef, useCallback } from "react";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "context/";

import * as XLSX from "xlsx";

// ====================== MP&MUI Components  ======================
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MPBox from "components/MPBox";
import MPInput from "components/MPInput";
import MPButton from "components/MPButton/index";
import MPTypography from "components/MPTypography";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
// ====================== Service Components ======================
import DashboardLayout from "services/LayoutContainers/DashboardLayout";
import AdmFooter from "services/Footer/AdmFooter/index";

// ====================== Images ==================================
import breakpoints from "assets/theme/base/breakpoints";

function Resume() {
  let page = 1;
  let size = 100;
  const [controller, dispatch] = useMaterialUIController();
  const [searchValue, setSearchValue] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [order, setOrder] = useState("");
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    fetchEmployeeList();
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const createEmployeeBlock = async (employeeInfo) => {
    console.log(employeeInfo);
    try {
      const response = await dybApi.post("/api/adm/employeeCreateBlock", employeeInfo);
      if (response.data.result === "SC001") {
        // 성공 popup
        setOpenDialogPopup(dispatch, { modalType: "success", showYn: true });
        // 성공했을 때 결과 불러오기
        fetchEmployeeList();
      } else {
        // 실패 popup
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log(error.response);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const fetchEmployeeList = async () => {
    try {
      const response = await dybApi.get(
        `/api/adm/employeeList?page=${page || 1}&size=${size || 100}&searchName=${searchValue}`
      );
      if (response.data.result === "SC001") {
        setRowData(response.data.data);
        // setPagingInfo(response.data.pagingInfo);
      }
    } catch (error) {
      console.error("Error fetchEmployeeList : ", error);
    }
  };

  const fileInputRef = useRef(null);

  const handleExcelFile = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  //엑셀 양식 다운로드
  const excelFormDownload = async () => {
    try {
      const response = await dybApi.get(
        "/api/adm/downloadfile/dybsoft-employee-bulk-form.xlsx?fileType=DYBSOFT",
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dybsoft_작업자_대량등록양식.xlsx"); // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error.response);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };

  //엑셀 다운로드
  const excelDownload = () => {
    //rowData를 조작해서 새로만든다음 json_to_sheet에 넣어야 할 듯
    const workSheet = XLSX.utils.json_to_sheet(rowData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(
      workSheet,
      [
        [
          "직원아이디",
          "이름",
          "나이",
          "이메일 주소",
          "핸드폰 번호",
          "주민등록번호",
          "소속회사",
          "포지션",
          "등급",
          "상세 설명",
          "이상 설명",
          "성별",
          "작업자 유형",
          "경력(개월 수)",
          "직책",
          "주소",
        ],
      ],
      { origin: 0 }
    );

    XLSX.utils.book_append_sheet(workBook, workSheet, "dybsoft 인력");
    const nowDate = new Date();
    const year = nowDate.getFullYear();
    let month = nowDate.getMonth() + 1;
    month.toString().length == 1 ? (month = "0" + month.toString()) : month;
    let date = nowDate.getDate();
    date.toString().length == 1 ? (date = "0" + date.toString()) : date;
    let today = "";
    today = year + "-" + month + "-" + date;
    XLSX.writeFile(workBook, `${"DYB Soft 인력 현황"}_${today}.xlsx`);
  };

  const handleExcelFileChange = (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    readExcel(file);
  };

  const readExcel = async (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      if (!e.target) return;
      const bufferArray = e.target.result;
      const fileInformation = XLSX.read(bufferArray, {
        type: "buffer",
        cellText: false,
        cellDates: true,
      });
      const sheetName = fileInformation.SheetNames[0];
      const rawData = fileInformation.Sheets[sheetName];

      // // Define the range you want to read, starting from the second row (A2 to all columns)
      const range = {
        s: { r: 1, c: 0 }, // Start from the second row (row index 1) and the first column (column index 0)
        e: { r: 1000, c: 500 }, // 1000*500 고정값을 세팅하는 방법 밖에 없음 10000*10000 하면 메모리 초과로 뻗는다..
      };
      const data = XLSX.utils.sheet_to_json(rawData, { range });

      const transformedData = data.map((item) => {
        const address = {
          address: item.address,
          addressDetails: item.addressDetails,
          zipCode: item.zipCode,
        };
        // 객체에서 기존 address 지워주기
        delete item["address"];
        delete item["addressDetails"];
        delete item["zipCode"];

        const certificateInfo = [];
        // 필드값 명확히 정의된 후에 만들기
        const companyHistoryInfo = [];
        const educationInfo = [];

        let i = 1;
        while (
          item[`certificate_grade${i}`] &&
          item[`certificate_name${i}`] &&
          item[`acquisition_date${i}`]
        ) {
          certificateInfo.push({
            acquisitionDate: item[`acquisition_date${i}`],
            certificateName: item[`certificate_name${i}`],
            grade: item[`certificate_grade${i}`],
          });

          delete item[`acquisition_date${i}`];
          delete item[`certificate_name${i}`];
          delete item[`certificate_grade${i}`];
          i++;
        }

        return {
          ...item,
          certificateInfo,
          address,
          // companyHistoryInfo,
          // educationInfo,
        };
      });

      console.log(transformedData, "필터된 데이터");
      console.log(data, "json으로 변환");

      setRowData(transformedData);
      createEmployeeBlock(transformedData);
      // && setUploadedFileData(mappingDataToTable(data));
    };
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const columns = [
    { field: "employeeId", headerName: "ID", width: 70 },
    {
      field: "employeeType",
      headerName: "직군",
      width: 100,
      valueGetter: (params) =>
        `${
          params.row.employeeType == "dev"
            ? "개발자"
            : params.row.employeeType == "pub"
            ? "퍼블리셔"
            : params.row.employeeType == "des"
            ? "디자이너"
            : "기획자"
        }`,
    },
    { field: "name", headerName: "이름", width: 100 },
    { field: "age", headerName: "나이", width: 90 },
    { field: "registrationNum", headerName: "주민등록번호(앞자리)", width: 130 },
    {
      field: "gender",
      headerName: "성별",
      width: 90,
      valueGetter: (params) => `${params.row.gender == "M" ? "남자" : "여자"}`,
    },
    {
      field: "grade",
      headerName: "등급",
      width: 90,
      valueGetter: (params) =>
        `${
          params.row.grade == "begginner"
            ? "초급"
            : params.row.grade == "intermediate"
            ? "중급"
            : params.row.grade == "advanced"
            ? "고급"
            : "특급"
        }`,
    },
    { field: "employeeMonth", headerName: "경력(개월수)", width: 130 },
    { field: "employeeRank", headerName: "직급", width: 90 },
    { field: "phoneNumber", headerName: "연락처", width: 180 },
    { field: "email", headerName: "이메일 주소", width: 200 },
    {
      field: "address_details",
      headerName: "집 주소",
      width: 250,
      valueGetter: (params) =>
        `${params.row.address?.address ? params.row.address?.address : params.row.address} ${
          params.row.addressDetails ? params.row.addressDetails : params.row.address?.addressDetails
        }`,
    },
    { field: "companyName", headerName: "소속 회사", width: 130 },
    {
      field: "edit",
      headerName: "Edit",
      width: 130,
      renderCell: (params) => (
        <MPButton size="small" variant="outlined" color="secondary" startIcon={<EditIcon />}>
          수정하기
        </MPButton>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item mb={{ xs: 1, lg: 0 }}>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<SaveIcon />}
          >
            저장하기
          </MPButton>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<VisibilityIcon />}
            onClick={fetchEmployeeList}
          >
            조회하기
          </MPButton>
        </Grid>
        {mobileView && (
          <MPBox mb={1}>
            <MPInput
              m="0 auto"
              sx={{ width: "300px" }}
              type="text"
              label="이름으로 검색"
              value={searchValue}
              onChange={handleInputChange}
            />
          </MPBox>
        )}
        {!mobileView && (
          <Grid item>
            <input
              type="file"
              multiple={true}
              ref={fileInputRef}
              onChange={handleExcelFileChange}
              style={{ display: "none" }}
            />
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<UploadIcon />}
              onClick={handleExcelFile}
            >
              엑셀 업로드
            </MPButton>
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<DownloadIcon />}
              onClick={excelDownload}
            >
              엑셀 다운로드
            </MPButton>
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<SystemUpdateAltIcon />}
              onClick={excelFormDownload}
            >
              엑셀 양식 다운로드
            </MPButton>
          </Grid>
        )}
      </Grid>
      <MPBox mt={1} pb={1}>
        <Grid container spacing={3}>
          {!mobileView && (
            <Grid item xs={12}>
              <Card>
                <Grid container justifycontent="space-around" p={3}>
                  <Grid item display="flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* 모바일에서는 필터식으로 마크업하기 */}

                      <MPBox>
                        <MPTypography
                          component="div"
                          variant="caption"
                          mb={0.5}
                          color="black"
                          fontWeight="regular"
                        >
                          조회 시작일
                        </MPTypography>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          mask={"____-__-__"}
                          label="From"
                          background="white"
                          sx={{
                            width: "200px",
                            minWidth: "200px",
                            marginRight: "10px",
                          }}
                        />
                      </MPBox>
                      <MPBox>
                        <MPTypography
                          component="div"
                          variant="caption"
                          mb={0.5}
                          color="black"
                          fontWeight="regular"
                        >
                          조회 종료일
                        </MPTypography>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          mask={"____-__-__"}
                          label="To"
                          sx={{ width: "200px", minWidth: "200px", marginRight: "30px" }}
                        />
                      </MPBox>

                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        정렬
                      </MPTypography>
                    </MPBox>
                    <FormControl sx={{ width: "120px" }}>
                      <InputLabel>Sort</InputLabel>
                      <Select value={order} label="Sort" onChange={handleChangeOrder}>
                        <MenuItem value={"NEWCREATE"}>최신 등록순</MenuItem>
                        <MenuItem value={"STARTDATE"}>시작 예정일</MenuItem>
                        <MenuItem value={"HIGHPRICE"}>견적 높은순</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item ml="auto">
                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        검색어 입력
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPInput
                        sx={{ width: { xs: "270px", lg: "400px" } }}
                        type="text"
                        label="이름으로 검색"
                        value={searchValue}
                        onChange={handleInputChange}
                      />
                    </MPBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          <Grid mt={2} item xs={12}>
            <Card>
              <MPBox
                mx={2}
                mt={-3}
                py={3}
                px={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MPTypography variant="h5" color="white">
                  DYB Soft 인력 현황 관리
                </MPTypography>
              </MPBox>
              <MPBox p={2}>
                <DataGrid
                  rows={rowData}
                  columns={columns}
                  getRowId={(row) => row.employeeId}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15]}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  checkboxSelection
                  sx={({ typography: { size, fontWeightMedium, fontWeightRegular } }) => ({
                    "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                      fontSize: size.sm,
                      fontWeight: fontWeightMedium,
                    },
                    color: "text",
                    fontSize: size.sm,
                    fontWeight: fontWeightRegular,
                  })}
                />
              </MPBox>
            </Card>
          </Grid>
        </Grid>
      </MPBox>
      <AdmFooter />
    </DashboardLayout>
  );
}

export default Resume;
