// ====================== React Library ===========================
import { useEffect, useState, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPButton from "components/MPButton/index";
import MPTypography from "components/MPTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MPInput from "components/MPInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
// ====================== Service Components ======================
import AdmFooter from "services/Footer/AdmFooter/index";
import DataTable from "services/Tables/DataTable";
import ProjectLayout from "layouts/project/svcProject/components/ProjectLayout/index";
import AdminProjectData from "layouts/project/admProject/data/AdminProjectData";
// ====================== Images ==================================
import AddCircleIcon from "@mui/icons-material/AddCircle";
import breakpoints from "assets/theme/base/breakpoints";

function AdminProject() {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("");
  const [fetch, setFetch] = useState("");
  const [sortCode, setSortCode] = useState("");

  const fetchProjectList = () => {
    setFetch(searchValue);
    setPage(1);
  };
  const handleChange = (event, page) => {
    setPage(page);
    // 최상단으로 올리기
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { columns, rows, pagingInfo } = AdminProjectData(page, searchValue, fetch, sortCode);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangeOrder = (event) => {
    setSortCode(event.target.value);
  };

  const fileInputRef = useRef(null);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  return (
    <ProjectLayout>
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item mb={{ xs: 1, lg: 0 }}>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<SaveIcon />}
          >
            저장하기
          </MPButton>
          <MPButton
            size="small"
            variant="outlined"
            color="primary"
            sx={({ typography: { size, fontWeightMedium } }) => ({
              fontSize: size.sm,
              fontWeight: fontWeightMedium,
              mr: 1,
            })}
            startIcon={<VisibilityIcon />}
            onClick={fetchProjectList}
          >
            조회하기
          </MPButton>
        </Grid>
        {mobileView && (
          <MPBox mb={3}>
            <MPInput
              m="0 auto"
              sx={{ width: "300px" }}
              type="text"
              label="프로젝트 명으로 검색"
              value={searchValue}
              onChange={handleInputChange}
            />
          </MPBox>
        )}
        {!mobileView && (
          <Grid item>
            <input
              type="file"
              multiple={true}
              ref={fileInputRef}
              // onChange={handleExcelFileChange}
              style={{ display: "none" }}
            />
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<UploadIcon />}
              // onClick={handleExcelFile}
            >
              엑셀 업로드
            </MPButton>
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<DownloadIcon />}
              // onClick={excelDownload}
            >
              엑셀 다운로드
            </MPButton>
            <MPButton
              size="small"
              variant="outlined"
              color="primary"
              sx={({ typography: { size, fontWeightMedium } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightMedium,
                mr: 1,
              })}
              startIcon={<SystemUpdateAltIcon />}
              // onClick={excelFormDownload}
            >
              엑셀 양식 다운로드
            </MPButton>
          </Grid>
        )}
      </Grid>
      <MPBox mt={1} pb={1}>
        <Grid container spacing={6}>
          {!mobileView && (
            <Grid item xs={12}>
              <Card>
                <Grid container justifycontent="space-around" p={3}>
                  <Grid item display="flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* 모바일에서는 필터식으로 마크업하기 */}

                      <MPBox>
                        <MPTypography
                          component="div"
                          variant="caption"
                          mb={0.5}
                          color="black"
                          fontWeight="regular"
                        >
                          조회 시작일
                        </MPTypography>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          mask={"____-__-__"}
                          label="From"
                          background="white"
                          sx={{
                            width: "200px",
                            minWidth: "200px",
                            marginRight: "10px",
                          }}
                        />
                      </MPBox>
                      <MPBox>
                        <MPTypography
                          component="div"
                          variant="caption"
                          mb={0.5}
                          color="black"
                          fontWeight="regular"
                        >
                          조회 종료일
                        </MPTypography>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          mask={"____-__-__"}
                          label="To"
                          sx={{ width: "200px", minWidth: "200px", marginRight: "30px" }}
                        />
                      </MPBox>

                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        정렬
                      </MPTypography>
                    </MPBox>
                    <FormControl sx={{ width: "120px" }}>
                      <InputLabel>Sort</InputLabel>
                      <Select value={sortCode} label="Sort" onChange={handleChangeOrder}>
                        <MenuItem value={"NEWCREATE"}>최신 등록순</MenuItem>
                        <MenuItem value={"STARTDATE"}>시작 예정일</MenuItem>
                        <MenuItem value={"HIGHPRICE"}>견적 높은순</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item ml="auto">
                    <MPBox>
                      <MPTypography
                        component="div"
                        variant="caption"
                        mb={0.5}
                        color="black"
                        fontWeight="regular"
                      >
                        검색어 입력
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPInput
                        sx={{ width: { xs: "270px", lg: "400px" } }}
                        type="text"
                        label="프로젝트 명으로 검색"
                        value={searchValue}
                        onChange={handleInputChange}
                      />
                    </MPBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MPBox
                mx={2}
                mt={-3}
                py={2}
                px={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <MPTypography component="div" variant="h5" color="white">
                      DYB Soft 프로젝트 관리
                    </MPTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      fullWidth
                      variant="contained"
                      color="light"
                      startIcon={<AddCircleIcon />}
                      onClick={() => navigate(`/adm/project-detail`)}
                    >
                      신규 프로젝트 등록
                    </MPButton>
                  </Grid>
                </Grid>
              </MPBox>
              <MPBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MPBox>
              <MPBox display="flex">
                <MPBox
                  sacing={2}
                  sx={{ margin: "0 auto", marginTop: "15px", marginBottom: "20px" }}
                >
                  <Pagination
                    count={pagingInfo.totPage}
                    defaultPage={1}
                    siblingCount={0}
                    boundaryCount={1}
                    renderItem={(dataList) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...dataList}
                      />
                    )}
                    onChange={handleChange}
                  />
                </MPBox>
              </MPBox>
            </Card>
          </Grid>
        </Grid>
      </MPBox>
      <AdmFooter />
    </ProjectLayout>
  );
}

export default AdminProject;
