import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MPInput
import MPInputRoot from "components/MPInput/MPInputRoot";

const MPInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <MPInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of MPInput
MPInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MPInput
MPInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MPInput;
