/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPAvatar from "components/MPAvatar";
import MPButton from "components/MPButton";
import { TextField } from "../../../../node_modules/@mui/material/index";

function ProfilesList({ title, profiles, shadow }) {
  const renderProfiles = profiles.map(({ image, name, description, action }) => (
    <MPBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MPBox mr={2}>
        <MPAvatar src={image} alt="something here" shadow="md" />
      </MPBox>
      <MPBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MPTypography variant="button" fontWeight="medium">
          {name}
        </MPTypography>
        <MPTypography variant="caption" color="text">
          {description}
        </MPTypography>
      </MPBox>
      <MPBox ml="auto">
        {action.type === "internal" ? (
          <MPButton
            variant="text"
            color="info"
            onClick={() => {
              alert("서비스 준비중입니다.");
            }}
          >
            {action.label}
          </MPButton>
        ) : (
          <MPButton
            component="a"
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="text"
            color={action.color}
          >
            {action.label}
          </MPButton>
        )}
      </MPBox>
    </MPBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MPBox pt={2} px={2}>
        <MPTypography variant="h6" color="black" fontWeight="medium" textTransform="capitalize">
          {title}
        </MPTypography>
      </MPBox>
      <MPBox p={2}>
        <MPBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MPBox>
      </MPBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
