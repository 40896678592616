/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { filterProject, searchKeyword } from "../../../modules/projectFilter";
import PropTypes from "prop-types";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { useSelector, useDispatch } from "react-redux";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPInput from "components/MPInput";
import MPButton from "components/MPButton/index";
import MPTypography from "components/MPTypography/index";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";

// ====================== Service Components ======================
import Breadcrumbs from "services/Breadcrumbs";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "services/Navbars/ProjectNavbar/styles";

// ====================== Images ==================================

//필터
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "large" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ProjectNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [workType, setWorkType] = useState("");
  const [prjType, setPrjType] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const [openMenu, setOpenMenu] = useState(false);
  const dispatchRedux = useDispatch();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const projectInfo = {
    workType: workType,
    prjType: prjType,
    category: category,
  };
  const handleCloseWithFilter = () => {
    setOpen(false);
    dispatchRedux(filterProject(projectInfo));
  };

  const handleDelete = (type) => {
    if (type === "workType") {
      setWorkType("");
      dispatchRedux(filterProject({ workType: "", prjType: prjType, category: category }));
    } else if (type === "prjType") {
      setPrjType("");
      dispatchRedux(filterProject({ workType: workType, prjType: "", category: category }));
    } else {
      setCategory("");
      dispatchRedux(filterProject({ workType: workType, prjType: prjType, category: "" }));
    }
    // setChipData((chipData) => chipData.filter((chip) => chip.key !== key));
  };
  const [chipData, setChipData] = useState([
    { key: 0, label: "상주근무" },
    { key: 1, label: "재택근무" },
    { key: 2, label: "개발자" },
    { key: 3, label: "퍼블리셔" },
    { key: 4, label: "디자이너" },
    { key: 5, label: "기획자" },
    { key: 6, label: "디자이너" },
    { key: 7, label: "개발 프로젝트" },
    { key: 8, label: "운영 프로젝트" },
  ]);

  useEffect(() => {
    // 홈에서 넘어올 때 && 다른 페이지로 갔다 돌아올 때
    setPrjType(filterInfo.prjType);
    setWorkType(filterInfo.workType);
    setCategory(filterInfo.category);
    setSearchValue(filterInfo.keyword);

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("scroll", handleTransparentNavbar);

      // 나갈 때 초기화 해줘야 함(검색영역은 초기화 안하는 게 나을 듯)
      /*
      setWorkType("");
      setPrjType("");
      setCategory("");
      dispatchRedux(searchKeyword(""));
      dispatchRedux(filterProject({ workType: "", prjType: "", category: "" }));
      */
    };
  }, [dispatch, fixedNavbar, dispatchRedux]);

  const handleWorkType = (workType) => {
    setWorkType(workType);
  };

  const handleCategory = (category) => {
    setCategory(category);
  };
  const handlePrjType = (prjType) => {
    setPrjType(prjType);
  };

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    // timeout 걸기
    dispatchRedux(searchKeyword(event.target.value));
  };

  const keyword = useSelector((state) => state.projectFilter.keyword);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const filterInfo = useSelector((state) => state.projectFilter);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MPBox color="inherit" mb={{ xs: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={filterInfo.category} route={route} light={light} />
        </MPBox>
        {isMini ? null : (
          <MPBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MPBox pr={1}>
              <MPInput
                sx={{ width: { lg: "400px" } }}
                type="text"
                label="프로젝트 명 검색"
                value={searchValue}
                onChange={handleInputChange}
              />
            </MPBox>
            <MPBox
              color={light ? "white" : "inherit"}
              sx={{
                mr: { lg: "150px" },
                border: "0.5px solid",
                borderColor: "#D3D3D3",
                borderRadius: "10%",
              }}
            >
              <IconButton size="small" disableRipple color="inherit" onClick={handleClickOpen}>
                <MPTypography fontSize="small">필터</MPTypography>
                <Icon sx={iconsStyle} fontSize="medium">
                  <FilterAltIcon />
                </Icon>
              </IconButton>
            </MPBox>
          </MPBox>
        )}
        <MPBox
          sx={{
            "& .MuiChip-root ": { marginRight: "5px" },
            display: "inline-block",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: { xs: "325px", lg: "535px" },
            margin: "0 auto",
            marginTop: "5px",
            float: "right",
          }}
        >
          {workType === "RE" ? (
            <Chip label="재택근무" variant="outlined" onDelete={() => handleDelete("workType")} />
          ) : workType === "PL" ? (
            <Chip label="상주근무" variant="outlined" onDelete={() => handleDelete("workType")} />
          ) : (
            ""
          )}
          {category === "dev" ? (
            <Chip label="개발자" variant="outlined" onDelete={() => handleDelete("category")} />
          ) : category === "pub" ? (
            <Chip label="퍼블리셔" variant="outlined" onDelete={() => handleDelete("category")} />
          ) : category === "des" ? (
            <Chip label="디자이너" variant="outlined" onDelete={() => handleDelete("category")} />
          ) : category === "pla" ? (
            <Chip label="기획자" variant="outlined" onDelete={() => handleDelete("category")} />
          ) : (
            ""
          )}
          {prjType === "SI" ? (
            <Chip
              label="개발 프로젝트"
              variant="outlined"
              onDelete={() => handleDelete("prjType")}
            />
          ) : prjType === "SM" ? (
            <Chip
              label="운영 프로젝트"
              variant="outlined"
              onDelete={() => handleDelete("prjType")}
            />
          ) : (
            ""
          )}
        </MPBox>
      </Toolbar>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          프로젝트 검색 필터
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <MPBox mb={3}>
            <MPTypography color="dark" fontWeight="medium" fontSize="small" textAlign="center">
              필터 선택
            </MPTypography>
            <Stack direction="row" spacing={1}>
              {workType === "RE" ? (
                <Chip label="재택근무" />
              ) : workType === "PL" ? (
                <Chip label="상주근무" />
              ) : (
                ""
              )}
              {category === "dev" ? (
                <Chip label="개발자" />
              ) : category === "pub" ? (
                <Chip label="퍼블리셔" />
              ) : category === "des" ? (
                <Chip label="디자이너" />
              ) : category === "pla" ? (
                <Chip label="기획자" />
              ) : (
                ""
              )}
              {prjType === "SI" ? (
                <Chip label="개발 프로젝트" />
              ) : prjType === "SM" ? (
                <Chip label="운영 프로젝트" />
              ) : (
                ""
              )}
            </Stack>
          </MPBox>
          <MPBox>
            <MPTypography color="dark" fontWeight="medium" fontSize="small">
              상주 / 재택 (근무형태)
            </MPTypography>
            <Stack direction="row" spacing={1}>
              <Chip label="재택근무" variant="outlined" onClick={() => handleWorkType("RE")} />
              <Chip label="상주근무" variant="outlined" onClick={() => handleWorkType("PL")} />
            </Stack>
          </MPBox>
          <MPBox mt={2}>
            <MPTypography color="dark" fontWeight="medium" fontSize="small">
              직군 (개발, 기획, 디자인, 퍼블)
            </MPTypography>
            <Stack direction="row" spacing={1}>
              <Chip label="개발자" variant="outlined" onClick={() => handleCategory("dev")} />
              <Chip label="퍼블리셔" variant="outlined" onClick={() => handleCategory("pub")} />
              <Chip label="기획자" variant="outlined" onClick={() => handleCategory("pla")} />
              <Chip label="디자이너" variant="outlined" onClick={() => handleCategory("des")} />
            </Stack>
          </MPBox>
          <MPBox mt={2}>
            <MPTypography color="dark" fontWeight="medium" fontSize="small">
              SI(개발) / SM(운영) 프로젝트
            </MPTypography>
            <Stack direction="row" spacing={1}>
              <Chip label="개발 프로젝트" variant="outlined" onClick={() => handlePrjType("SI")} />
              <Chip label="운영 프로젝트" variant="outlined" onClick={() => handlePrjType("SM")} />
            </Stack>
          </MPBox>
        </DialogContent>
        <DialogActions>
          <MPButton
            sx={{ fontSize: "small" }}
            variant="contained"
            color="info"
            size="small"
            startIcon={<DoneOutlineIcon />}
            onClick={handleCloseWithFilter}
          >
            필터 적용
          </MPButton>
        </DialogActions>
      </BootstrapDialog>
    </AppBar>
  );
}

// Setting default values for the props of ProjectNavbar
ProjectNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  category: "",
};

// Typechecking props for the ProjectNavbar
ProjectNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  category: PropTypes.string,
};

export default ProjectNavbar;
