/*
  SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
  SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import rootReducer, { rootSaga } from "modules/index";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

// ====================== MP&MUI Components  ======================

// ====================== Service Components ======================
import App from "./App";
import { ManPowerUIControllerProvider } from "./context";

// ====================== Images ==================================

// redux logger 설정 적용
const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(logger, sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ManPowerUIControllerProvider>
        <App />
      </ManPowerUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
