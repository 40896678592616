// ====================== React Library ===========================
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import dybApi from "lib/api/dybApi";
import { isLogIn } from "lib/api/utils";
import { setOpenDialogPopup, useMaterialUIController } from "context/";

// ====================== MP&MUI Components  ======================

import MPTypography from "components/MPTypography/index";
import MPBox from "components/MPBox/index";
import MPInput from "components/MPInput";
import MPButton from "components/MPButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DownloadIcon from "@mui/icons-material/Download";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PublishIcon from "@mui/icons-material/Publish";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CategoryIcon from "@mui/icons-material/Category";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import AccessibilityIcon from "@mui/icons-material/Accessibility";

// ====================== Service Components ======================
import PageLayout from "services/LayoutContainers/PageLayout";
import HomeNavbar from "services/Navbars/HomeNavbar";
import Calendar from "../../../../node_modules/react-calendar/dist/cjs/Calendar";
import "react-calendar/dist/Calendar.css";
import Drawer from "services/Drawer/index";
import DOMPurify from "dompurify";

// ====================== Images ==================================
import breakpoints from "assets/theme/base/breakpoints";
import leftArrow from "assets/images/icons/arrow_back.svg";

function ProjectDetail() {
  const [controller, dispatch] = useMaterialUIController();
  const [htmlCode, setHtmlCode] = useState("");
  const [dataDetail, setDataDetail] = useState();
  const [relatedPrj, setRelatedPrj] = useState([]);
  const [isApplied, setIsApplied] = useState("");

  const { projectId } = useParams();
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    // accept: "image/*", // Only accept image files
    maxFiles: 1, // Limit the number of files to 1
    noDrag: true,
  });
  const files = acceptedFiles.map((file, index) => (
    <MPBox key={index}>
      <MPTypography
        sx={{ whiteSpace: "no-wrap", overflow: "hidden", textOverflow: "ellipsis" }}
        fontSize="medium"
        fontWeight="bold"
        color="inherit"
      >
        {index + 1}. {file.name}
      </MPTypography>
    </MPBox>
  ));
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });
  const fileInputRef = useRef(null);
  const handleClickSelectFiles = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const goRelatedProject = (projectId) => {
    navigate(`/project-detail/${projectId}`);
    //최상단으로 올리기
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const isApply = async () => {
      try {
        const response = await dybApi.get(`/api/svc/v1/projectApplyYn?projectId=${projectId}`);
        setIsApplied(response.data.data);
      } catch (error) {
        console.error("Error fetching project detail:", error);
      }
    };
    const fetchData = async () => {
      try {
        const response = await dybApi.get(`/api/svc/v2/projectDetail?projectId=${projectId}`);
        setDataDetail(response.data.data);
        const sanitizedHtmlCode = DOMPurify.sanitize(response.data.data.description);
        setHtmlCode(sanitizedHtmlCode);

        const relatedProject = await dybApi.get(
          `/api/svc/v2/relatedProjectList?largeCategoryCode=${response.data.data.largeCateCode}`
        );
        setRelatedPrj(relatedProject.data.data);
      } catch (error) {
        console.error("Error fetching project detail:", error);
      }
    };
    if (isLogIn) {
      isApply();
    }

    fetchData();
    const handleResize = () => {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [projectId]);

  const applyForProject = async (applyProject) => {
    try {
      const response = await dybApi.post("/api/svc/v1/applyForProject", applyProject);
      if (response.data.result === "SC001") {
        setOpenDialogPopup(dispatch, {
          modalType: "sucCustom",
          showYn: true,
          title: "지원완료!",
          content: "프로젝트 지원완료 되었습니다",
          reload: true,
        });
      } else {
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log("Error apply project:", error);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };

  const downloadResume = async () => {
    try {
      // 일단 adm 열면 안된다.(경로 바꿔서 열기)
      const response = await dybApi.get(
        "/api/svc/v2/downloadfile/dybsoft_resume_form.docx?fileType=DYBSOFT",
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dybsoft_개인이력카드.docx"); // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error.response);
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };
  return (
    <PageLayout background="#FAFAFD">
      <HomeNavbar />
      <MPBox
        display={{ lg: "flex" }}
        justifyContent={{ lg: "center" }}
        // alignItems={{ lg: "center" }}
        px={{ xs: 2, lg: 20 }}
      >
        <Card
          sx={{
            maxWidth: "720px",
            minWidth: mobileView ? 0 : "500px",
            position: "relative",
            mx: 0,
            my: 2,
            py: 2,
            px: 2,
          }}
        >
          <MPTypography variant="h5" color="black">
            {dataDetail ? dataDetail.projectName : ""}
          </MPTypography>
          <MPBox sx={{ marginTop: "2px" }}>
            {dataDetail
              ? dataDetail.framework
                  .split(",")
                  .map((framework) => (
                    <Chip
                      key={framework}
                      sx={{ marginRight: "3px" }}
                      label={framework}
                      size="small"
                      color="success"
                      variant="outlined"
                    />
                  ))
              : ""}
            {dataDetail
              ? dataDetail.language
                  .split(",")
                  .map((language) => (
                    <Chip
                      key={language}
                      sx={{ marginRight: "3px" }}
                      label={language}
                      size="small"
                      color="success"
                      variant="outlined"
                    />
                  ))
              : ""}
          </MPBox>
          <Card sx={{ marginTop: "10px", bgcolor: "#f0f0f0" }}>
            <CardContent sx={{ padding: "0.5rem 1rem 0.5rem !important" }}>
              <MPBox sx={{ marginTop: "6px" }} minWidth="200px">
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <AttachMoneyIcon sx={{ mr: "2px" }} /> 예상 금액
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.estimatedPriceStart : ""} 만원 ~{" "}
                    {dataDetail ? dataDetail.estimatedPriceEnd : ""}만원 /{" "}
                    {dataDetail
                      ? dataDetail.estimatedPriceType == "P"
                        ? "프로젝트"
                        : "월 단위"
                      : ""}{" "}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <TimelapseIcon sx={{ marginRight: "2px" }} />
                    프로젝트 기간
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.period : ""}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <CalendarTodayIcon sx={{ marginRight: "2px" }} />
                    프로젝트 시작일
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail
                      ? dataDetail.projectStartDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")
                      : ""}{" "}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <CalendarMonthIcon sx={{ marginRight: "2px" }} />
                    프로젝트 종료일
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail
                      ? dataDetail.projectEndDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")
                      : ""}{" "}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <CategoryIcon sx={{ marginRight: "2px" }} />
                    개발 분야
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.projectRole : ""}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <HomeIcon sx={{ marginRight: "2px" }} />
                    근무 위치
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.address : ""}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <BusinessIcon sx={{ marginRight: "2px" }} />
                    고객사 or 수행사
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.client : ""}
                  </MPTypography>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="112px"
                    mr={1}
                  >
                    <AccessibilityIcon sx={{ marginRight: "2px" }} />
                    모집 인원
                  </MPTypography>
                  <MPTypography
                    color="dark"
                    fontSize="small"
                    fontWeight="regular"
                    component="div"
                    minWidth="100px"
                  >
                    {dataDetail ? dataDetail.recruitmentMember : "0"}명
                  </MPTypography>
                </MPBox>
              </MPBox>
            </CardContent>
          </Card>
          <MPBox mt={4} mb={2}>
            <MPTypography mb={0.5} fontSize="size.lg" fontWeight="bold" color="black">
              프로젝트 세부 사항
            </MPTypography>
            <MPTypography
              variant="body2"
              fontWeight="regular"
              dangerouslySetInnerHTML={{ __html: htmlCode ? htmlCode : "" }}
            ></MPTypography>
          </MPBox>
          <MPBox>
            <MPTypography mt={1} mb={0.5} fontSize="size.lg" fontWeight="bold" color="black">
              전형 단계
            </MPTypography>
            <MPTypography fontSize="size.sm" fontWeight="regular">
              <MPBox mb={0.5}>
                <Chip
                  key="서류 전형"
                  label="서류 전형"
                  size="small"
                  color="warning"
                  variant="contained"
                />{" "}
                - 프로젝트 지원( With. 이력서){" "}
                <MPTypography variant="caption" fontWeight="regular">
                  * 양식 꼭 맞춰주시길 바랍니다. (양식 다를 경우 서류전형 탈락)
                </MPTypography>
              </MPBox>
              <MPBox mb={0.5} fontWeight="regular">
                <Chip
                  key="서류 검토"
                  label="서류 검토"
                  size="small"
                  color="info"
                  variant="contained"
                />{" "}
                - DYB Soft 전화 인터뷰
              </MPBox>
              <MPBox mb={0.5} fontWeight="regular">
                <Chip
                  key="인터뷰 요청"
                  label="인터뷰 요청"
                  size="small"
                  color="primary"
                  variant="contained"
                />{" "}
                - 고객사 혹은 수행사 면접
              </MPBox>
              <MPBox mb={0.5} fontWeight="regular">
                <Chip
                  key="계약 체결"
                  label="계약 체결"
                  size="small"
                  color="success"
                  variant="contained"
                />{" "}
                - 근무( or 작업) 시작
              </MPBox>
            </MPTypography>
          </MPBox>
          <MPBox>
            <MPTypography mt={3} mb={0.5} fontSize="size.lg" fontWeight="bold" color="black">
              지원방법 (홈페이지 or 이메일 지원)
            </MPTypography>
            <MPTypography fontSize="size.sm" fontWeight="regular">
              1. DYB Soft 홈페이지 지원
              <br />
              2. prden@dybsoft.com 로 프로젝트 지원 및 이력서 전송(개인이력카드)
            </MPTypography>
            {/* TODO :: 여기 프로젝트 종류에 따라 분기처리해줘야한다.  */}
            <MPBox mt={3}>
              <Chip
                avatar={<Avatar alt="back" src={leftArrow} />}
                label="프로젝트 목록 확인"
                onClick={() => navigate(`/project`)}
              />
            </MPBox>
          </MPBox>
          {mobileView && <Drawer isLogIn={isLogIn} isApplied={isApplied} projectId={projectId} />}
        </Card>
        {!mobileView && (
          <Card
            sx={{
              minWidth: mobileView ? 0 : "350px",
              maxWidth: mobileView ? 0 : "200px",
              position: "relative",
              ml: 1,
              my: 2,
              py: 2,
              px: 2,
            }}
          >
            <MPBox position="sticky" top={"50px"} pt={4} pb={3} px={3} sx={{ background: "white" }}>
              <MPTypography variant="h6" color="inherit">
                희망 견적(단가) /월 단위
              </MPTypography>
              <MPBox
                component="form"
                role="form"
                onSubmit={handleSubmit((data) => {
                  if (!acceptedFiles[0]) {
                    setOpenDialogPopup(dispatch, {
                      modalType: "failCustom",
                      showYn: true,
                      content: "이력서를 첨부해주세요",
                    });
                    return;
                  }
                  const dY = data.prjStPosDate.getFullYear();
                  let dM = data.prjStPosDate.getMonth() + 1;
                  let dD = data.prjStPosDate.getDate();
                  dM.toString().length == 1 ? (dM = "0" + dM.toString()) : dM;
                  dD.toString().length == 1 ? (dD = "0" + dD.toString()) : dD;

                  const prjStPosDate = dY.toString() + dM + dD;
                  const dataDto = {
                    devHopeStartPrice: data.devHopeStartPrice,
                    devHopeEndPrice: data.devHopeEndPrice,
                    // devHopeTotalPrice: 1200, //api에서 제거하기
                    prjStPosDate: prjStPosDate,
                    projectId: projectId,
                  };
                  const formData = new FormData();
                  const applyProjectDto = JSON.stringify(dataDto);
                  const blob = new Blob([applyProjectDto], { type: "application/json" });
                  formData.append("resume", acceptedFiles[0]);
                  formData.append("applyProjectDto", blob);
                  console.log(dataDto, "데이타");
                  console.log(acceptedFiles[0], "파일");
                  applyForProject(formData);
                })}
              >
                <MPBox display="flex" alignItems="center" justifyContent="center">
                  <MPInput
                    type="number"
                    label="만원"
                    {...register("devHopeStartPrice", {
                      required: "희망 견적을 입력해주세요.",
                    })}
                  />
                  ~
                  <MPInput
                    type="number"
                    label="만원"
                    {...register("devHopeEndPrice", {
                      required: "희망 견적을 입력해주세요.",
                    })}
                  />
                </MPBox>
                <MPBox>
                  {(errors.devHopeStartPrice || errors.devHopeEndPrice) && (
                    <MPTypography variant="caption" color="error" fontWeight="bold">
                      {errors.devHopeStartPrice
                        ? errors.devHopeStartPrice.message
                        : "" || errors.devHopeEndPrice
                        ? errors.devHopeEndPrice.message
                        : ""}
                    </MPTypography>
                  )}
                </MPBox>
                <MPTypography mt={3} variant="h6" color="inherit">
                  프로젝트 시작 가능일
                </MPTypography>
                <MPBox mb={3}>
                  <Calendar
                    value={setValue("prjStPosDate", selectedDate)}
                    {...register("prjStPosDate", { required: "시작 가능일을 선택해주세요." })}
                    onChange={handleDateChange}
                  />
                  <MPBox>
                    {errors.prjStPosDate && (
                      <MPTypography variant="caption" color="error" fontWeight="bold">
                        {errors.prjStPosDate.message}
                      </MPTypography>
                    )}
                  </MPBox>
                </MPBox>
                <MPBox display="flex">
                  <MPTypography mb={1} variant="h6" color="inherit">
                    이력서 업로드{" "}
                  </MPTypography>
                  <MPTypography mt={1} variant="caption" color="inherit" fontWeight="regular">
                    * 파일 최대 1개 등록
                  </MPTypography>
                </MPBox>
                <MPBox>
                  <input {...getInputProps()} ref={fileInputRef} sx={{ display: "none" }} />
                  {isLogIn && isApplied == "N" ? (
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={handleClickSelectFiles}
                    >
                      파일 추가
                    </MPButton>
                  ) : (
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      disabled
                    >
                      파일 추가
                    </MPButton>
                  )}
                  <MPBox mt={1}>{files}</MPBox>
                </MPBox>
                <MPBox mt={2} mb={1}>
                  {!isLogIn ? (
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      type="submit"
                      variant="gradient"
                      fontWeight="bold"
                      color="secondary"
                      fullWidth
                      onClick={() => navigate("/authentication/sign-in")}
                      startIcon={<LockOpenIcon />}
                    >
                      로그인 후 지원가능
                    </MPButton>
                  ) : isApplied == "Y" ? (
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      type="submit"
                      variant="gradient"
                      fontWeight="bold"
                      color="info"
                      fullWidth
                      disabled
                      startIcon={<PublishIcon />}
                    >
                      이미 지원완료 하셨습니다.
                    </MPButton>
                  ) : (
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      type="submit"
                      variant="gradient"
                      fontWeight="bold"
                      color="info"
                      fullWidth
                      startIcon={<PublishIcon />}
                      disabled={isSubmitting}
                    >
                      지원완료 하기
                    </MPButton>
                  )}
                </MPBox>
              </MPBox>
              <MPTypography mt={3} variant="h6" color="inherit">
                자사 이력서 양식 다운로드
              </MPTypography>
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                fontWeight="bold"
                color="info"
                fullWidth
                startIcon={<DownloadIcon />}
                onClick={downloadResume}
              >
                이력서 다운로드
              </MPButton>
            </MPBox>
          </Card>
        )}
      </MPBox>
      <MPBox maxWidth="1080px" margin={"0 auto"} px={{ xs: 2 }} py={2} pb={{ xs: 8, lg: 2 }}>
        <MPTypography variant="h5" fontWeight="medium" color="black" mb={1}>
          연관 프로젝트 추천드려요
        </MPTypography>
        <Grid container spacing={1}>
          {relatedPrj
            ? relatedPrj.map((project, index) => (
                <Grid key={index} item xs={6} md={6} xl={3}>
                  <Card sx={{ maxWidth: 250, height: { xs: "260px", sm: "170px", lg: "170px" } }}>
                    <CardActionArea onClick={() => goRelatedProject(project.projectId)}>
                      <CardContent sx={{ paddingBottom: " 0.5rem " }}>
                        <MPTypography gutterBottom variant="h6" component="div">
                          {project.projectName}
                        </MPTypography>
                        <MPTypography gutterBottom variant="body3" component="div">
                          견적 : {project.estimatedPriceStart}만원 ~ {project.estimatedPriceEnd}만원
                          / {project.estimatedPriceType == "P" ? "프로젝트 단위" : "월 단위"}
                        </MPTypography>
                        <MPTypography variant="body2">
                          {project.framework.split(",").map((framework) => (
                            <Chip
                              key={framework}
                              sx={{ marginRight: "3px" }}
                              label={framework}
                              size="small"
                              color="primary"
                              variant="outlined"
                            />
                          ))}
                          {project.language.split(",").map((language) => (
                            <Chip
                              key={language}
                              sx={{ marginRight: "3px" }}
                              label={language}
                              size="small"
                              color="info"
                              variant="outlined"
                            />
                          ))}
                        </MPTypography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            : ""}
        </Grid>
      </MPBox>
    </PageLayout>
  );
}

export default ProjectDetail;
