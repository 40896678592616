// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setOpenDialogPopup, useMaterialUIController } from "context/";
import dybApi from "lib/api/dybApi";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPInput from "components/MPInput";
import MPButton from "components/MPButton";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// ====================== Service Components ======================
import BasicLayout from "layouts/authentication/components/BasicLayout";

// ====================== Images ==================================
import typography from "assets/theme/base/typography";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SignUp() {
  const [controller, dispatch] = useMaterialUIController();
  const { size } = typography;
  const [open, setOpen] = useState(false);

  const openTerms = () => {
    setOpen(true);
  };

  const closeTerms = () => {
    setOpen(false);
  };

  const handleBirthDate = (date) => {
    const year = date.$y.toString();
    let month = date.$M + 1;
    let day = date.$D;
    month.toString().length == 1 ? (month = "0" + month.toString()) : month;
    day.toString().length == 1 ? (day = "0" + day.toString()) : day;
    const birthDate = year + month + day;
    setValue("birthDate", birthDate);
  };

  const requestSignup = async (signUpInfo) => {
    try {
      const response = await dybApi.post("/api/svc/v2/signUp", signUpInfo);
      if (response.data.result === "SC001") {
        setOpenDialogPopup(dispatch, {
          modalType: "sucCustom",
          showYn: true,
          title: "회원가입 완료!",
          content: "회원가입이 완료되었습니다. \n로그인 페이지로 이동합니다.",
          reload: false,
          redirect: "/authentication/sign-in",
        });
      }
    } catch (error) {
      let contentMsg = "";
      if (error.response.data.result === "MB001") {
        contentMsg = "이메일이 중복되었습니다.";
      } else {
        contentMsg = "작업에 실패하셨습니다. \n잠시 후 다시 시도해주세요";
      }
      setOpenDialogPopup(dispatch, {
        modalType: "failCustom",
        showYn: true,
        content: contentMsg,
        reload: false,
      });
      console.log(error.response);
    }
  };
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  return (
    <BasicLayout>
      <Card>
        <MPBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-2}
          p={2}
          mb={2}
          textAlign="center"
        >
          <MPTypography variant="h4" fontWeight="medium" color="white">
            Email 회원가입
          </MPTypography>
          <MPTypography display="block" variant="button" color="white">
            Register for DYB Soft Business
          </MPTypography>
        </MPBox>
        <MPBox pt={2} pb={2} px={3}>
          <MPBox
            component="form"
            role="form"
            onSubmit={handleSubmit((data) => {
              console.log(data, "여기 있을 거 같은데");
              const replacePhoneNumber = data.phoneNumber
                .replace(/[^0-9]/g, "") // 숫자를 제외한 모든 문자 제거
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

              const signUpInfo = {
                email: data.email,
                name: data.name,
                phoneNumber: replacePhoneNumber,
                password: data.password,
                birthDate: data.birthDate,
              };
              requestSignup(signUpInfo);
            })}
          >
            <MPBox mb={2}>
              <MPInput
                type="email"
                label="아이디(이메일)"
                variant="standard"
                {...register("email", {
                  required: "아이디(이메일주소)입력해주세요.",
                  pattern: {
                    value:
                      /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    message: "이메일을 다시 확인해주세요.",
                  },
                })}
                fullWidth //
              />
              <MPBox>
                {errors.email && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.email.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox mb={2}>
              <MPInput
                type="text"
                label="이름"
                variant="standard"
                {...register("name", {
                  required: "이름을 입력해주세요.",
                })}
                fullWidth
              />
              <MPBox>
                {errors.name && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.name.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox mb={2}>
              <MPInput
                type="text"
                label="핸드폰 번호"
                variant="standard"
                {...register("phoneNumber", {
                  required: "핸드폰 번호를 입력해주세요.",
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
                    message: "핸드폰 번호(형식)를 다시 확인해주세요.",
                  },
                })}
                fullWidth
              />
              <MPBox>
                {errors.phoneNumber && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.phoneNumber.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MPBox textAlign="center">
                <DatePicker
                  // TODO::  DatePicker랑 어떻게 useForm 연결시킬지
                  {...register("birthDate", {
                    required: "생년월일을 입력해주세요.",
                  })}
                  onChange={(date) => handleBirthDate(date)}
                  format={"YYYY-MM-DD"}
                  mask={"____-__-__"}
                  label="생년월일"
                  background="white"
                  fullWidth
                  sx={{
                    minWidth: "200px",
                  }}
                />
                <MPBox>
                  {errors.birthDate && (
                    <MPTypography variant="caption" color="error" fontWeight="regular">
                      {errors.birthDate.message}
                    </MPTypography>
                  )}
                </MPBox>
              </MPBox>
            </LocalizationProvider>
            <MPBox mb={2}>
              <MPInput
                type="password"
                label="비밀번호"
                variant="standard"
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
                    message:
                      "비밀번호는 영문,숫자,특수문자 를 모두 조합하여 최소 8~15자 입력해 주세요.",
                  },
                })}
                autoComplete="on"
                fullWidth
              />
              <MPBox>
                {errors.password && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.password.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox mb={2}>
              <MPInput
                type="password"
                label="비밀번호 확인"
                variant="standard"
                {...register("passwordConfirm", {
                  required: "비밀번호를 입력해주세요.",
                  validate: {
                    matchesPreviousPassword: (value) => {
                      return watch().password === value || "비밀번호가 일치하지 않습니다.";
                    },
                  },
                })}
                autoComplete="on"
                fullWidth
              />
              <MPBox>
                {errors.passwordConfirm && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.passwordConfirm.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            {/* TODO:: 휴대폰 본인인증, 회원가입시 아이디에 금지어 로직 추가

            <MPBox mb={3}>
              <MPTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;휴대폰 인증&nbsp;
              </MPTypography>
              <MPBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <MPInput type="text" variant="standard" placeholder="휴대폰 번호 입력" fullWidth />
                <Grid item xs={6} sm={6} lg={6}>
                  <MPButton variant="gradient" component={Link} size="small" color="dark" fullWidth>
                    인증요청
                  </MPButton>
                </Grid>
              </MPBox>
              <MPBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <MPInput type="text" variant="standard" placeholder="인증번호 입력" fullWidth />
                <Grid item xs={6} sm={6} lg={6}>
                  <MPButton variant="gradient" component={Link} size="small" color="dark" fullWidth>
                    인증하기
                  </MPButton>
                </Grid>
              </MPBox>
            </MPBox> */}
            <MPBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                {...register("agree", {
                  required: "이용약관 및 개인정보처리 방침에 동의해주세요.",
                })}
              />
              <MPTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MPTypography>
              <MPButton
                variant="outlined"
                size="small"
                fontWeight="medium"
                color="info"
                onClick={openTerms}
              >
                Terms & Conditions
              </MPButton>
            </MPBox>
            <MPBox>
              {errors.agree && (
                <MPTypography variant="caption" color="error" fontWeight="regular">
                  {errors.agree.message}
                </MPTypography>
              )}
            </MPBox>
            <MPBox mt={2}>
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                fontWeight="bold"
                color="info"
                fullWidth
                startIcon={<HowToRegIcon />}
                disabled={isSubmitting}
              >
                회원가입 완료
              </MPButton>
            </MPBox>
            <MPBox mt={1} textAlign="center">
              <MPTypography variant="button" color="text">
                Already have an account?{" "}
                <MPTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  SIGN IN(로그인)
                </MPTypography>
              </MPTypography>
            </MPBox>
          </MPBox>
        </MPBox>
      </Card>
      <MPBox fontSize={size.sm} pt={2} display="flex" justifyContent="center">
        <MPBox>
          &copy; {new Date().getFullYear()}, made by
          <Link to={"/"} target="_blank">
            <MPTypography variant="button" fontWeight="medium">
              &nbsp;{"Providence Inc."}&nbsp;
            </MPTypography>
          </Link>
          All Rights Reserved.
        </MPBox>
      </MPBox>
      <BootstrapDialog onClose={closeTerms} open={open}>
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "medium" }}>
          이용 약관 및 개인정보 처리방침
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeTerms}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
        </DialogContent>
        <DialogActions>
          <MPButton
            sx={{ fontSize: "small" }}
            variant="outlined"
            color="info"
            size="small"
            startIcon={<DoneOutlineIcon />}
            onClick={closeTerms}
          >
            확인
          </MPButton>
        </DialogActions>
      </BootstrapDialog>
    </BasicLayout>
  );
}

export default SignUp;
