import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPAvatar from "components/MPAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import ManDefault from "assets/images/team-4.jpg";
import awsbackground from "assets/images/background/aws-background3.jpg";

function MypageHeader({ myInfo, children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MPBox position="relative" maxWidth={{ lg: "1080px" }} m={"0 auto"} p={1}>
      <MPBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="9.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `url(${awsbackground})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 1,
          py: 2,
          px: 1,
        }}
      >
        {/* <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MPAvatar src={ManDefault} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MPBox height="100%" lineHeight={1}>
              <MPTypography variant="h5" fontWeight="medium" color="black">
                {myInfo ? myInfo.email : ""}
              </MPTypography>
              <MPTypography variant="button" color="text" fontWeight="regular">
                {myInfo ? myInfo.name : ""}/{" "}
                {(myInfo ? myInfo.userType : "") == "Adm"
                  ? "관리자"
                  : (myInfo ? myInfo.userType : "") == "Man"
                  ? "매니저"
                  : "일반 사용자"}
              </MPTypography>
            </MPBox>
          </Grid>
        </Grid> */}
        {children}
      </Card>
    </MPBox>
  );
}

// Setting default props for the MypageHeader
MypageHeader.defaultProps = {
  myInfo: {},
  children: "",
};

// Typechecking props for the MypageHeader
MypageHeader.propTypes = {
  myInfo: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default MypageHeader;
