// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setOpenDialogPopup, useMaterialUIController } from "context/";
import dybApi from "lib/api/dybApi";
import PropTypes from "prop-types";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPInput from "components/MPInput";
import MPButton from "components/MPButton";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

// ====================== Service Components ======================
import BasicLayout from "layouts/authentication/components/BasicLayout";

// ====================== Images ==================================
import typography from "assets/theme/base/typography";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SnsSignUp({ snsInfo }) {
  const [controller, dispatch] = useMaterialUIController();
  const { size } = typography;
  const [open, setOpen] = useState(false);
  const openTerms = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log(snsInfo, "에쎈에스");
    setValue("email", snsInfo.email);
    setValue("name", snsInfo.name);
    setValue("phoneNumber", snsInfo.mobile);
    setValue("birthDate", snsInfo.birthyear + snsInfo.birthday);
  }, [snsInfo]);

  const closeTerms = () => {
    setOpen(false);
  };

  const requestSignup = async (signUpInfo) => {
    try {
      const response = await dybApi.post("/api/svc/v2/signUp", signUpInfo);
      if (response.data.result === "SC001") {
        setOpenDialogPopup(dispatch, {
          modalType: "sucCustom",
          showYn: true,
          title: "회원가입 완료!",
          content: "회원가입이 완료되었습니다. \n로그인 페이지로 이동합니다.",
          reload: false,
          redirect: "/authentication/sign-in",
        });
      }
    } catch (error) {
      let contentMsg = "";
      if (error.response.data.result === "MB001") {
        contentMsg = "이메일이 중복되었습니다.";
      } else {
        contentMsg = "작업에 실패하셨습니다. \n잠시 후 다시 시도해주세요";
      }
      setOpenDialogPopup(dispatch, {
        modalType: "failCustom",
        showYn: true,
        content: contentMsg,
        reload: false,
      });
      console.log(error.response);
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  return (
    <BasicLayout>
      <Card>
        <MPBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-2}
          p={2}
          mb={2}
          textAlign="center"
        >
          <MPTypography variant="h4" fontWeight="medium" color="white">
            SNS 간편 회원가입
          </MPTypography>
          <MPTypography display="block" variant="button" color="white">
            약관 동의 후 클릭 한 번으로 회원가입 하세요!
          </MPTypography>
        </MPBox>
        <MPBox pt={2} pb={2} px={3}>
          <MPBox
            component="form"
            role="form"
            onSubmit={handleSubmit((data) => {
              const replacePhoneNumber = data.phoneNumber
                .replace(/[^0-9]/g, "") // 숫자를 제외한 모든 문자 제거
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

              const signUpInfo = {
                email: data.email,
                name: data.name,
                phoneNumber: replacePhoneNumber,
                password: data.password,
              };
              console.log(JSON.stringify(data), "싸인업");
              requestSignup(signUpInfo);
            })}
          >
            <MPTypography variant="caption" fontWeight="bold" color="text">
              아이디(이메일)
            </MPTypography>
            <MPBox>
              <MPInput
                disabled
                type="email"
                {...register("email", {
                  required: "아이디(이메일주소)입력해주세요.",
                  pattern: {
                    value:
                      /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    message: "이메일을 다시 확인해주세요.",
                  },
                })}
                fullWidth //
              />
              <MPBox>
                {errors.email && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.email.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPTypography variant="caption" fontWeight="bold" color="text">
              이름
            </MPTypography>
            <MPBox>
              <MPInput
                disabled
                type="text"
                {...register("name", {
                  required: "이름을 입력해주세요.",
                })}
                fullWidth
              />
              <MPBox>
                {errors.name && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.name.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPTypography variant="caption" fontWeight="bold" color="text">
              핸드폰 번호
            </MPTypography>
            <MPBox>
              <MPInput
                disabled
                type="text"
                {...register("phoneNumber", {
                  required: "핸드폰 번호를 입력해주세요.",
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
                    message: "핸드폰 번호(형식)를 다시 확인해주세요.",
                  },
                })}
                fullWidth
              />
              <MPBox>
                {errors.phoneNumber && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.phoneNumber.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPTypography variant="caption" fontWeight="bold" color="text">
              생년월일(YYYYMMDD)
            </MPTypography>
            <MPBox mb={1}>
              <MPInput
                disabled
                type="text"
                {...register("birthDate", {
                  required: "생년월일을 입력해주세요.",
                })}
                fullWidth
              />
              <MPBox>
                {errors.birthDate && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.birthDate.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                {...register("agree", {
                  required: "이용약관 및 개인정보처리 방침에 동의해주세요.",
                })}
              />
              <MPTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MPTypography>
              <MPButton
                variant="outlined"
                size="small"
                fontWeight="medium"
                color="info"
                onClick={openTerms}
              >
                Terms & Conditions
              </MPButton>
            </MPBox>
            <MPBox>
              {errors.agree && (
                <MPTypography variant="caption" color="error" fontWeight="regular">
                  {errors.agree.message}
                </MPTypography>
              )}
            </MPBox>
            <MPBox mt={2}>
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                fontWeight="bold"
                color="info"
                fullWidth
                startIcon={<HowToRegIcon />}
                disabled={isSubmitting}
              >
                회원가입 완료
              </MPButton>
            </MPBox>
            <MPBox mt={1} textAlign="center">
              <MPTypography variant="button" color="text">
                Already have an account?{" "}
                <MPTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  SIGN IN(로그인)
                </MPTypography>
              </MPTypography>
            </MPBox>
          </MPBox>
        </MPBox>
      </Card>
      <MPBox fontSize={size.sm} pt={2} display="flex" justifyContent="center">
        <MPBox>
          &copy; {new Date().getFullYear()}, made by
          <Link to={"/"} target="_blank">
            <MPTypography variant="button" fontWeight="medium">
              &nbsp;{"Providence Inc."}&nbsp;
            </MPTypography>
          </Link>
          All Rights Reserved.
        </MPBox>
      </MPBox>
      <BootstrapDialog onClose={closeTerms} open={open}>
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "medium" }}>
          이용 약관 및 개인정보 처리방침
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeTerms}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
          <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
            이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침이용약관 및 개인정보처리방침이용약관 및
            개인정보처리방침이용약관 및 개인정보처리방침
          </MPTypography>
        </DialogContent>
        <DialogActions>
          <MPButton
            sx={{ fontSize: "small" }}
            variant="outlined"
            color="info"
            size="small"
            startIcon={<DoneOutlineIcon />}
            onClick={closeTerms}
          >
            확인
          </MPButton>
        </DialogActions>
      </BootstrapDialog>
    </BasicLayout>
  );
}

SnsSignUp.defaultProps = {
  snsInfo: {},
};

SnsSignUp.propTypes = {
  snsInfo: PropTypes.objectOf(PropTypes.string),
};

export default SnsSignUp;
