// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "context/";

// draft 사용방법 https://haranglog.tistory.com/12
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPInput from "components/MPInput/index";
import MPButton from "components/MPButton/index";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// ====================== Service Components ======================
import DashboardLayout from "services/LayoutContainers/DashboardLayout";
import AdmProjectNavbar from "services/Navbars/AdmProjectNavbar";
import AdmFooter from "services/Footer/AdmFooter";
import AdmPrjDetailHeader from "layouts/projectDetail/admProjectDetail/components/AdmPrjDetailHeader";

// ====================== Images ==================================

function AdmProjectDetail() {
  const { projectId } = useParams();
  // useState로 상태관리하기 초기값은 EditorState.createEmpty()
  // EditorState의 비어있는 ContentState 기본 구성으로 새 개체를 반환 => 이렇게 안하면 상태 값을 나중에 변경할 수 없음.
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [controller, dispatch] = useMaterialUIController();
  const [categoryList, setCategoryList] = useState([]);

  const onEditorStateChange = (editorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };
  // HTML 변환 공통 함수
  const editorToHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const handleChangeCategory = (event) => {
    setProjectDetail({ ...projectDetail, largeCateCode: event.target.value });
  };

  const [projectDetail, setProjectDetail] = useState({
    projectName: "",
    projectStartDate: "",
    projectEndDate: "",
    client: "",
    projectRole: "",
    os: "",
    dbms: "",
    language: "",
    framework: "",
    period: "",
    description: "",
    address: "",
    projectType: "",
    estimatedPriceStart: "",
    estimatedPriceEnd: "",
    recruitmentMember: "",
    showYn: "",
    delYn: "",
    recruitmentYn: "",
    largeCateCode: "",
    smallCateCode: "",
    estimatedPriceType: "",
    projectWorkType: "",
    projectType: "",
    chargeType: "",
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      projectName: projectDetail.projectName,
      projectStartDate: projectDetail.projectStartDate,
      projectEndDate: projectDetail.projectEndDate,
      client: projectDetail.client,
      projectRole: projectDetail.projectRole,
      os: projectDetail.os,
      dbms: projectDetail.dbms,
      language: projectDetail.language,
      framework: projectDetail.framework,
      period: projectDetail.period,
      description: projectDetail.description,
      address: projectDetail.address,
      projectType: projectDetail.projectType,
      estimatedPriceStart: projectDetail.estimatedPriceStart,
      estimatedPriceEnd: projectDetail.estimatedPriceEnd,
      recruitmentMember: projectDetail.recruitmentMember,
      showYn: projectDetail.showYn,
      delYn: projectDetail.delYn,
      recruitmentYn: projectDetail.recruitmentYn,
      largeCateCode: projectDetail.largeCateCode,
      smallCateCode: projectDetail.smallCateCode,
      estimatedPriceType: projectDetail.estimatedPriceType,
      projectWorkType: projectDetail.projectWorkType,
      projectType: projectDetail.projectType,
      chargeType: projectDetail.chargeType,
    },
  });

  const handleChange = (event, page) => {
    setPage(page);
  };

  const handleProjectInfo = (event) => {
    const { name, value } = event.target;
    setProjectDetail({
      ...projectDetail,
      [name]: value,
    });
  };

  const updateProject = async (projectInfo) => {
    try {
      const response = await dybApi.put("/api/adm/projectUpdate", projectInfo);
      if (response.data.result === "SC001") {
        // 성공 popup
        setOpenDialogPopup(dispatch, { modalType: "success", showYn: true });
      } else {
        // 실패 popup
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log(error.response);
      // 실패 popup httpStatus code 200 아닐 때 TODO (api 통일해야 한다.)
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };

  const createProject = async (projectInfo) => {
    try {
      const response = await dybApi.post("/api/adm/projectCreate", projectInfo);
      if (response.data.result === "SC001") {
        // 성공 popup
        setOpenDialogPopup(dispatch, {
          modalType: "sucCustom",
          showYn: true,
          content: "프로젝트 등록이 완료되었습니다. \n프로젝트 목록으로 이동합니다.",
          reload: false,
          redirect: "/adm/project",
        });

        // 입력한 내용 공백으로 만들기( 공백으로 만드는 게 오히력 안 좋을 수 있다.)
        // setProjectDetail("");
      } else {
        // 실패 popup
        setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
      }
    } catch (error) {
      console.log(error.response);
      // 실패 popup httpStatus code 200 아닐 때 TODO (api 통일해야 한다.)
      setOpenDialogPopup(dispatch, { modalType: "failure", showYn: true });
    }
  };

  useEffect(() => {
    const fetchCategoryList = async () => {
      try {
        const response = await dybApi.get("/api/svc/v2/categoryList?categoryLevel=1");
        setCategoryList(response.data.data);
      } catch (error) {
        console.error("Error fetching categoryList:", error);
      }
    };

    if (projectId != null) {
      const fetchProjectDetail = async () => {
        try {
          const projectDetailRes = await dybApi.get(
            `/api/adm/projectDetail?projectId=${projectId}`
          );
          if (projectDetailRes.data.result === "SC001") {
            setProjectDetail(projectDetailRes.data.data);

            setValue("projectName", projectDetailRes.data.data.projectName);
            setValue("projectStartDate", projectDetailRes.data.data.projectStartDate);
            setValue("projectEndDate", projectDetailRes.data.data.projectEndDate);
            setValue("client", projectDetailRes.data.data.client);
            setValue("projectRole", projectDetailRes.data.data.projectRole);
            setValue("os", projectDetailRes.data.data.os);
            setValue("dbms", projectDetailRes.data.data.dbms);
            setValue("language", projectDetailRes.data.data.language);
            setValue("framework", projectDetailRes.data.data.framework);
            setValue("period", projectDetailRes.data.data.period);
            setValue("description", projectDetailRes.data.data.description);
            setValue("address", projectDetailRes.data.data.address);
            setValue("projectType", projectDetailRes.data.data.projectType);
            setValue("estimatedPriceStart", projectDetailRes.data.data.estimatedPriceStart);
            setValue("estimatedPriceEnd", projectDetailRes.data.data.estimatedPriceEnd);
            setValue("recruitmentMember", projectDetailRes.data.data.recruitmentMember);
            setValue("largeCateCode", projectDetailRes.data.data.largeCateCode);
            setValue("smallCateCode", projectDetailRes.data.data.smallCateCode);
            setValue("showYn", projectDetailRes.data.data.showYn);
            setValue("delYn", projectDetailRes.data.data.delYn);
            setValue("recruitmentYn", projectDetailRes.data.data.recruitmentYn);
            setValue("chargeType", projectDetailRes.data.data.chargeType);
            setValue("projectWorkType", projectDetailRes.data.data.projectWorkType);
            setValue("estimatedPriceType", projectDetailRes.data.data.estimatedPriceType);
            setValue("projectType", projectDetailRes.data.data.projectType);
          }

          const blocksFromHtml = htmlToDraft(projectDetailRes.data.data.description);
          if (blocksFromHtml) {
            const { contentBlocks, entityMap } = blocksFromHtml;
            // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            // ContentState를 EditorState기반으로 새 개체를 반환.
            // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchProjectDetail();
      fetchCategoryList();
    }
  }, [projectId]);

  return (
    <DashboardLayout>
      <AdmProjectNavbar />
      <MPBox mb={2} />
      <AdmPrjDetailHeader projectInfo={projectDetail} projectId={projectId}>
        <MPBox
          component="form"
          role="form"
          onSubmit={handleSubmit((data) => {
            const projectInfo = {
              ...data,
              projectId: projectId,
              description: editorToHtml(editorState),
            };
            projectId == null ? createProject(projectInfo) : updateProject(projectInfo);
          })}
        >
          <MPBox mt={1} mb={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <MPBox pt={2} px={2}>
                  <MPTypography variant="h6" fontWeight="medium" color="black">
                    프로젝트 계약 정보
                  </MPTypography>
                </MPBox>
                <MPBox px={2}>
                  <MPTypography variant="caption" fontWeight="bold" color="text">
                    프로젝트 명칭
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      fullWidth
                      {...register("projectName", { required: "프로젝트 명칭을 입력해주세요." })}
                      onChange={handleProjectInfo}
                    />
                    <MPBox>
                      {errors.projectName && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.projectName.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="bold" color="text">
                    프로젝트 위치
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("address", { required: "프로젝트 위치를 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.address && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.address.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="bold" color="text">
                    프로젝트 기간 ※ 'OO개월' 로 입력
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("period", { required: "프로젝트 기간을 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.period && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.period.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="bold" color="text">
                    프로젝트 시작일 ※ 형식: YYYYMMDD
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      type="number"
                      {...register("projectStartDate", {
                        required: "프로젝트 시작일을 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.projectStartDate && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.projectStartDate.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="bold" color="text">
                    프로젝트 종료일 ※ 형식: YYYYMMDD
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      type="number"
                      {...register("projectEndDate", {
                        required: "프로젝트 종료일을 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.projectEndDate && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.projectEndDate.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 견적 단위
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup
                        row
                        value={projectDetail.estimatedPriceType}
                        onChange={handleProjectInfo}
                      >
                        <FormControlLabel
                          {...register("estimatedPriceType", {
                            required: "견적 단위를 선택해주세요",
                          })}
                          value="P"
                          control={<Radio />}
                          label="프로젝트 단위"
                        />
                        <FormControlLabel
                          {...register("estimatedPriceType", {
                            required: "견적 단위를 선택해주세요",
                          })}
                          value="M"
                          control={<Radio />}
                          label="월 단위"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.estimatedPriceType && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.estimatedPriceType.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 예상견적(최소) ※ 단위: 만원
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      type="number"
                      {...register("estimatedPriceStart", {
                        required: "예상견적(최소)을 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.estimatedPriceStart && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.estimatedPriceStart.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 예상견적(최대) ※ 단위: 만원
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      type="number"
                      {...register("estimatedPriceEnd", {
                        required: "예상견적(최대)을 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.estimatedPriceEnd && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.estimatedPriceEnd.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트에서 역할(개발, 작업분야)
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("projectRole", {
                        required: "프로젝트에서 역할을 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.projectRole && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.projectRole.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    모집인원 ※ 단위 : 명
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      type="number"
                      {...register("recruitmentMember", { required: "모집인원을 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.recruitmentMember && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.recruitmentMember.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    고객사
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("client", { required: "고객사를 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.client && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.client.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                </MPBox>
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <MPBox p={2}>
                  <MPTypography variant="h6" fontWeight="medium" color="black">
                    프로젝트 기술 정보
                  </MPTypography>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프레임워크(Framework) ※ ,로 구분해 입력 / 프로젝트에서 사용하는 프레임워크
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("framework", {
                        required: "프레임워크(Framework)를 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.framework && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.framework.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로그래밍 언어(Language) ※ ,로 구분해 입력
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("language", {
                        required: "프로그래밍 언어(Language)를 입력해주세요.",
                      })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.language && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.language.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    운영체제(OS) ※ 프로젝트에서 사용하는 운영체제
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("os", { required: "운영체제(OS)를 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.os && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.os.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    데이터베이스(D/B) ※ 프로젝트에서 사용하는 디비
                  </MPTypography>
                  <MPBox>
                    <MPInput
                      {...register("dbms", { required: "데이터베이스(D/B)를 입력해주세요." })}
                      onChange={handleProjectInfo}
                      fullWidth
                    />
                    <MPBox>
                      {errors.dbms && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.dbms.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                </MPBox>
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4}>
                <MPBox p={2}>
                  <MPTypography variant="h6" fontWeight="medium" color="black">
                    프로젝트 표시 정보
                  </MPTypography>
                  <MPBox display="flex">
                    <MPBox mr={1}>
                      <MPBox>
                        <MPTypography variant="caption" fontWeight="regular" color="text">
                          대 카테고리
                        </MPTypography>
                      </MPBox>
                      <FormControl sx={{ width: "120px" }}>
                        <Select
                          {...register("largeCateCode")}
                          value={projectDetail.largeCateCode}
                          onChange={handleProjectInfo}
                        >
                          {categoryList.map((category) => (
                            <MenuItem value={category.categoryCode}>
                              {category.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MPBox>
                    <MPBox>
                      <MPBox>
                        {/* TODO :: 중 카테고리는 나중에 붙이기 */}
                        <MPTypography variant="caption" fontWeight="regular" color="text">
                          중 카테고리
                        </MPTypography>
                      </MPBox>
                      <FormControl sx={{ width: "120px" }}>
                        <Select
                          {...register("smallCateCode")}
                          value={projectDetail.smallCateCode}
                          onChange={handleProjectInfo}
                        >
                          <MenuItem value={"pla"}>기획자</MenuItem>
                          <MenuItem value={"pub"}>퍼블리셔</MenuItem>
                          <MenuItem value={"des"}>디자이너</MenuItem>
                          <MenuItem value={"dev"}>개발자</MenuItem>
                        </Select>
                      </FormControl>
                    </MPBox>
                  </MPBox>
                  <MPBox>
                    <MPTypography variant="caption" fontWeight="regular" color="text">
                      개발(SI) / 운영(SM)프로젝트 여부
                    </MPTypography>
                  </MPBox>
                  <MPBox>
                    <FormControl>
                      <RadioGroup
                        row
                        value={projectDetail.projectType}
                        onChange={handleProjectInfo}
                      >
                        <FormControlLabel
                          {...register("projectType", {
                            required: "프로젝트형태를 선택해주세요",
                          })}
                          value="SI"
                          control={<Radio />}
                          label="SI(개발)"
                        />
                        <FormControlLabel
                          {...register("projectType", {
                            required: "프로젝트형태를 선택해주세요",
                          })}
                          value="SM"
                          control={<Radio />}
                          label="SM(운영)"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.projectType && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.projectType.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    근무형태
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup
                        row
                        value={projectDetail.projectWorkType}
                        onChange={handleProjectInfo}
                      >
                        <FormControlLabel
                          {...register("projectWorkType", {
                            required: "근무형태를 선택해주세요",
                          })}
                          value="RE"
                          control={<Radio />}
                          label="재택(비상주)"
                        />
                        <FormControlLabel
                          {...register("projectWorkType", {
                            required: "근무형태를 선택해주세요",
                          })}
                          value="PL"
                          control={<Radio />}
                          label="상주"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.projectWorkType && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.projectWorkType.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    자사프로젝트 여부 * 자사프로젝트는 수수료 제도 X
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup row value={projectDetail.chargeType} onChange={handleProjectInfo}>
                        <FormControlLabel
                          {...register("chargeType", {
                            required: "자사프로젝트 여부를 선택해주세요",
                          })}
                          value="one"
                          control={<Radio />}
                          label="아웃소싱"
                        />
                        <FormControlLabel
                          {...register("chargeType", {
                            required: "자사프로젝트 여부를 선택해주세요",
                          })}
                          value="mon"
                          control={<Radio />}
                          label="자사 프로젝트"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.chargeType && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.chargeType.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 전시여부 * 비전시일 경우 프론트 노출 X
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup row value={projectDetail.showYn} onChange={handleProjectInfo}>
                        <FormControlLabel
                          {...register("showYn", {
                            required: "전시여부를 선택해주세요",
                          })}
                          value="Y"
                          control={<Radio />}
                          label="전시"
                        />
                        <FormControlLabel
                          {...register("showYn", {
                            required: "전시여부를 선택해주세요",
                          })}
                          value="N"
                          control={<Radio />}
                          label="비전시"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.showYn && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.showYn.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 삭제여부 * 삭제일 경우 프론트 노출 X
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup row value={projectDetail.delYn} onChange={handleProjectInfo}>
                        <FormControlLabel
                          {...register("delYn", {
                            required: "삭제여부를 선택해주세요",
                          })}
                          value="Y"
                          control={<Radio />}
                          label="삭제"
                        />
                        <FormControlLabel
                          {...register("delYn", {
                            required: "삭제여부를 선택해주세요",
                          })}
                          value="N"
                          control={<Radio />}
                          label="노출"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.delYn && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.delYn.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                  <MPTypography variant="caption" fontWeight="regular" color="text">
                    프로젝트 모집여부 * Y일 경우 모집중
                  </MPTypography>
                  <MPBox>
                    <FormControl>
                      <RadioGroup
                        row
                        value={projectDetail.recruitmentYn}
                        onChange={handleProjectInfo}
                      >
                        <FormControlLabel
                          {...register("recruitmentYn", {
                            required: "모집여부를 선택해주세요",
                          })}
                          value="Y"
                          control={<Radio />}
                          label="모집중"
                        />
                        <FormControlLabel
                          {...register("recruitmentYn", {
                            required: "모집여부를 선택해주세요",
                          })}
                          value="N"
                          control={<Radio />}
                          label="모집종료"
                        />
                      </RadioGroup>
                      <MPBox>
                        {errors.recruitmentYn && (
                          <MPTypography variant="caption" color="error" fontWeight="regular">
                            {errors.recruitmentYn.message}
                          </MPTypography>
                        )}
                      </MPBox>
                    </FormControl>
                  </MPBox>
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
          <MPBox pt={2} px={2} lineHeight={1.25}>
            <MPTypography variant="h6" fontWeight="medium">
              프로젝트 상세내용
            </MPTypography>
            <MPBox
              width="100%"
              m={"0 auto"}
              mb={"2rem"}
              sx={{
                "& .editor": {
                  height: "500px !important",
                  border: "1px solid #f1f1f1 !important",
                  padding: "5px !important",
                  borderRadius: "5px !important",
                },
              }}
            >
              <Editor
                // // 에디터와 툴바 모두에 적용되는 클래스
                // wrapperClassName="wrapper-class"
                // // 에디터 주변에 적용된 클래스
                editorClassName="editor"
                // // 툴바 주위에 적용된 클래스
                // toolbarClassName="toolbar-class"
                // 툴바 설정
                toolbar={{
                  // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false },
                }}
                placeholder="내용을 작성해주세요."
                // 한국어 설정
                localization={{
                  locale: "ko",
                }}
                // 초기값 설정
                editorState={editorState}
                // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                onEditorStateChange={onEditorStateChange}
              />
            </MPBox>
          </MPBox>
          <MPBox px={2} mt={1} mb={2}>
            {projectId == null ? (
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                startIcon={<DoneAllIcon />}
                disabled={isSubmitting}
              >
                프로젝트 등록
              </MPButton>
            ) : (
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                startIcon={<DoneAllIcon />}
                disabled={isSubmitting}
              >
                프로젝트 정보 수정
              </MPButton>
            )}
          </MPBox>
        </MPBox>
      </AdmPrjDetailHeader>
      <AdmFooter />
    </DashboardLayout>
  );
}

export default AdmProjectDetail;
