/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { isLogIn, logoutRemoveAuthInfo, isAdmin } from "lib/api/utils";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPButton from "components/MPButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HouseIcon from "@mui/icons-material/House";
// ====================== Service Components ======================

import SidenavCollapse from "services/Sidenav/SidenavCollapse";
import SidenavRoot from "services/Sidenav/SidenavRoot";
import sidenavLogoLabel from "services/Sidenav/styles/sidenav";
// ====================== Images ==================================

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const serviceRoutes = routes
    .filter((router) => router.admin === false)
    .map(({ type, name, icon, title, noCollapse, key, href, route, show }) => {
      let returnValue;

      if (type === "collapse" && show) {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MPTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MPTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    });

  const adminRoutes = routes
    .filter((router) => router.admin === true)
    .map(({ type, name, icon, title, noCollapse, key, href, route, show }) => {
      let returnValue;

      if (type === "collapse" && show) {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MPBox pt={3} pb={1} px={4} textAlign="center">
        <MPBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MPTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MPTypography>
        </MPBox>
        <MPBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MPBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MPBox
            ml={{ xs: 1 }}
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MPTypography variant="h5" fontWeight="regular" color={textColor}>
              {isAdmin ? "관리자 전용 화면" : brandName}
            </MPTypography>
          </MPBox>
        </MPBox>
      </MPBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{isAdmin ? adminRoutes : serviceRoutes}</List>
      <MPBox p={2} mt="auto">
        {isLogIn ? (
          <MPButton
            sx={{ fontSize: "medium" }}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={sidenavColor}
            startIcon={<ExitToAppIcon />}
            fullWidth
            onClick={logoutRemoveAuthInfo}
          >
            로그아웃
          </MPButton>
        ) : (
          <NavLink to="/">
            <MPButton
              sx={{ fontSize: "medium" }}
              // component="a"
              // href="/"
              // target="_blank"
              rel="noreferrer"
              variant="gradient"
              color={sidenavColor}
              startIcon={<HouseIcon />}
              fullWidth
            >
              홈으로
            </MPButton>
          </NavLink>
        )}
      </MPBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool,
};

export default Sidenav;
