// ====================== React Library ===========================
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPButton from "components/MPButton/index";

import MPAvatar from "components/MPAvatar";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
// ====================== Service Components ======================

// ====================== Images ==================================
import breakpoints from "assets/theme/base/breakpoints";
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

function AdmPrjDetailHeader({ children, projectInfo, projectId }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MPBox position="relative" mb={5}>
      <MPBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="8.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 1,
          py: 2,
          px: 1,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MPBox height="100%" mt={0.5} ml={1} lineHeight={1}>
              <MPTypography variant="h5" fontWeight="medium" color="black">
                {projectInfo.projectName}
              </MPTypography>
              <MPTypography variant="button" color="text" fontWeight="regular">
                {projectInfo.projectType} 프로젝트 /{" "}
                {projectInfo.largeCateCode === "dev"
                  ? "개발 프로젝트"
                  : projectInfo.largeCateCode === "des"
                  ? "디자이너 프로젝트"
                  : projectInfo.largeCateCode === "pla"
                  ? "기획자 프로젝트"
                  : projectInfo.largeCateCode === "pub"
                  ? "퍼블리셔 프로젝트"
                  : "개발 프로젝트"}
              </MPTypography>
            </MPBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MPButton
                sx={{ fontSize: "initial" }}
                fullWidth
                variant="contained"
                color="light"
                startIcon={<FlipCameraAndroidIcon />}
                onClick={() => navigate(`/project-detail/${projectId}`)}
              >
                사용자 프로젝트상세 화면
              </MPButton>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MPBox>
  );
}

// Setting default props for the AdmPrjDetailHeader
AdmPrjDetailHeader.defaultProps = {
  children: "",
  projectInfo: "",
  projectId: 0,
};

// Typechecking props for the AdmPrjDetailHeader
AdmPrjDetailHeader.propTypes = {
  children: PropTypes.node,
  projectInfo: PropTypes.object,
  projectId: PropTypes.string,
};

export default AdmPrjDetailHeader;
