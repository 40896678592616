// ====================== React Library ===========================
import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dybApi from "lib/api/dybApi";
import Cookies from "js-cookie";

// ====================== MP&MUI Components  ======================
// ====================== Service Components ======================
import SnsSigIn from "layouts/authentication/sns-sign-in";
// ====================== Images ==================================

function OauthLogin() {
  // 로딩하는 것 마크업으로 보여주기
  const location = useLocation();
  const navigate = useNavigate();

  const [snsInfo, setSnsInfo] = useState({});

  let loginResult = "";

  const handleOAuthNaver = async (code, snsType, state) => {
    try {
      const response = await dybApi.get(
        `api/svc/v2/Oauth2.0?snsType=${snsType}&code=${code}&state=${state}`
      );
      if (response.data.result === "SC001") {
        // 여기서 로그인 처리 하고 홈으로 돌려보내면 될 듯
        loginResult = response.data.data;
        const at = new Date();
        const rt = new Date();
        // SNS Login은 무조건 자동로그인 해두기
        localStorage.setItem("DYBSoft", JSON.stringify(loginResult));

        // 자동로그인이 맞을 경우(쿠키는 똑같이 담고, localStorage, sessionStorage 유무로 로그아웃 시키기)
        at.setTime(at.getTime() + 1000 * 60 * 60 * 24 * 30); // 30일
        rt.setTime(rt.getTime() + 1000 * 60 * 60 * 24 * 90); // 90일
        Cookies.set("AccessToken", loginResult.accessToken, { expires: at });
        Cookies.set("RefreshToken", loginResult.refreshToken, { expires: rt });

        // 새로고침하면서 이력남기기
        window.location.href = "/";
      } else {
        console.log(response.data.data.response, "SNS INFO");
        setSnsInfo(response.data.data.response);
        // navigate("/authentication/sns-sign-up");
      }
    } catch (error) {
      console.error("Error SNS Login ", error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const snsType = searchParams.get("snsType");
    const state = "false";

    if (snsType === "naver") {
      handleOAuthNaver(code, snsType, state);
    }
  }, [location]);

  // 로딩보여줄 것인지 signup 보여줄 것인지, 분기처리해야 함.
  return <SnsSigIn snsInfo={snsInfo} />;
}

export default OauthLogin;
