import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MPBox
import MPBoxRoot from "components/MPBox/MPBoxRoot";

const MPBox = forwardRef(
  (
    { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest },
    // props 목록 뒤에 ref를 별도로 전달받는 모습
    ref
  ) => (
    <MPBoxRoot
      {...rest}
      // 전달받은 ref는 HTML 속성으로 전달된다.
      ref={ref}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
        coloredShadow,
      }}
    />
  )
);
// Setting default values for the props of MPBox
MPBox.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  coloredShadow: "none",
};

// Typechecking props for the MPBox
MPBox.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  coloredShadow: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "none",
  ]),
};

export default MPBox;
