import Cookies from "js-cookie";

export function logoutRemoveAuthInfo(type) {
  Cookies.remove("AccessToken");
  Cookies.remove("RefreshToken");
  window.localStorage.removeItem("DYBSoft");
  window.sessionStorage.removeItem("DYBSoft");
  if (type == "redirectToLogin") {
    window.location.href = "/redirect-to-login";
  } else {
    window.location.href = "/";
  }
}

// YYYYMMDD에서 -> YYYY-MM-DD로 변환
export function convertToDate(YYYYMMDD) {
  return YYYYMMDD?.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
}

export function convertToDateTime(dateTime) {
  const year = dateTime?.[0] ?? 0;
  let month = dateTime?.[1] ?? 0;
  let day = dateTime?.[2] ?? 0;
  let hour = dateTime?.[3] ?? 0;
  let minute = dateTime?.[4] ?? 0;
  let second = dateTime?.[5] ?? 0;
  month.toString().length == 1 ? (month = "0" + month.toString()) : month;
  day.toString().length == 1 ? (day = "0" + day.toString()) : day;
  hour.toString().length == 1 ? (hour = "0" + hour.toString()) : hour;
  minute.toString().length == 1 ? (minute = "0" + minute.toString()) : minute;
  second.toString().length == 1 ? (second = "0" + second.toString()) : second;
  const ConvertedDateTime =
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  return ConvertedDateTime;
}

export function convertToDateWithDateTime(dateTime) {
  const year = dateTime?.[0] ?? 0;
  let month = dateTime?.[1] ?? 0;
  let day = dateTime?.[2] ?? 0;
  month.toString().length == 1 ? (month = "0" + month.toString()) : month;
  day.toString().length == 1 ? (day = "0" + day.toString()) : day;
  const ConvertedDate = year + "-" + month + "-" + day;
  return ConvertedDate;
}

const isLogIn =
  JSON.parse(localStorage.getItem("DYBSoft")) || JSON.parse(sessionStorage.getItem("DYBSoft"));

const isAdmin =
  (JSON.parse(localStorage.getItem("DYBSoft"))
    ? JSON.parse(localStorage.getItem("DYBSoft")).userType
    : "" || JSON.parse(sessionStorage.getItem("DYBSoft"))
    ? JSON.parse(sessionStorage.getItem("DYBSoft")).userType
    : "") == "Adm"
    ? true
    : false;

export { isLogIn, isAdmin };
