/* eslint-disable import/default */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material componentshome

import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import { logoutRemoveAuthInfo } from "lib/api/utils";

function HomeNavbarLink({ icon, name, route, light, logout, handleFunction, fontSize, minWidth }) {
  return (
    <MPBox
      minWidth={minWidth}
      onClick={logout ? logoutRemoveAuthInfo : handleFunction}
      component={Link}
      to={route}
      p={1}
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer", userSelect: "none" }}
    >
      {icon}
      <MPTypography
        variant="button"
        fontSize={fontSize}
        fontWeight="regular"
        color="black"
        textTransform="capitalize"
        sx={{ width: "100%", lineHeight: 0 }}
      >
        &nbsp;{name}
      </MPTypography>
    </MPBox>
  );
}

// Typechecking props for the HomeNavbarLink
HomeNavbarLink.propTypes = {
  icon: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  light: PropTypes.bool.isRequired,
  logout: PropTypes.bool,
  handleFunction: PropTypes.func,
  fontSize: PropTypes.string,
};

export default HomeNavbarLink;
