// ====================== React Library ===========================
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import dybApi from "lib/api/dybApi";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox/index";
import MPButton from "components/MPButton/index";
import MPInput from "components/MPInput";
import MPTypography from "components/MPTypography/index";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Grid } from "../../../../node_modules/@mui/material/index";

// ====================== Service Components ======================
import SvcFooter from "services/Footer/SvcFooter";
import HomeNavbar from "services/Navbars/HomeNavbar";
import PageLayout from "services/LayoutContainers/PageLayout/index";

// ====================== Images ==================================

function Inquiry() {
  const { getInputProps, acceptedFiles } = useDropzone({
    // accept: "image/*", // Only accept image files
    maxFiles: 1, // Limit the number of files to 1
  });

  const files = acceptedFiles.map((file, index) => (
    <MPBox key={index}>
      <MPTypography
        sx={{ whiteSpace: "no-wrap", overflow: "hidden", textOverflow: "ellipsis" }}
        fontSize="medium"
        fontWeight="bold"
        color="inherit"
      >
        {index + 1}. {file.name}
      </MPTypography>
    </MPBox>
  ));

  const fileInputRef = useRef(null);
  const handleClickSelectFiles = () => {
    fileInputRef.current && fileInputRef.current.click();
  };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  const dialogClose = () => {
    setOpen(false);
    navigate("/");
  };
  //Dialog 팝업 상태
  const [open, setOpen] = useState(false);
  const createInquiry = async (qnaInfo) => {
    try {
      const response = await dybApi.post("/api/svc/v2/qnaInfoCreate", qnaInfo);
      if (response.data.result === "SC001") {
        setOpen(true);
      } else {
        // Handle other result codes if necessary
      }
    } catch (error) {
      console.log("Error creating inquiry:", error);
    }
  };

  return (
    <PageLayout background="#FAFAFD">
      <HomeNavbar light={false} />
      <Grid container justifyContent="center">
        <Grid item xs={11} md={8} xl={5}>
          <MPBox mt={5} mb={1} sx={{ width: "100%" }} borderRadius="lg" coloredShadow="success">
            <Card>
              <MPBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MPTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                  DYB Soft 에게 문의하세요
                </MPTypography>
              </MPBox>
              <MPBox pt={2} pb={3} px={3}>
                <MPBox
                  component="form"
                  role="form"
                  onSubmit={handleSubmit((data) => {
                    const formData = new FormData();
                    const qnaInfo = JSON.stringify(data);
                    const blob = new Blob([qnaInfo], { type: "application/json" });
                    formData.append("qnaFile", acceptedFiles[0]);
                    formData.append("qnaInfo", blob);
                    createInquiry(formData);
                  })}
                >
                  <MPTypography variant="h6" fontWeight="regular" color="dark">
                    문의 유형
                  </MPTypography>
                  <FormControl>
                    <RadioGroup row defaultValue="AWS">
                      <FormControlLabel
                        {...register("qnaType", {
                          required: "문의 유형을 선택해주세요",
                        })}
                        value="AWS"
                        control={<Radio />}
                        label="AWS 구축 의뢰"
                      />
                      <FormControlLabel
                        {...register("qnaType", {
                          required: "문의 유형을 선택해주세요",
                        })}
                        value="SI"
                        control={<Radio />}
                        label="신규 개발 의뢰"
                      />
                      <FormControlLabel
                        {...register("qnaType", {
                          required: "문의 유형을 선택해주세요",
                        })}
                        value="SM"
                        control={<Radio />}
                        label="유지보수(Bug fix) 의뢰"
                      />
                    </RadioGroup>
                    <MPBox>
                      {errors.qnaType && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaType.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </FormControl>
                  <MPBox mb={3}>
                    <MPInput
                      type="text"
                      label="회사명"
                      variant="standard"
                      {...register("qnaCompany", {
                        required: "회사명을 입력해주세요.",
                      })}
                      fullWidth
                    />
                    <MPBox>
                      {errors.qnaCompany && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaCompany.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox mb={3}>
                    <MPInput
                      type="text"
                      label="이름"
                      variant="standard"
                      {...register("qnaName", {
                        required: "이름을 입력해주세요.",
                      })}
                      fullWidth
                    />
                    <MPBox>
                      {errors.qnaName && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaName.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox mb={3}>
                    <MPInput
                      type="text"
                      label="전화번호"
                      variant="standard"
                      {...register("qnaPhone", {
                        required: "전화번호를 입력해주세요.",
                      })}
                      fullWidth
                    />
                    <MPBox>
                      {errors.qnaPhone && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaPhone.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox mb={3}>
                    <MPInput
                      type="email"
                      label="Email 주소"
                      variant="standard"
                      {...register("qnaEmail", {
                        required: "이메일주소를 입력해주세요.",
                        pattern: {
                          value:
                            /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,3}$/,
                          message: "이메일을 다시 확인해주세요.",
                        },
                      })}
                      fullWidth
                    />
                    <MPBox>
                      {errors.qnaEmail && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaEmail.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox mb={3}>
                    <MPInput
                      type="text"
                      label="제목"
                      variant="standard"
                      {...register("qnaTitle", {
                        required: "제목을 입력해주세요.",
                      })}
                      fullWidth
                    />
                    <MPBox>
                      {errors.qnaTitle && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaTitle.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox mb={3}>
                    <TextField
                      id="standard-multiline-static"
                      label="문의할 내용"
                      multiline
                      rows={10}
                      variant="standard"
                      fullWidth
                      {...register("qnaContent", {
                        required: "문의할 내용을 입력해주세요.",
                      })}
                    />
                    <MPBox>
                      {errors.qnaContent && (
                        <MPTypography variant="caption" color="error" fontWeight="regular">
                          {errors.qnaContent.message}
                        </MPTypography>
                      )}
                    </MPBox>
                  </MPBox>
                  <MPBox>
                    <input {...getInputProps()} ref={fileInputRef} sx={{ display: "none" }} />
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={handleClickSelectFiles}
                    >
                      파일 첨부
                    </MPButton>
                    <MPBox mt={1}>{files}</MPBox>
                  </MPBox>
                  <MPBox mt={3} mb={1}>
                    <MPButton
                      sx={{ fontSize: "initial" }}
                      type="submit"
                      variant="gradient"
                      fontWeight="bold"
                      color="info"
                      startIcon={<FileUploadOutlinedIcon />}
                      fullWidth
                    >
                      문의 완료
                    </MPButton>
                  </MPBox>
                </MPBox>
              </MPBox>
              {/* Dialog 팝업 */}
              <Dialog
                open={open}
                onClose={dialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"문의 완료"}</DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                  <DialogContentText id="alert-dialog-description">
                    {"고객님 문의가 완료되었습니다."}
                    <br />
                    {"하루 내로 담당자가 연락드리겠습니다."}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MPButton
                    sx={{ fontSize: "initial" }}
                    variant="contained"
                    color="info"
                    size="small"
                    startIcon={<DoneOutlineIcon />}
                    onClick={dialogClose}
                  >
                    확인
                  </MPButton>
                </DialogActions>
              </Dialog>
            </Card>
          </MPBox>
        </Grid>
      </Grid>
      <SvcFooter />
    </PageLayout>
  );
}

export default Inquiry;
