// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "context/";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPInput from "components/MPInput";
import MPTypography from "components/MPTypography";
import MPButton from "components/MPButton";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DialogTitle from "@mui/material/DialogTitle";
import LoginIcon from "@mui/icons-material/Login";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";

// ====================== Service Components ======================
import BasicLayout from "layouts/authentication/components/BasicLayout";

// ====================== Images ==================================

import errorPopup from "assets/images/popup/icon_warning.svg";
import typography from "assets/theme/base/typography";

// Dialog 팝업

function SignIn() {
  const { size } = typography;
  const [controller, dispatch] = useMaterialUIController();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [rememberMe, setRememberMe] = useState(false);
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [conditions, setConditions] = useState(false);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ mode: "onBlur" });

  const dialogClose = () => {
    setOpen(false);
    setTerms(false);
    setConditions(false);
  };

  const openTerms = () => {
    setOpen(true);
    setTerms(true);
  };

  const openConditions = () => {
    setOpen(true);
    setConditions(true);
  };

  let loginResult = "";

  const requestLogin = async (loginDto) => {
    try {
      const response = await dybApi.post("/api/svc/v2/signIn", loginDto);
      if (response.data.result === "SC001") {
        loginResult = response.data.data;
        const at = new Date();
        const rt = new Date();
        if (rememberMe == true) {
          // 자동로그인 맞을 경우 localStorage에 데이터 담기
          localStorage.setItem("DYBSoft", JSON.stringify(loginResult));
        } else {
          // 자동로그인 아닐 경우는 sessionStorage에 데이터 담기
          sessionStorage.setItem("DYBSoft", JSON.stringify(loginResult));
        }
        // 자동로그인이 맞을 경우(쿠키는 똑같이 담고, localStorage, sessionStorage 유무로 로그아웃 시키기)
        at.setTime(at.getTime() + 1000 * 60 * 60 * 24 * 30); // 30일
        rt.setTime(rt.getTime() + 1000 * 60 * 60 * 24 * 90); // 90일
        Cookies.set("AccessToken", loginResult.accessToken, { expires: at });
        Cookies.set("RefreshToken", loginResult.refreshToken, { expires: rt });

        // 이력서 목록 페이지로 이동 여기 navigate가 좀 이상함, adm으로 들어가게 하면 안됨..
        // TODO :: navigate 하면 전체 새로고침 안되는 현상 해결..
        // <Navigate to={state?.from || "/"} replace />;

        // 새로고침하면서 이력남기기
        window.location.href = "/";
      }
    } catch (error) {
      const titleMsg = "로그인에 실패하셨습니다.";

      let contentMsg = "";
      if (error.response.data.result === "MB003") {
        contentMsg = "아이디(이메일주소)와 비밀번호가 \n일치하지 않습니다.";
      } else {
        contentMsg = "작업에 실패하셨습니다. \n잠시 후 다시 시도해주세요";
      }
      // 공통 팝업
      setOpenDialogPopup(dispatch, {
        modalType: "failCustom",
        showYn: true,
        title: titleMsg,
        content: contentMsg,
        reload: false,
      });
    }
  };
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const REDIRECT_URI = "http://dybsoft.com/Oauth?snsType=naver";
  const STATE = "false";
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

  const NaverLogin = () => {
    console.log(NAVER_CLIENT_ID, "클라아이디");
    window.location.href = NAVER_AUTH_URL;
  };

  useEffect(() => {}, []);

  return (
    <BasicLayout>
      <MPTypography variant="h6" fontWeight="medium">
        SNS 간편 로그인
      </MPTypography>
      <MPBox mt={1} mb={1} display="flex" alignItems="center" justifyContent="center">
        <MPBox maxWidth="250px">
          <MPButton
            sx={{ background: "#1EC800", fontSize: "size.sm" }}
            fullWidth
            color="black"
            variant="contained"
            startIcon={<LockOpenIcon />}
            onClick={NaverLogin}
          >
            네이버 아이디로 로그인
          </MPButton>
        </MPBox>
      </MPBox>
      <MPBox mb={5} display="flex" alignItems="center" justifyContent="center">
        <MPBox maxWidth="250px">
          <MPButton
            sx={{ background: "#FBE702", fontSize: "size.sm" }}
            fullWidth
            color="black"
            variant="outlined"
            startIcon={<FmdGoodRoundedIcon />}
            onClick={() => alert("서비스 준비중입니다.")}
          >
            카카오 아이디로 로그인
          </MPButton>
        </MPBox>
      </MPBox>

      <Card>
        <MPBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MPTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            DYB Soft 로그인
          </MPTypography>
        </MPBox>
        <MPBox pt={4} pb={3} px={3}>
          <MPBox
            component="form"
            role="form"
            onSubmit={handleSubmit((data) => {
              requestLogin(data);
            })}
          >
            <MPBox mb={2}>
              <MPInput
                type="email"
                label="Email"
                {...register("email", {
                  required: "아이디(이메일주소)입력해주세요.",
                  pattern: {
                    value:
                      /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    message: "이메일을 다시 확인해주세요.",
                  },
                })}
                fullWidth
              />
              <MPBox>
                {errors.email && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.email.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox mb={2}>
              <MPInput
                type="password"
                label="Password"
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
                    message:
                      "비밀번호는 영문,숫자,특수문자 를 모두 조합하여 최소 8~15자 입력해 주세요.",
                  },
                })}
                autoComplete="on"
                fullWidth
              />
              <MPBox>
                {errors.password && (
                  <MPTypography variant="caption" color="error" fontWeight="regular">
                    {errors.password.message}
                  </MPTypography>
                )}
              </MPBox>
            </MPBox>
            <MPBox display="flex" alignItems="center" ml={-1}>
              <Switch
                checked={rememberMe}
                {...register("autoLogin")}
                onChange={handleSetRememberMe}
              />
              <MPTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;자동 로그인
              </MPTypography>
            </MPBox>
            <MPBox mt={3} mb={1}>
              <MPButton
                sx={{ fontSize: "initial" }}
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                startIcon={<LoginIcon />}
                disabled={isSubmitting}
              >
                로그인
              </MPButton>
            </MPBox>
            <MPBox mt={3} mb={1} textAlign="center">
              <MPTypography variant="button" fontWeight="regular">
                DYB Soft 계정이 없으신가요?{" "}
                <MPTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  SIGN UP(회원가입)
                </MPTypography>
              </MPTypography>
            </MPBox>
          </MPBox>
        </MPBox>
        {/* Dialog 팝업 */}
        <Dialog open={open} onClose={dialogClose}>
          <DialogTitle sx={{ fontSize: "large" }}>
            {terms ? "이용약관" : ""}
            {conditions ? "개인정보 처리방침" : ""}
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }} dividers>
            <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
              {terms
                ? "이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관" +
                  "이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관" +
                  "이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관" +
                  "이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관" +
                  "이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관이용약관"
                : ""}
              {conditions
                ? "개인정보 처리방침개인정보 처리방침개인정보 처리방침개인정보 처리방침" +
                  "개인정보 처리방침개인정보 처리방침개인정보 처리방침개인정보 처리방침" +
                  "개인정보 처리방침개인정보 처리방침개인정보 처리방침개인정보 처리방침" +
                  "개인정보 처리방침개인정보 처리방침개인정보 처리방침개인정보 처리방침" +
                  "개인정보 처리방침개인정보 처리방침개인정보 처리방침개인정보 처리방침"
                : ""}
            </MPTypography>
          </DialogContent>
          <DialogActions>
            <MPButton
              sx={{ fontSize: "small" }}
              variant="outlined"
              color="info"
              size="small"
              startIcon={<DoneOutlineIcon />}
              onClick={dialogClose}
            >
              확인
            </MPButton>
          </DialogActions>
        </Dialog>
      </Card>
      <MPBox pt={2} px={3}>
        <MPBox display="flex" justifyContent="center">
          <MPBox mr={1}>
            <MPButton variant="outlined" color="black" size="small" onClick={openTerms}>
              이용 약관
            </MPButton>
          </MPBox>
          <MPBox>
            <MPButton variant="outlined" color="black" size="small" onClick={openConditions}>
              개인정보 처리 방침
            </MPButton>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox fontSize={size.sm} pt={2} display="flex" justifyContent="center">
        <MPBox>
          &copy; {new Date().getFullYear()}, made by
          <Link to={"/"} target="_blank">
            <MPTypography variant="button" fontWeight="medium">
              &nbsp;{"Providence Inc."}&nbsp;
            </MPTypography>
          </Link>
          All Rights Reserved.
        </MPBox>
      </MPBox>
    </BasicLayout>
  );
}

export default SignIn;
