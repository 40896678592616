// ====================== React Library ===========================
import { useState, useEffect } from "react";
import dybApi from "lib/api/dybApi";
import { convertToDate, convertToDateTime } from "lib/api/utils";
import { Link } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPAvatar from "components/MPAvatar";
import MPBadge from "components/MPBadge";
import Chip from "@mui/material/Chip";
// ====================== Service Components ======================

// ====================== Images ==================================
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function MyProjectData(page) {
  const [dataList, setDataList] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({});

  useEffect(() => {
    fetchMyProjectList();
    return () => {
      // unmount 시 초기화
    };
  }, [page]);

  const fetchMyProjectList = async () => {
    try {
      const response = await dybApi.get(`/api/svc/v1/myApplyProjectList?page=${page}`);
      if (response.data.result == "SC001") {
        setDataList(response.data.data);
        setPagingInfo(response.data.pagingInfo);
      }
    } catch (error) {
      console.error("Error fetching fetchMyProjectList : ", error);
    }
  };

  const Person = ({ image, name, maneger, projectId }) => (
    <MPBox display="flex" alignItems="center" lineHeight={1}>
      <MPAvatar src={image} name={name} size="sm" />
      <MPBox ml={2} lineHeight={1}>
        <MPTypography display="block" variant="button" fontWeight="medium">
          <Link to={`/project-detail/${projectId}`}>{name}</Link>
        </MPTypography>

        <MPTypography variant="caption">{maneger}</MPTypography>
      </MPBox>
    </MPBox>
  );

  const Job = ({ title, description }) => (
    <MPBox lineHeight={1} textAlign="left">
      <MPTypography display="block" variant="caption" color="black" fontWeight="medium">
        {title}
      </MPTypography>
      <MPTypography variant="caption" color="black">
        {description}
      </MPTypography>
    </MPBox>
  );

  const tableColumn = [
    {
      Header: "프로젝트 명칭 ( *제목 클릭시 프로젝트 상세 페이지 이동 )",
      accessor: "projectName",
      width: "35%",
      align: "left",
    },
    { Header: "계약 단계", accessor: "status", align: "center" },
    { Header: "프로젝트 견적", accessor: "estimatedPrice", align: "center" },
    { Header: "나의 희망 견적", accessor: "estimatedHopePrice", align: "center" },
    { Header: "프로젝트 시작일", accessor: "startDate", align: "center" },
    { Header: "나의 시작 희망일", accessor: "startHopeDate", align: "center" },
    { Header: "지원한 일시", accessor: "applyDate", align: "center" },
  ];

  const myProjectList = dataList
    ? dataList.map((myPrj, index) => ({
        projectName: <Person image={team2} name={myPrj.projectName} projectId={myPrj.projectId} />,
        status: (
          <MPBox>
            <MPTypography component="div" variant="caption" fontWeight="regular">
              {myPrj.contractStep == "APP" ? (
                <Chip
                  key={myPrj.contractStep}
                  label="지원 완료"
                  size="small"
                  color="warning"
                  variant="contained"
                />
              ) : myPrj.contractStep == "DOC" ? (
                <Chip
                  key={myPrj.contractStep}
                  label="서류 검토"
                  size="small"
                  color="info"
                  variant="contained"
                />
              ) : myPrj.contractStep == "INT" ? (
                <Chip
                  key={myPrj.contractStep}
                  label="인터뷰 요청"
                  size="small"
                  color="primary"
                  variant="contained"
                />
              ) : myPrj.contractStep == "SUC" ? (
                <Chip
                  key={myPrj.contractStep}
                  label="계약 체결"
                  size="small"
                  color="success"
                  variant="contained"
                />
              ) : (
                <Chip
                  key={myPrj.contractStep}
                  label="모집 종료"
                  size="small"
                  color="secondary"
                  variant="secondary"
                />
              )}
            </MPTypography>
          </MPBox>
        ),
        estimatedPrice: (
          // <Job
          //   title={myPrj.estimatedPriceStart + "만원"}
          //   description={myPrj.estimatedPriceStart + "~" + myPrj.estimatedPriceEnd + "만원"}
          // />
          <MPTypography variant="caption" color="black" fontWeight="regular">
            {myPrj.estimatedPriceStart + "만원 ~" + myPrj.estimatedPriceEnd + "만원"}
          </MPTypography>
        ),
        estimatedHopePrice: (
          <MPTypography variant="caption" color="black" fontWeight="medium">
            {myPrj.devHopeStartPrice + "만원 ~" + myPrj.devHopeEndPrice + "만원"}
          </MPTypography>
        ),

        startDate: (
          <MPTypography variant="caption" color="black" fontWeight="regular">
            {convertToDate(myPrj.projectStartDate)}
          </MPTypography>
        ),
        startHopeDate: (
          <MPTypography variant="caption" color="black" fontWeight="medium">
            {convertToDate(myPrj.prjStPosDate)}
          </MPTypography>
        ),
        applyDate: (
          <MPTypography variant="caption" color="black" fontWeight="regular">
            {convertToDateTime(myPrj.regiDttm)}
          </MPTypography>
        ),
      }))
    : "";

  return {
    columns: tableColumn,
    rows: myProjectList,
    pagingInfo: pagingInfo,
  };
}
export default MyProjectData;
