// ====================== React Library ===========================
import { useEffect, useState, useRef, useCallback } from "react";
import dybApi from "lib/api/dybApi";
import { setOpenDialogPopup, useMaterialUIController } from "context/";

import * as XLSX from "xlsx";

// ====================== MP&MUI Components  ======================
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MPBox from "components/MPBox";

// ====================== Service Components ======================
import DashboardLayout from "services/LayoutContainers/DashboardLayout";
import AdmFooter from "services/Footer/AdmFooter/index";

// ====================== Images ==================================
import breakpoints from "assets/theme/base/breakpoints";

function RegiUser() {
  return (
    <DashboardLayout>
      <MPBox>회원가입 사용자 화면</MPBox>
      <AdmFooter />
    </DashboardLayout>
  );
}

export default RegiUser;
