import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import auth, { authSaga } from "./auth";
import loading from "./loading";
import projectFilter from "./projectFilter";

const rootReducer = combineReducers({ auth, loading, projectFilter });

export function* rootSaga() {
  yield all([authSaga]);
}

export default rootReducer;
