// ====================== React Library ===========================
import PropTypes from "prop-types";
import { setOpenDialogPopup, useMaterialUIController } from "../../context";
import { useNavigate } from "react-router-dom";

// ====================== MP&MUI Components  ======================
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MPTypography from "components/MPTypography/index";
import MPButton from "components/MPButton/index";
import MPBox from "components/MPBox/index";
// ====================== Service Components ======================

// ====================== Images ==================================
import alertImg from "assets/images/popup/icon_alert.svg";
import acceptImg from "assets/images/popup/icon_accept.svg";
import warningImg from "assets/images/popup/icon_warning.svg";
import questionImg from "assets/images/popup/icon_question.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "large" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [controller, dispatch] = useMaterialUIController();
  const { openDialogpopup } = controller;
  const navigate = useNavigate();

  // const [open, setOpen] = useState(openDialogpopup);

  const handleCloseNoAction = () => {
    setOpenDialogPopup(dispatch, { modalType: openDialogpopup.modalType, showYn: false });
  };
  const handleClose = () => {
    setOpenDialogPopup(dispatch, { modalType: openDialogpopup.modalType, showYn: false });
    if (openDialogpopup.modalType == "login") navigate("/authentication/sign-in");
    if (openDialogpopup.redirect) {
      navigate(openDialogpopup.redirect);
    }
  };

  const handleCloseWithReload = () => {
    location.reload();
    setOpenDialogPopup(dispatch, { modalType: openDialogpopup.modalType, showYn: false });
  };
  return (
    <MPBox>
      <BootstrapDialog
        onClose={handleCloseNoAction}
        aria-labelledby="customized-dialog-title"
        open={openDialogpopup.showYn}
      >
        <BootstrapDialogTitle onClose={handleCloseNoAction}>
          {openDialogpopup.modalType == "sucCustom"
            ? openDialogpopup.title || "작업 완료~!"
            : openDialogpopup.modalType == "failCustom"
            ? openDialogpopup.title || "작업 실패~!"
            : openDialogpopup.modalType == "admin"
            ? "관리자용 화면"
            : openDialogpopup.modalType == "login"
            ? "로그인 필요~!"
            : openDialogpopup.modalType == "failure"
            ? "작업 실패~!"
            : "작업 완료~!"}
        </BootstrapDialogTitle>
        <DialogContent sx={{ textAlign: "center" }} dividers>
          <MPBox display="flex" justifyContent="center" sx={{ mb: "10px" }}>
            <CardMedia
              src={
                openDialogpopup.modalType == "sucCustom"
                  ? acceptImg
                  : openDialogpopup.modalType == "failCustom"
                  ? warningImg
                  : openDialogpopup.modalType == "admin"
                  ? warningImg
                  : openDialogpopup.modalType == "login"
                  ? alertImg
                  : openDialogpopup.modalType == "failure"
                  ? warningImg
                  : acceptImg
              }
              component="img"
              title={
                openDialogpopup.modalType == "sucCustom"
                  ? "successe"
                  : openDialogpopup.modalType == "failCustom"
                  ? "failure"
                  : openDialogpopup.modalType == "admin"
                  ? "admin"
                  : openDialogpopup.modalType == "login"
                  ? "login"
                  : openDialogpopup.modalType == "failure"
                  ? "failure"
                  : "successe"
              }
              sx={{
                maxWidth: "100%",
                margin: 0,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </MPBox>
          {openDialogpopup.modalType == "sucCustom" || openDialogpopup.modalType == "failCustom" ? (
            <MPTypography gutterBottom sx={{ fontSize: "medium", whiteSpace: "pre-line" }}>
              {openDialogpopup.content}
            </MPTypography>
          ) : openDialogpopup.modalType == "admin" ? (
            <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
              접근 권한이 없습니다.
              <br />
              관리자만 접근할 수 있습니다.
            </MPTypography>
          ) : openDialogpopup.modalType == "login" ? (
            <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
              로그인이 필요한 서비스입니다.
              <br />
              로그인 페이지로 이동하시겠습니까?
            </MPTypography>
          ) : openDialogpopup.modalType == "failure" ? (
            <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
              작업에 실패하셨습니다.
              <br />
              다시 시도해주세요.
            </MPTypography>
          ) : (
            <MPTypography gutterBottom sx={{ fontSize: "medium" }}>
              성공적으로 작업을 수행했습니다.
            </MPTypography>
          )}
        </DialogContent>
        <DialogActions>
          <MPButton
            sx={{ fontSize: "small" }}
            variant="contained"
            color="info"
            size="small"
            startIcon={<DoneOutlineIcon />}
            onClick={openDialogpopup.reload ? handleCloseWithReload : handleClose}
          >
            확인
          </MPButton>
        </DialogActions>
      </BootstrapDialog>
    </MPBox>
  );
}
