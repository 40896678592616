/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { isLogIn } from "lib/api/utils";

// ====================== MP&MUI Components  ======================

// ====================== Service Components ======================
import Home from "layouts/home";
import Oauth from "layouts/oauth";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SnsSignUp from "layouts/authentication/sns-sign-up";
import SnsSignIn from "layouts/authentication/sns-sign-in";

import Mypage from "layouts/mypage";
import SvcAWSpage from "layouts/awspage/svcAws";
import AWSDetail from "layouts/awsDetail/index";

import Resume from "layouts/resume";
import Profile from "layouts/profile";
import SvcProject from "layouts/project/svcProject";
import SvcProjectDetail from "layouts/projectDetail/svcProjectDetail/index";
import AdmProject from "layouts/project/admProject";
import AdmProjectDetail from "layouts/projectDetail/admProjectDetail/index";
import Inquiry from "layouts/inquiry/svcInquiry/index";
import AdmInquiry from "layouts/inquiry/admInquiry/index";
import Contract from "layouts/contract";
import RedirectToLogin from "layouts/error/redirectToLogin";
import RegiUser from "layouts/regiUser";

// ====================== Images ==================================
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import BusinessIcon from "@mui/icons-material/Business";
import Icon from "@mui/material/Icon";
import CloudIcon from "@mui/icons-material/Cloud";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LoginIcon from "@mui/icons-material/Login";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// admin 관리자 페이지 여부
// auth 로그인 필요여부
// show SideNav에 표시여부

const routes = [
  {
    type: "collapse",
    name: "DYB Soft 소개 및 서비스",
    key: "info",
    admin: false,
    auth: false,
    show: true,
    icon: (
      <Icon fontSize="small">
        <BusinessIcon />
      </Icon>
    ),
    route: "/",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Oauth 2.0 로그인",
    key: "info",
    admin: false,
    auth: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <LoginIcon />
      </Icon>
    ),
    route: "/Oauth",
    component: <Oauth />,
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "Inquiry",
    admin: false,
    auth: false,
    show: true,
    icon: (
      <Icon fontSize="small">
        <RingVolumeIcon />
      </Icon>
    ),
    route: "/inquiry",
    component: <Inquiry />,
  },
  {
    type: "collapse",
    name: "AWS 솔루션 구축 사례",
    key: "aws",
    auth: false,
    admin: false,
    show: true,
    icon: (
      <Icon fontSize="small">
        <CloudIcon />
      </Icon>
    ),
    route: "/awspage",
    component: <SvcAWSpage />,
  },
  {
    type: "collapse",
    name: "AWS 솔루션 구축 사례 상세페이지",
    key: "awsDetail",
    auth: true,
    admin: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <CloudIcon />
      </Icon>
    ),
    route: "/awspage/:pageId",
    component: <AWSDetail />,
  },
  {
    type: "collapse",
    name: "DYB Soft 프로젝트 현황",
    key: "project",
    admin: false,
    auth: false,
    show: true,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/project/",
    component: <SvcProject />,
  },
  {
    type: "collapse",
    name: "SvcProjectDetail",
    key: "SvcProjectDetail",
    admin: false,
    auth: false,
    show: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/project-detail/:projectId",
    component: <SvcProjectDetail />,
  },
  {
    type: "collapse",
    name: "회원가입 (SIGN UP)",
    key: "sign-up",
    admin: false,
    auth: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <ArrowCircleLeftIcon />
      </Icon>
    ),
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "SNS 간편 회원가입 (SIGN UP)",
    key: "sns-sign-up",
    admin: false,
    auth: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <ArrowCircleLeftIcon />
      </Icon>
    ),
    route: "/authentication/sns-sign-up",
    component: <SnsSignUp />,
    SnsSignIn,
  },
  {
    type: "collapse",
    name: "SNS 간편 로그인 계정연동",
    key: "sns-sign-in",
    admin: false,
    auth: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <ArrowCircleLeftIcon />
      </Icon>
    ),
    route: "/authentication/sns-sign-in",
    component: <SnsSignIn />,
    SnsSignIn,
  },
  {
    type: "collapse",
    name: "로그인 (SIGN IN)",
    key: "sign-in",
    admin: false,
    auth: false,
    show: !isLogIn,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "마이페이지",
    key: "mypage",
    admin: false,
    auth: true,
    show: isLogIn,
    icon: (
      <Icon fontSize="small">
        <AccessibilityIcon />
      </Icon>
    ),
    route: "/mypage",
    component: <Mypage />,
  },
  {
    type: "collapse",
    name: "로그인 페이지로 라우팅",
    key: "redirectToLogin",
    admin: false,
    auth: false,
    show: false,
    icon: (
      <Icon fontSize="small">
        <AccessibilityIcon />
      </Icon>
    ),
    route: "/redirect-to-login",
    component: <RedirectToLogin />,
  },
  {
    type: "collapse",
    name: "전체 프로젝트 관리",
    key: "AdmProject",
    admin: true,
    auth: true,
    show: true,
    icon: (
      <Icon fontSize="small">
        <SupervisorAccountIcon />
      </Icon>
    ),
    route: "/adm/project",
    component: <AdmProject />,
  },
  {
    type: "collapse",
    name: "DYB Soft 인력(이력) 관리",
    key: "resume",
    admin: true,
    auth: true,
    show: true,
    icon: (
      <Icon fontSize="small">
        <Diversity3Icon />
      </Icon>
    ),
    route: "/adm/resume",
    component: <Resume />,
  },
  {
    type: "collapse",
    name: "문의 내역 관리",
    key: "admInquiry",
    admin: true,
    auth: true,
    show: true,
    icon: (
      <Icon fontSize="small">
        <QuestionMarkIcon />
      </Icon>
    ),
    route: "/adm/inquiry",
    component: <AdmInquiry />,
  },
  {
    type: "collapse",
    name: "회원가입 사용자 관리",
    key: "regiUser",
    admin: true,
    auth: true,
    show: true,
    icon: (
      <Icon fontSize="small">
        <PermIdentityIcon />
      </Icon>
    ),
    route: "/adm/regiUser",
    component: <RegiUser />,
  },
  {
    type: "collapse",
    name: "프로젝트 지원자 내역",
    key: "contract",
    admin: true,
    auth: true,
    show: false,
    icon: (
      <Icon fontSize="small">
        <AccessibilityIcon />
      </Icon>
    ),
    route: "/adm/contract/:projectId",
    component: <Contract />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    admin: true,
    auth: true,
    show: false,
    icon: <Icon fontSize="small">person</Icon>,
    route: "/adm/resume/:id",
    component: <Profile />,
  },
  {
    // 이렇게 라우터 두 개 등록하는 게 최선인가?
    type: "collapse",
    name: "프로젝트 관리 상세(최초 등록)",
    key: "AdmProjectDetail",
    admin: true,
    auth: true,
    show: false,
    icon: (
      <Icon fontSize="small">
        <CallToActionIcon />
      </Icon>
    ),
    route: "/adm/project-detail",
    component: <AdmProjectDetail />,
  },
  {
    type: "collapse",
    name: "프로젝트 관리 상세(수정, 읽기)",
    key: "AdmProjectDetail",
    admin: true,
    auth: true,
    show: false,
    icon: (
      <Icon fontSize="small">
        <CallToActionIcon />
      </Icon>
    ),
    route: "/adm/project-detail/:projectId",
    component: <AdmProjectDetail />,
  },
];

export default routes;
