// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isLogIn, logoutRemoveAuthInfo, isAdmin } from "lib/api/utils";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

// ====================== MP&MUI Components  ======================
import Icon from "@mui/material/Icon";
import BusinessIcon from "@mui/icons-material/Business";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import LoginIcon from "@mui/icons-material/Login";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ListItemIcon from "@mui/material/ListItemIcon";
import dybHomeIcon from "assets/images/logos/dybsoft/providence-logo.png";
import CloudIcon from "@mui/icons-material/Cloud";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import MPButton from "components/MPButton";
import MPAvatar from "components/MPAvatar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

// ====================== Service Components ======================
import HomeNavbarLink from "./HomeNavbarLink";
import HomeNavbarLinkMobile from "./HomeNavbarLinkMobile";
import NotificationItem from "services/Items/NotificationItem";

// ====================== Images ==================================
import ManDefault from "assets/images/icons/man_defalut.jpg";
import smileImg from "assets/images/smile.png";

import breakpoints from "assets/theme/base/breakpoints";
import WomanDefault from "assets/images/icons/woman_default.jpg";

function HomeNavbar({ action }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const goMypage = () => navigate("/mypage");
  const goAdminProject = () => navigate("/adm/project");
  const goAdminInquiry = () => navigate("/adm/inquiry");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<BusinessIcon />} title="회사 소개" onClick={() => navigate("/")} />
      <NotificationItem
        icon={<BloodtypeIcon />}
        title="프로젝트 아웃소싱 소개"
        onClick={() => navigate("/")}
      />
      <NotificationItem
        icon={<AccountTreeIcon />}
        title="외주개발(웹, 모바일웹)"
        onClick={() => navigate("/")}
      />
      <NotificationItem
        icon={<CloudIcon />}
        title="AWS 솔루션 구축 사례"
        onClick={() => navigate("/awspage")}
      />
    </Menu>
  );

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }
    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <MPBox
      position="sticky"
      top={-1}
      zIndex={50}
      shadow={"md"}
      sx={{ background: "#ffffe8", border: "2px solid #ffffe8" }}
    >
      <MPBox
        py={1}
        px={{ xs: 3, sm: 5 }} //여기서 반응형 어떻게 주나 잘 보기
        width="100%"
        height="70px"
        color={"dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        zIndex={20}
        maxWidth={{ lg: "1200px" }}
        margin="0 auto"
      >
        <MPBox
          component={Link}
          to="/"
          py={0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={dybHomeIcon} alt="dybSoft" />
          <MPTypography
            ml={{ xs: 1 }}
            mt={{ xs: 1, lg: 0 }}
            variant="h5"
            type="button"
            fontWeight="bold"
            color="black"
          >
            Do Your Best
          </MPTypography>
        </MPBox>
        <MPBox color="black" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          <HomeNavbarLink
            handleFunction={handleOpenMenu}
            icon={<BusinessIcon />}
            fontSize="size.sm"
            name="DYB Soft 소개 및 서비스"
            light={false}
          />
          {renderMenu()}
          <HomeNavbarLink
            icon={<TerminalOutlinedIcon />}
            fontSize="size.sm"
            name="AWS, 소프트웨어 개발 문의"
            route="/inquiry"
            light={false}
          />
          <HomeNavbarLink
            icon={<SensorsOutlinedIcon />}
            fontSize="size.sm"
            name="아웃소싱 프로젝트 현황"
            route="/project"
            light={false}
          />
        </MPBox>
        <MPBox display={{ xs: "none", lg: "inline-block" }}>
          {isLogIn ? (
            <MPBox>
              <Tooltip title="마이페이지 내역확인">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MPAvatar src={smileImg} size="lg" />
                  {/* <MPTypography color="black" variant="body2">
                    OOO 님
                  </MPTypography> */}
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={goMypage}>
                  <MPAvatar src={smileImg} />
                  나의 활동 내역
                </MenuItem>
                <Divider />
                {isAdmin ? (
                  <MenuItem onClick={goAdminProject}>
                    <ListItemIcon>
                      <WorkOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    프로젝트 관리
                  </MenuItem>
                ) : (
                  ""
                )}
                {isAdmin ? (
                  <MenuItem onClick={goAdminInquiry}>
                    <ListItemIcon>
                      <QuestionMarkIcon fontSize="small" />
                    </ListItemIcon>
                    문의내역 관리
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem onClick={logoutRemoveAuthInfo}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  로그아웃
                </MenuItem>
              </Menu>
            </MPBox>
          ) : (
            <MPBox>
              <MPButton
                sx={{ marginRight: "10px" }}
                component={Link}
                to={"/authentication/sign-in"}
                variant="outlined"
                size="small"
                color="black"
                startIcon={<VpnKeyIcon />}
              >
                로그인
              </MPButton>
              <MPButton
                component={Link}
                to="/authentication/sign-up"
                variant="contained"
                color="black"
                size="small"
                startIcon={<LoginIcon />}
              >
                회원가입
              </MPButton>
            </MPBox>
          )}
        </MPBox>
        <MPBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MPBox>
      </MPBox>
      {mobileView && <HomeNavbarLinkMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </MPBox>
  );
}

// Setting default values for the props of HomeNavbar
HomeNavbar.defaultProps = {
  action: false,
};

// Typechecking props for the HomeNavbar
HomeNavbar.propTypes = {
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default HomeNavbar;
