// ====================== React Library ===========================
import { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dybApi from "lib/api/dybApi";

// ====================== MP&MUI Components  ======================
import MPBox from "components/MPBox/index";
import MPButton from "components/MPButton/index";
import MPTypography from "components/MPTypography/index";

import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CardActions, CardContent, Grid } from "../../../../node_modules/@mui/material/index";

// ====================== Service Components ======================
import ProjectNavbar from "services/Navbars/ProjectNavbar";
import ProjectLayout from "layouts/project/svcProject/components/ProjectLayout/index";

// ====================== Images ==================================

function Project() {
  const [dataList, setDataList] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [totPage, setTotPage] = useState();
  const [order, setOrder] = useState("NEWCREATE");

  const filterInfo = useSelector((state) => state.projectFilter);

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangePaging = (event, page) => {
    dybApi
      .get(
        `/api/svc/v2/projectList?page=${page}&categoryCode=${filterInfo.category}&prjType=${filterInfo.prjType}&workType=${filterInfo.workType}&sortCode=${order}&search=${filterInfo.keyword}`
      )
      .then((response) => {
        setDataList(response.data.data);
        setTotPage(response.data.pagingInfo.totPage);
      });

    //state에 넣으면 검색할 때 2page 이거나 하면 안나올 수 있어서 아래에서 해결
    setPageNum(page);

    // 최상단으로 올리기
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    dybApi
      .get(
        `/api/svc/v2/projectList?page=${pageNum}&categoryCode=${filterInfo.category}&prjType=${filterInfo.prjType}&workType=${filterInfo.workType}&sortCode=${order}&search=${filterInfo.keyword}`
      )
      .then((response) => {
        if (response.data.data.length == 0) {
          // 데이터 없으면 page 초기화해줘야함.
          setPageNum(1);
        }
        setDataList(response.data.data);
        setTotPage(response.data.pagingInfo.totPage);
      });
  }, [pageNum, order, filterInfo]);

  return (
    <ProjectLayout>
      <ProjectNavbar category={filterInfo.category} />
      <MPBox>
        {/* 정렬 select box , 필터 글자 써주기 */}
        <MPBox mr={{ lg: "135px" }}>
          <FormControl sx={{ mb: 1, width: "120px", float: "right" }}>
            <InputLabel>정렬</InputLabel>
            <Select value={order} label="정렬" onChange={handleChangeOrder}>
              <MenuItem value={"NEWCREATE"}>최신 등록 순</MenuItem>
              <MenuItem value={"STARTDATE"}>시작 예정일 순</MenuItem>
              <MenuItem value={"HIGHPRICE"}>견적 높은 순</MenuItem>
            </Select>
          </FormControl>
        </MPBox>
        <Grid justifyContent="center" spacing={1} container>
          {dataList
            ? dataList.map((card) => (
                <Grid item key={card.projectId} xs={12} sm={12} md={10} lg={10} mb={2}>
                  {/* 프로젝트명칭, 기술, 연차, 위치, 단가, 한 행당 두개 카드만 들어가게 */}
                  <Card sx={{ bgcolor: card.recruitmentYn === "Y" ? "white" : "#c0c0c0" }}>
                    <CardContent sx={{ paddingBottom: "0rem" }}>
                      <MPBox>
                        {card.recruitmentYn == "Y" ? (
                          <Chip
                            key={card.recruitmentYn}
                            sx={{ marginRight: "3px" }}
                            label="모집중"
                            size="small"
                            color="error"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            key={card.recruitmentYn}
                            sx={{ marginRight: "3px" }}
                            label="모집종료"
                            size="small"
                            color="secondary"
                            variant="outlined"
                          />
                        )}
                        {card.projectWorkType == "RE" ? (
                          <Chip
                            key={card.projectWorkType}
                            sx={{ marginRight: "3px" }}
                            label="재택"
                            size="small"
                            color="primary"
                            variant="contained"
                          />
                        ) : (
                          <Chip
                            key={card.projectWorkType}
                            sx={{ marginRight: "3px" }}
                            label="상주"
                            size="small"
                            color="info"
                            variant="contained"
                          />
                        )}
                        {/* <Chip
                            key="반상주"
                            sx={{ marginRight: "3px" }}
                            label="반상주"
                            size="small"
                            color="secondary"
                            variant="contained"
                          /> */}
                      </MPBox>
                      <NavLink to={`/project-detail/${card.projectId}`}>
                        <MPTypography
                          component="div"
                          color="dark"
                          fontSize="large"
                          fontWeight="bold"
                        >
                          {card.projectName}
                        </MPTypography>
                      </NavLink>
                      <MPBox minWidth="200px">
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          예상 금액 : {card.estimatedPriceStart}~{card.estimatedPriceEnd}만원 /{" "}
                          {card.estimatedPriceType == "P" ? "프로젝트 단위" : "월 단위"}{" "}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          프로젝트 기간 : {card.period}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          프로젝트 시작일 :{" "}
                          {card.projectStartDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")}
                        </MPTypography>
                        <MPTypography color="dark" fontWeight="regular" fontSize="small">
                          근무 위치 : {card.address}
                        </MPTypography>
                      </MPBox>
                    </CardContent>
                    <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                      <MPBox>
                        {card.language.split(",").map((language) => (
                          <Chip
                            key={language}
                            sx={{ marginRight: "3px" }}
                            label={language}
                            size="small"
                            color="success"
                            variant="outlined"
                          />
                        ))}
                        {card.framework.split(",").map((framework) => (
                          <Chip
                            key={framework}
                            sx={{ marginRight: "3px" }}
                            label={framework}
                            size="small"
                            color="success"
                            variant="outlined"
                          />
                        ))}
                      </MPBox>
                      <MPBox>
                        {card.recruitmentYn == "Y" ? (
                          <NavLink to={`/project-detail/${card.projectId}`}>
                            <MPButton
                              sx={{
                                marginLeft: "auto",
                                fontWeight: "bold",
                                color: "orange",
                                minWidth: "135px",
                              }}
                              size="small"
                            >
                              프로젝트 상세보기
                            </MPButton>
                          </NavLink>
                        ) : (
                          <MPButton
                            disabled
                            sx={{
                              marginLeft: "auto",
                              fontWeight: "bold",
                              color: "orange",
                              minWidth: "135px",
                            }}
                            size="small"
                          >
                            모집종료
                          </MPButton>
                        )}
                      </MPBox>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            : ""}
        </Grid>
      </MPBox>
      <MPBox display="flex">
        <MPBox sacing={2} sx={{ margin: "0 auto", marginTop: "20px", marginBottom: "20px" }}>
          <Pagination
            count={totPage}
            page={pageNum}
            defaultPage={1}
            siblingCount={0}
            boundaryCount={1}
            renderItem={(dataList) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...dataList}
              />
            )}
            onChange={handleChangePaging}
          />
        </MPBox>
      </MPBox>
      {/* <Footer /> */}
    </ProjectLayout>
  );
}

export default Project;
