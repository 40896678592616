import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import EmailIcon from "@mui/icons-material/Email";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import breakpoints from "assets/theme/base/breakpoints";

import MPTypography from "components/MPTypography";

function PageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "page");

    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, [pathname, dispatch]);

  return (
    <MPBox width="100vw" height="100%" minHeight="100vh" bgColor={background}>
      {children}
    </MPBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "white",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageLayout;
